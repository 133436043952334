import api, { ApiFetchParams, ApiResponse } from './api';
import { TimeRange } from '../types/common';

export interface TrendingEquipmentFailureMetric {
  name: string;
  failures_per_day: string; // TODO: BE is sending string, but actually a number
}

type TrendingEquipmentFailureResponse = ApiResponse<
  TrendingEquipmentFailureMetric[]
>;

interface FetchTrendingEquipmentFailureParams extends ApiFetchParams {
  country: string | null;
  timeRange: TimeRange;
  customerId?: string;
  withTrend: boolean;
}

export const fetchTrendingEquipmentFailure = async ({
  country,
  timeRange,
  withTrend,
  customerId,
  signal,
  mock,
}: FetchTrendingEquipmentFailureParams): Promise<
  TrendingEquipmentFailureMetric[]
> => {
  const { data } = await api.get<TrendingEquipmentFailureResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      timeRange,
      withTrend,
      customerId,
      country,
      type: 'TopEquipmentFailure',
    },
    config: { signal },
  });

  return data;
};
