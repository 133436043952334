import React, { useMemo } from 'react';
import { Typography, useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { FetchingStatus } from '../../../types/common';
import AppChartContainer from '../chartContainer/AppChartContainer';
import { BarChartData } from '../../../types/charts';
import { SelectChartProps } from '../../../hooks/UseSelectChart';
import AppChartTooltip from '../tooltip/AppChartTooltip';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { getLongestLabelWidthPx } from '../../../utils/measurements';
import { isDefined } from '../../../utils/isDefined';
import styles from './BarChartHorizonal.module.scss';
import AxisArrow from '../axisArrow/AxisArrow';
import CustomBarChartLabel from '../customBarChartLabel/CustomBarChartLabel';
import CustomAxisTick from '../customAxisTick/CustomAxisTick';

const BAR_HEIGHT_PX = 18;

interface Props {
  data: BarChartData;
  status: FetchingStatus;
  selectChartProps?: SelectChartProps<number>;
  testId: string;
  title?: string;
  chartLabel: string;
}

const BarChartHorizontal: React.FC<Props> = ({
  data,
  title,
  status,
  selectChartProps,
  chartLabel,
  testId,
}) => {
  const theme = useTheme();
  const textColor = theme.palette.primary.light;
  const chartHeight = useMemo(() => {
    // 22px: title height, 16px: title bottom margin
    if (isDefined(title)) {
      return 'calc(100% - 38px)';
    }
  }, [title]);

  const longestLabelLength = useMemo(() => {
    if (data === null) {
      return 0;
    }
    return getLongestLabelWidthPx(data.map((d) => d.name));
  }, [data]);

  if (status === FetchingStatus.PENDING) {
    return <LoadingOverlay />;
  }
  return (
    data && (
      <div className={styles.container} data-testid={testId}>
        {title && (
          <Typography variant="h2" className={styles.title}>
            {title}
          </Typography>
        )}
        <AppChartContainer chartHeight={chartHeight}>
          {({ width, height }) => (
            <BarChart
              data={data}
              layout="vertical"
              width={width}
              height={height}
              barSize={data?.length > 3 ? BAR_HEIGHT_PX : BAR_HEIGHT_PX * 2}
              margin={{ top: 0, right: 0, bottom: 0, left: 10 }}
            >
              <defs>
                <AxisArrow color={textColor} />
              </defs>
              {data.slice(0, -1).map((dataPoint, index) => (
                <ReferenceLine
                  key={index}
                  position="end"
                  y={dataPoint.name}
                  stroke={textColor}
                  strokeDasharray="3 3"
                />
              ))}
              <XAxis
                axisLine={{
                  stroke: textColor,
                  strokeWidth: 1,
                  markerEnd: 'url(#arrow)',
                }}
                type="number"
                tick={false}
                label={{
                  value: chartLabel,
                  position: 'insideBottomRight',
                  offset: 0,
                }}
              />
              <YAxis
                axisLine={{
                  stroke: textColor,
                  strokeWidth: 1,
                }}
                type="category"
                dataKey="name"
                width={longestLabelLength}
                tick={(tickProps) => {
                  return (
                    <CustomAxisTick
                      {...tickProps}
                      tickProps={tickProps}
                      selectChartProps={selectChartProps}
                      textColor={textColor}
                    />
                  );
                }}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                content={<AppChartTooltip />}
              />
              {selectChartProps ? (
                <Bar
                  dataKey="count"
                  label={({ ...customLabelProps }) => (
                    <CustomBarChartLabel
                      {...customLabelProps}
                      fillColor={textColor}
                      isActive={
                        selectChartProps.activeChartIndex === null ||
                        customLabelProps.index ===
                          selectChartProps.activeChartIndex
                      }
                    />
                  )}
                  fill={theme.palette.info.main}
                  onClick={selectChartProps.handleChartClicked}
                >
                  {data.map((dataPoint, index) => (
                    <Cell
                      key={dataPoint.id}
                      cursor="pointer"
                      fill={
                        selectChartProps.activeChartIndex === null ||
                        index === selectChartProps.activeChartIndex
                          ? theme.palette.info.main
                          : theme.palette.info.light
                      }
                    />
                  ))}
                </Bar>
              ) : (
                <Bar
                  dataKey="count"
                  label={({ ...customLabelProps }) => (
                    <CustomBarChartLabel
                      {...customLabelProps}
                      fillColor={textColor}
                    />
                  )}
                  fill={theme.palette.info.main}
                />
              )}
            </BarChart>
          )}
        </AppChartContainer>
      </div>
    )
  );
};

export default BarChartHorizontal;
