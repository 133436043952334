import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsEventType } from '@insightloop/common-ui';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import { Customer, fetchCustomers } from '../../integration/customer.api';
import { fetchEquipmentTypes } from '../../integration/equipmentTypes.api';
import { sendAnalytics } from '../../utils/sendAnalytics';

export const SEARCH_KEY = 'search';

export interface SearchSliceState {
  customers: {
    status: FetchingStatus;
    error: string | null;
    results: Customer[];
  };
  equipment: {
    status: FetchingStatus;
    error: string | null;
    results: string[];
  };
}

const initialState: SearchSliceState = {
  customers: {
    status: FetchingStatus.IDLE,
    error: null,
    results: [],
  },
  equipment: {
    status: FetchingStatus.IDLE,
    error: null,
    results: [],
  },
};

export const getCustomers = createAsyncThunk<
  Customer[],
  string,
  AppThunkConfig
>('getCustomers', async (searchTerm, { signal, getState }) => {
  const {
    filters: {
      dashboard: { country, equipmentType },
    },
    mockServer,
  } = getState();

  sendAnalytics({
    name: AnalyticsEventType.SEARCH_CUSTOMER,
    attributes: {
      searchTerm,
    },
  });

  return fetchCustomers({
    searchTerm,
    country,
    equipmentType,
    signal,
    mock: mockServer.active,
  });
});

export const getEquipmentTypes = createAsyncThunk<
  string[],
  string,
  AppThunkConfig
>('getEquipmentTypes', async (searchTerm, { signal, getState }) => {
  const {
    filters: {
      dashboard: { country, customer },
    },
    mockServer,
  } = getState();

  sendAnalytics({
    name: AnalyticsEventType.SEARCH_EQUIPMENT_TYPE,
    attributes: { searchTerm },
  });

  return fetchEquipmentTypes({
    searchTerm,
    country,
    signal,
    customerId: customer?.id || null,
    mock: mockServer.active,
  });
});

const searchSlice = createSlice({
  name: SEARCH_KEY,
  initialState,
  reducers: {
    clearCustomerSearch: (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          status: FetchingStatus.IDLE,
          results: [],
          error: null,
        },
      };
    },
    addCustomerToOptions: (state, action: PayloadAction<Customer>) => {
      const prevCustomers = state.customers.results;
      if (prevCustomers.find((c) => c.id === action.payload.id)) {
        return state;
      }
      return {
        ...state,
        customers: {
          ...state.customers,
          results: [...state.customers.results, action.payload],
        },
      };
    },
    clearEquipmentSearch: (state) => {
      return {
        ...state,
        equipment: {
          ...state.equipment,
          status: FetchingStatus.IDLE,
          results: [],
          error: null,
        },
      };
    },
    addEquipmentTypeToOptions: (state, action: PayloadAction<string>) => {
      const prevEquipmentTypes = state.equipment.results;
      if (prevEquipmentTypes.find((eq) => eq === action.payload)) {
        return state;
      }
      return {
        ...state,
        equipment: {
          ...state.equipment,
          results: [...state.equipment.results, action.payload],
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.customers.status = FetchingStatus.PENDING;
        state.customers.results = [];
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers.status = FetchingStatus.SUCCESS;
        state.customers.results = action.payload;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.customers.status = FetchingStatus.ERROR;
        state.customers.error = action.error.message || 'Something went wrong.';
      })
      .addCase(getEquipmentTypes.pending, (state) => {
        state.equipment.status = FetchingStatus.PENDING;
        state.equipment.results = [];
      })
      .addCase(getEquipmentTypes.fulfilled, (state, action) => {
        state.equipment.status = FetchingStatus.SUCCESS;
        state.equipment.results = action.payload;
      })
      .addCase(getEquipmentTypes.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.equipment.status = FetchingStatus.ERROR;
        state.equipment.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { actions, reducer } = searchSlice;

export const {
  clearCustomerSearch,
  clearEquipmentSearch,
  addCustomerToOptions,
  addEquipmentTypeToOptions,
} = actions;

export const searchReducer = reducer;
