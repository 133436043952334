import React from 'react';
import { CircularProgress } from '@mui/material';

import { useDarkModeSelected } from '../../store/slices/theme.slice';
import styles from './LoadingOverlay.module.scss';
import clsx from 'clsx';

interface Props {
  className?: string;
  isDefaultColor?: boolean;
}

const LoadingOverlay: React.FC<Props> = ({ className, isDefaultColor }) => {
  const isDarkMode = useDarkModeSelected();
  const color = isDarkMode ? '#fff' : '#000';

  return (
    <div
      className={clsx(styles.loading, className)}
      style={{
        backgroundColor: isDarkMode
          ? 'rgba(42, 42, 42, 0.6)'
          : 'rgb(255, 255, 255, 0.6)',
      }}
    >
      <CircularProgress sx={{ color: isDefaultColor ? '#000' : color }} />
    </div>
  );
};

export default LoadingOverlay;
