import React, { useMemo, useRef, useState } from 'react';

import ArrowSvg from '../icon/ArrowSvg';
import styles from './ContactSupportTopicsList.module.scss';
import AstronautSvg from '../icon/AstronautSvg';
import { Menu, Typography } from '@mui/material';
import BulbSvg from '../icon/BulbSvg';
import clsx from 'clsx';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';
import { useTranslations } from '../../../store/slices/translation.slice';
import Tour from '../../dashboardTour/Tour/Tour';
import {
  HighlightedComponent,
  updateOnboardingState,
} from '../../../store/slices/onboardingTour.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';

const ContactSupportTopicsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useDarkModeSelected();
  const { contactSupport: translation } = useTranslations();
  const { currentStep, startTour } = useAppSelector(
    (state) => state.onboardingTour,
  );

  const handleItemClick = (subject: string) => {
    window.location.href = translation.mail(subject);
  };

  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const takeTour = () => {
    localStorage.removeItem('isOnboardingDialogComplete');
    dispatch(updateOnboardingState({ restartTour: true }));
    setOpen(false);
  };

  const isHighlightedForContactSupport = useMemo(() => {
    return currentStep === HighlightedComponent.ContactSupport;
  }, [currentStep]);

  return (
    <>
      <div ref={ref} className={styles.container}>
        <span className={styles.contact} onClick={onClick}>
          {translation.contact}
          <span className={styles.arrowSvg}>
            <ArrowSvg />
          </span>
        </span>
        {isHighlightedForContactSupport && (
          <Tour
            style={{ transform: 'translate(-348px, 64px)' }}
            shouldStartTour={startTour}
            nextStep={() =>
              dispatch(
                updateOnboardingState({
                  currentStep: HighlightedComponent.None,
                }),
              )
            }
          />
        )}
      </div>
      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={clsx(styles.menu)}>
          <Typography variant="body1">{translation.title}</Typography>
          <div className={styles.astronaut}>
            <ul className={styles.list}>
              {translation.feedbackTopics.map((item, index) => (
                <li
                  className={clsx(
                    styles.listItem,
                    isDarkMode && styles.darkModeListItem,
                  )}
                  key={index}
                  onClick={() => handleItemClick(item)}
                >
                  <span className={styles.text}>{item}</span>
                </li>
              ))}
            </ul>
            <div className={styles.takeTourContainer}>
              <AstronautSvg />
              <div className={styles.svgAndTitle}>
                <BulbSvg />
                <Typography onClick={takeTour} className={styles.title}>
                  {translation.takeTour}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default ContactSupportTopicsList;
