import React from 'react';

export interface StripedBarProps {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  index: number;
  opacity: number;
}

const StripedBar: React.FC<StripedBarProps> = ({
  fill,
  x,
  y,
  width,
  height,
  index,
  opacity,
}) => {
  return (
    <g opacity={index === 0 ? opacity : 1}>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={index === 0 ? '#00BDC7' : fill}
      />

      <pattern
        id={`striped-#00BDC7`}
        patternUnits="userSpaceOnUse"
        width="6"
        height="6"
      >
        <path
          d="M-1,1 l2,-2 M0,6 l6,-6 M5,7 l2,-2"
          stroke="#fff"
          strokeWidth="1"
        />
      </pattern>

      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={index === 0 ? `url(#striped-#00BDC7)` : fill}
      />
    </g>
  );
};

export default StripedBar;
