import React from 'react';
import { Typography } from '@mui/material';

import { ReactComponent as NoData } from '../../../assets/images/NoData.svg';
import { useTranslations } from '../../../store/slices/translation.slice';
import styles from './NoDataAvailableCard.module.scss';

const NoDataAvailableCard: React.FC = () => {
  const { noData } = useTranslations();

  return (
    <div className={styles.container}>
      <NoData />
      <Typography variant="body1" sx={{ marginTop: '16px' }}>
        {noData}
      </Typography>
    </div>
  );
};

export default NoDataAvailableCard;
