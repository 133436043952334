import React, { useEffect, useMemo } from 'react';
import { getMeanTimeToResolution } from '../../../store/slices/meanTimeToResolution.slice';
import { getFirstTimeFixedRate } from '../../../store/slices/firstTimeFixedRate.slice';
import { getMeanTimeBetweenVisits } from '../../../store/slices/meanTimeBetweenVisits.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import {
  getFtfrDataset,
  getMtbvDataset,
  getMttrDataset,
  MINUTES_PER_DAY,
} from './kpiDatasetCalculations';
import { isDefined } from '../../../utils/isDefined';
import { useTranslations } from '../../../store/slices/translation.slice';
import { useMockServerActive } from '../../../store/slices/mockServer.slice';
import { SupportedLanguage } from '../../../assets/translations/common';
import FtfrIcon from './icons/FtfrIcon';
import MttrIcon from './icons/MttrIcon';
import MtbvIcon from './icons/MtbvIcon';
import KpiCard from './kpiCard/KpiCard';
import styles from './KpisGlance.module.scss';

const KpisGlance: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();
  const { country, customer, equipmentType, timeRange } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const language = useAppSelector((state) => state.translation.language);
  const { months, kpiCharts: translations, timeUnitLabels } = useTranslations();

  useEffect(() => {
    const promises = [
      dispatch(getFirstTimeFixedRate({ withTrend: true })),
      dispatch(getMeanTimeToResolution({ withTrend: true })),
      dispatch(getMeanTimeBetweenVisits({ withTrend: true })),
    ];

    return () => {
      promises.forEach((promise) => promise.abort());
    };
  }, [
    country,
    customer,
    equipmentType,
    timeRange,
    isMockServerActive,
    dispatch,
  ]);

  const {
    status: meanTimeToResolutionStatus,
    metrics: meanTimeToResolutionData,
  } = useAppSelector((state) => state.meanTimeToResolution);
  const meanTimeToResolution =
    meanTimeToResolutionData?.mean_time_to_resolution || null;
  const trendForMeanTimeToResolution = meanTimeToResolutionData?.trend;

  const { status: firstTimeFixedRateStatus, metrics: firstTimeFixedRateData } =
    useAppSelector((state) => state.firstTimeFixedRate);
  const firstTimeFixedRate =
    firstTimeFixedRateData?.first_time_fix_rate || null;
  const trendForFirstTimeFixRate = firstTimeFixedRateData?.trend;

  const {
    status: meanTimeBetweenVisitsStatus,
    metrics: meanTimeBetweenVisitsData,
  } = useAppSelector((state) => state.meanTimeBetweenVisits);
  const meanTimeBetweenVisits =
    meanTimeBetweenVisitsData?.mean_time_between_visits || null;
  const trendForMeanTimeBetweenVisits = meanTimeBetweenVisitsData?.trend;

  const averageForMttr = useMemo(() => {
    if (meanTimeToResolution === null || meanTimeToResolution.length === 0) {
      return null;
    }
    const dataPoints = meanTimeToResolution.map((metric) =>
      Number(metric.mean_time_to_res),
    );
    const sum = dataPoints.reduce((acc, current) => acc + current, 0);
    const avg = sum / dataPoints.length;

    return (avg / MINUTES_PER_DAY).toFixed(1);
  }, [meanTimeToResolution]);

  const avgMTBV: string | null = useMemo(() => {
    if (meanTimeBetweenVisits === null || meanTimeBetweenVisits.length === 0) {
      return null;
    }
    const dataPoints = meanTimeBetweenVisits.map((metric) =>
      Number(metric.mean_time_between_visits),
    );
    const sum = dataPoints.reduce((acc, current) => acc + current, 0);
    const avg = sum / dataPoints.length;

    return avg.toFixed(1);
  }, [meanTimeBetweenVisits]);

  const averageForFTFR = useMemo(() => {
    if (firstTimeFixedRate === null || firstTimeFixedRate.length === 0) {
      return null;
    }
    const dataPoints = firstTimeFixedRate.map((metric) => Number(metric.rate));
    const sum = dataPoints.reduce((acc, current) => acc + current, 0);
    const avg = sum / dataPoints.length;

    return (avg * 100).toFixed(1);
  }, [firstTimeFixedRate]);

  const mttrDataset = useMemo(() => {
    if (meanTimeToResolution === null) {
      return null;
    }

    return getMttrDataset(
      meanTimeToResolution,
      timeRange,
      months,
      language === SupportedLanguage.ENGLISH,
    );
  }, [meanTimeToResolution, timeRange, months, language]);

  const ftfrDataset = useMemo(() => {
    if (firstTimeFixedRate === null) {
      return null;
    }

    return getFtfrDataset(
      firstTimeFixedRate,
      timeRange,
      months,
      language === SupportedLanguage.ENGLISH,
    );
  }, [firstTimeFixedRate, timeRange, months, language]);

  const mtbvDataset = useMemo(() => {
    if (meanTimeBetweenVisits === null) {
      return null;
    }

    return getMtbvDataset(
      meanTimeBetweenVisits,
      timeRange,
      months,
      language === SupportedLanguage.ENGLISH,
    );
  }, [meanTimeBetweenVisits, timeRange, months, language]);

  return (
    <div className={styles.container}>
      <KpiCard
        testId="ftfr"
        cardId="FTFR"
        title={translations.metrics.firstTimeFixRate}
        avgValue={averageForFTFR}
        trends={
          isDefined(trendForFirstTimeFixRate)
            ? trendForFirstTimeFixRate * 100
            : null
        }
        isTrendPositive={
          isDefined(trendForFirstTimeFixRate)
            ? trendForFirstTimeFixRate > 0
            : null
        }
        valueUnit="%"
        theme="primary"
        dataSet={ftfrDataset}
        status={firstTimeFixedRateStatus}
        icon={<FtfrIcon />}
      />
      <KpiCard
        testId="mttr"
        cardId="MTTR"
        title={translations.metrics.meanTimeToResolution}
        avgValue={averageForMttr}
        trends={
          isDefined(trendForMeanTimeToResolution)
            ? trendForMeanTimeToResolution * 100
            : null
        }
        isTrendPositive={
          isDefined(trendForMeanTimeToResolution)
            ? trendForMeanTimeToResolution < 0
            : null
        }
        valueUnit={
          Number(averageForMttr) < 2
            ? ` ${timeUnitLabels.day}`
            : ` ${timeUnitLabels.days}`
        }
        theme="secondary"
        dataSet={mttrDataset}
        status={meanTimeToResolutionStatus}
        icon={<MttrIcon />}
      />
      <KpiCard
        testId="mtbv"
        cardId="MTBV"
        title={translations.metrics.meanTimeBetweenVisits}
        dataSet={mtbvDataset}
        avgValue={avgMTBV}
        trends={
          isDefined(trendForMeanTimeBetweenVisits)
            ? trendForMeanTimeBetweenVisits * 100
            : null
        }
        isTrendPositive={
          isDefined(trendForMeanTimeBetweenVisits)
            ? trendForMeanTimeBetweenVisits > 0
            : null
        }
        valueUnit={
          Number(avgMTBV) < 2
            ? ` ${timeUnitLabels.day}`
            : ` ${timeUnitLabels.days}`
        }
        theme="primary"
        status={meanTimeBetweenVisitsStatus}
        icon={<MtbvIcon />}
      />
    </div>
  );
};

export default KpisGlance;
