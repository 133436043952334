import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { isDefined } from '../../../utils/isDefined';

interface AppChartContainerInjectedProps {
  width: number;
  height: number;
}

interface Props {
  children: (props: AppChartContainerInjectedProps) => ReactNode;
  chartHeight?: string;
  testId?: string;
}

const isTestEnv = process.env.NODE_ENV === 'test';

const AppChartContainer: React.FC<Props> = ({
  children,
  chartHeight = '100%',
  testId,
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isDefined(ref.current)) {
      return;
    }
    const { width, height } = isTestEnv
      ? { width: 600, height: 400 }
      : ref.current.getBoundingClientRect();
    setWidth(width);
    setHeight(height);
  }, []);

  return (
    <div style={{ height: chartHeight }} ref={ref} data-testid={testId}>
      {children({ width, height })}
    </div>
  );
};

export default AppChartContainer;
