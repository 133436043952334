export const shortenText =
  (maxWords: number) =>
  (text: string): string => {
    const parts = text.split(/\s+/gm);
    if (parts.length <= maxWords) {
      return text;
    }
    const shortenedText = parts.slice(0, maxWords).join(' ');
    if (shortenedText[shortenedText.length - 1] === '.') {
      return `${shortenedText}..`;
    } else {
      return `${shortenedText}...`;
    }
  };
