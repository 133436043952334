import React from 'react';
import { Typography } from '@mui/material';

import { useTranslations } from '../../../store/slices/translation.slice';
import styles from './CardForUnauthorizedTenants.module.scss';
import RocketSvg from './icons/RocketSvg';

interface Props {
  isAuthorized: boolean;
}

const CardForUnauthorizedTenants: React.FC<Props> = ({ isAuthorized }) => {
  const { auth: translations } = useTranslations();

  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <div className={styles.rocketImg}>
          <RocketSvg />
        </div>
        <Typography className={styles.title} variant="h1">
          {translations.marketingMessage}
        </Typography>
        <Typography className={styles.description} variant="body1">
          {isAuthorized
            ? translations.pilotDescription
            : translations.unauthorized}
        </Typography>
      </div>
    </div>
  );
};

export default CardForUnauthorizedTenants;
