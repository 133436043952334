import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ReactComponent as NoData } from '../../assets/images/NoData.svg';
import { useTranslations } from '../../store/slices/translation.slice';
import styles from './ErrorCard.module.scss';

const ErrorCard: React.FC = () => {
  const { errorCardText } = useTranslations();

  return (
    <Card className={styles.container} data-testid="error-card">
      <NoData />
      <Typography variant="body1" className={styles.text}>
        {errorCardText}
      </Typography>
    </Card>
  );
};

export default ErrorCard;
