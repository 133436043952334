import { Customer } from '../../integration/customer.api';

interface SearchResponse {
  customers: Customer[];
  equipment_types: string[];
}

const searchSeed: SearchResponse = {
  customers: [
    {
      id: 'customer1',
      name: 'UK Onshore WF',
      city: 'London',
    },
    {
      id: 'customer2',
      name: 'ESB (offshore)',
      city: 'Belfast',
    },
    {
      id: 'customer3',
      name: 'Landsvirkjun',
      city: 'Reykjavik',
    },
    {
      id: 'customer4',
      name: 'Borssele Sites I',
      city: 'Berlin',
    },
    {
      id: 'customer5',
      name: 'Hollandse Kust (noord)',
      city: 'Dublin',
    },
  ],
  equipment_types: [
    'WT 8.0-167 DD',
    'WT 14-236 DD',
    'WT 3.x-103',
    'WT 3.x-137',
    'WT 2-B Energy 2B6',
  ],
};

export default searchSeed;
