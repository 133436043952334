import useScrollToTop from '../../hooks/UseScrollToTop';
import Dashboard from '../dashboard/Dashboard';
import Header from '../../components/header/Header';

const Home = () => {
  useScrollToTop();

  return (
    <>
      <Header />
      <Dashboard />
    </>
  );
};

export default Home;
