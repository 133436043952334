import React, { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  LegendProps,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';
import { AnalyticsEventType, isDefined } from '@insightloop/common-ui';
import clsx from 'clsx';

import { FetchingStatus } from '../../../../types/common';
import AppChartContainer from '../../../charts/chartContainer/AppChartContainer';
import LoadingOverlay from '../../../loadingOverlay/LoadingOverlay';
import styles from './TrendingPartsSummaryChart.module.scss';
import JobTypesChartTooltip from '../../../charts/jobTypesChartTooltip/JobTypesChartTooltip';
import { SelectedChartData } from '../../../../hooks/UseSelectChart';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { selectTrendingPartsMonth } from '../../../../store/slices/trendingParts.slice';
import { useTranslations } from '../../../../store/slices/translation.slice';
import {
  getMaintenanceCellColor,
  getFailureCellColor,
  getOtherCellColor,
  getChartOpacity,
} from '../../../../utils/getChartColor';
import { TrendingPartsSummaryChartData } from '../../../../integration/trendingParts.api';
import StripedBar, { StripedBarProps } from './StripedBar';
import CustomXAxisTickTrendingParts from '../customAxisTickTrendingParts/CustomXAxisTickTrendingParts';
import { formatNumberByLocale } from '../../../../utils/formatNumberByLocale';
import { sendAnalytics } from '../../../../utils/sendAnalytics';

const BAR_WIDTH_PX = 14;

interface Props {
  data: TrendingPartsSummaryChartData;
  status: FetchingStatus;
  testId: string;
}

type PartsSummaryCategory = 'maintenance' | 'failure' | 'other';

const TrendingPartsSummaryChart: React.FC<Props> = ({
  data,
  status,
  testId,
}) => {
  const { timeRanges, months } = useTranslations();
  const dispatch = useAppDispatch();
  const { selectedMonth } = useAppSelector((state) => state.trendingParts);
  const locale = useAppSelector((state) => state.translation.locale);

  const theme = useTheme();
  const textColor = theme.palette.primary.light;

  const handleChartClicked = useCallback(
    (data: SelectedChartData<number, number>) => {
      if (
        data === null ||
        data.name === selectedMonth ||
        data.name === `Actual-${months[new Date().getMonth()]}`
      ) {
        dispatch(selectTrendingPartsMonth(null));
      } else {
        dispatch(selectTrendingPartsMonth(data.name));
        sendAnalytics({
          name: AnalyticsEventType.FILTER_TRENDING_PARTS_MONTH,
          attributes: { month: String(data.id) },
        });
      }
    },
    [dispatch, selectedMonth],
  );

  const updatedDataSetForTimeRange = useMemo(() => {
    if (data === null) {
      return null;
    }

    const dataSet = [...data];
    dataSet[0].name = `Actual-${dataSet[0].name}`;
    return dataSet;
  }, [data, timeRanges]);

  const updatedDataSet = useMemo(() => {
    if (updatedDataSetForTimeRange === null) {
      return null;
    }

    return updatedDataSetForTimeRange.map((dataSetForTimeRange) => {
      const { id, name, demands } = dataSetForTimeRange;
      const { maintenance, failure, other } = demands;
      return {
        maintenance,
        failure,
        other,
        id,
        name,
      };
    });
  }, [updatedDataSetForTimeRange]);

  const emptyCategories = useMemo(() => {
    const categories = new Set<PartsSummaryCategory>();
    if (updatedDataSet === null) {
      return categories;
    }
    const totalMaintenance = updatedDataSet.reduce(
      (acc, val) => acc + val.maintenance,
      0,
    );
    const totalFailure = updatedDataSet.reduce(
      (acc, val) => acc + val.failure,
      0,
    );
    const totalOther = updatedDataSet.reduce((acc, val) => acc + val.other, 0);
    if (totalMaintenance === 0) {
      categories.add('maintenance');
    }
    if (totalFailure === 0) {
      categories.add('failure');
    }
    if (totalOther === 0) {
      categories.add('other');
    }

    return categories;
  }, [updatedDataSet]);

  if (status === FetchingStatus.PENDING) {
    return <LoadingOverlay />;
  }
  return (
    updatedDataSet && (
      <div
        className={clsx(styles.container, 'trending-parts-legend')}
        data-testid={testId}
      >
        <AppChartContainer>
          {({ width, height }) => (
            <BarChart
              data={updatedDataSet}
              width={width}
              height={height}
              barSize={BAR_WIDTH_PX}
            >
              <ReferenceLine
                position="end"
                x={months[new Date().getMonth()]}
                stroke={textColor}
                strokeDasharray="3 3"
              />
              <XAxis
                interval={0}
                dataKey="name"
                tick={(tickProps) => (
                  <CustomXAxisTickTrendingParts
                    {...tickProps}
                    tickProps={tickProps}
                    isActive={
                      selectedMonth === null ||
                      selectedMonth === tickProps.payload.value
                    }
                    isActiveForUsedVsPredicetd={
                      selectedMonth === months[new Date().getMonth()] ||
                      selectedMonth === null
                    }
                  />
                )}
              />
              <YAxis
                tick={{ fill: textColor }}
                tickFormatter={(value) => formatNumberByLocale(locale, value)}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                content={<JobTypesChartTooltip />}
              />
              <Bar
                dataKey="maintenance"
                stackId="a"
                onClick={handleChartClicked}
                cursor="pointer"
                shape={(props: StripedBarProps) => (
                  <StripedBar
                    {...props}
                    opacity={getChartOpacity(selectedMonth)}
                  />
                )}
              >
                {updatedDataSet.map((dataPoint, index) => (
                  <Cell
                    key={dataPoint.id}
                    fill={
                      index === 0
                        ? `url(#striped-#00BDC7)`
                        : getMaintenanceCellColor(
                            selectedMonth === null ||
                              dataPoint.name === selectedMonth,
                          )
                    }
                  />
                ))}
              </Bar>
              <Bar
                dataKey="failure"
                stackId="a"
                fill="#183e7499"
                onClick={handleChartClicked}
                cursor="pointer"
                shape={(props: StripedBarProps) => (
                  <StripedBar
                    {...props}
                    opacity={getChartOpacity(selectedMonth)}
                  />
                )}
              >
                {updatedDataSet.map((dataPoint, index) => (
                  <Cell
                    key={dataPoint.id}
                    fill={
                      index === 0
                        ? `url(#striped-#00BDC7)`
                        : getFailureCellColor(
                            selectedMonth === null ||
                              dataPoint.name === selectedMonth,
                          )
                    }
                  />
                ))}
              </Bar>
              <Bar
                dataKey="other"
                stackId="a"
                fill="#6EBEE3"
                onClick={handleChartClicked}
                cursor="pointer"
                shape={(props: StripedBarProps) => (
                  <StripedBar
                    {...props}
                    opacity={getChartOpacity(selectedMonth)}
                  />
                )}
              >
                {updatedDataSet.map((dataPoint, index) => (
                  <Cell
                    key={dataPoint.id}
                    fill={
                      index === 0
                        ? `url(#striped-#00BDC7)`
                        : getOtherCellColor(
                            selectedMonth === null ||
                              dataPoint.name === selectedMonth,
                          )
                    }
                  />
                ))}
              </Bar>
              <Legend
                content={
                  <TrendingPartsLegend emptyCategories={emptyCategories} />
                }
              />
            </BarChart>
          )}
        </AppChartContainer>
      </div>
    )
  );
};

const TrendingPartsLegend: React.FC<
  LegendProps & { emptyCategories: Set<PartsSummaryCategory> }
> = (props) => {
  const { trendingParts: translations } = useTranslations();

  const { payload, emptyCategories } = props;
  if (!isDefined(payload)) {
    return null;
  }

  return (
    <ul className={styles.legendWrapper}>
      {payload
        .map(({ value }) => value as PartsSummaryCategory)
        .filter((value) => !emptyCategories.has(value))
        .map((value) => (
          <li className={clsx(styles.legendItem, styles[value])} key={value}>
            {translations[value]}
          </li>
        ))}
    </ul>
  );
};

export default TrendingPartsSummaryChart;
