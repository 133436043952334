import React from 'react';

import CardContainer from '../cardContainer/CardContainer';
import { ReactComponent as GraphLogo } from '../../assets/images/lineUp.svg';
import { useTranslations } from '../../store/slices/translation.slice';
import KpisGlance from './kpisGlance/KpisGlance';
import KpisGlanceTooltip from './kpisGlance/KpisGlanceTooltip/KpisGlanceTooltip';
import ProcessStatus from './processStatus/ProcessStatus';

const KpisGlanceAndProcessStatus: React.FC = () => {
  const { kpiCharts: translations } = useTranslations();

  return (
    <CardContainer
      logo={<GraphLogo />}
      title={translations.mainTitle}
      tooltipContent={
        <KpisGlanceTooltip
          key={translations.metrics.tooltipContent[3].id}
          title={translations.metrics.tooltipContent[3].definition}
          definition={translations.metrics.tooltipContent[3].description}
        />
      }
      testId="kpi-glance"
      height="auto"
    >
      <KpisGlance />
      <ProcessStatus />
    </CardContainer>
  );
};

export default KpisGlanceAndProcessStatus;
