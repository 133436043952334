import { useCallback, useEffect } from 'react';
import { AnalyticsEventType, NULL_SELECTION } from '@insightloop/common-ui';

import useSelectChart, {
  SelectChartProps,
  SelectedChartData,
} from '../../hooks/UseSelectChart';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import {
  clearCustomerFilter,
  updateFilters,
} from '../../store/slices/filters.slice';
import {
  addCustomerToOptions,
  getCustomers,
} from '../../store/slices/search.slice';
import { TrendingCustomerDataPoint } from './customersChart/TrendingCustomersChart';
import { Customer } from '../../integration/customer.api';
import { sendAnalytics } from '../../utils/sendAnalytics';

const useCustomerChartFilter = (
  dataPoint: TrendingCustomerDataPoint[] | null,
): SelectChartProps<number> => {
  const dispatch = useAppDispatch();
  const customerFilter = useAppSelector(
    (state) => state.filters.dashboard.customer,
  );
  const handleSelect = useCallback(
    (data: SelectedChartData<number>) => {
      if (data === null) {
        dispatch(clearCustomerFilter({ shouldClearInput: true }));
        sendAnalytics({
          name: AnalyticsEventType.CHART_FILTER_CUSTOMER,
          attributes: { customer: NULL_SELECTION },
        });
      } else {
        const customer: Customer = {
          id: data.id,
          name: data.name,
        };
        sendAnalytics({
          name: AnalyticsEventType.CHART_FILTER_CUSTOMER,
          attributes: { customer: customer.id },
        });
        dispatch(updateFilters({ customer }));
        dispatch(addCustomerToOptions(customer));
        dispatch(getCustomers(customer.name));
      }
    },
    [dispatch],
  );
  const { activeChartIndex, handleChartClicked, setIndex } =
    useSelectChart<number>({
      handleSelect,
    });

  useEffect(() => {
    if (dataPoint === null) {
      return;
    }
    const barIsSelected = activeChartIndex !== null;
    // Removing bar selection when filter is cleared
    if (barIsSelected && customerFilter === null) {
      setIndex(null);
      return;
    }
    const selectedCustomerIndex = dataPoint.findIndex(
      (c) => c.id === customerFilter?.id,
    );
    // Updating bar selection when filter changes, if the value is present on the chart
    if (
      customerFilter !== null &&
      selectedCustomerIndex !== -1 &&
      selectedCustomerIndex !== activeChartIndex
    ) {
      setIndex(selectedCustomerIndex);
    }
    // Removing bar selection when the filter value is not present on the chart
    if (barIsSelected && selectedCustomerIndex === -1) {
      setIndex(null);
    }
  }, [activeChartIndex, customerFilter, dataPoint]);

  return {
    activeChartIndex,
    handleChartClicked,
    setIndex,
  };
};

export default useCustomerChartFilter;
