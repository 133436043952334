import { DocumentManagementData } from '../../integration/documentManagement.api';

const url =
  'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Wind_Turbine_Generator_Systems_3.6-137_50+60Hz_Technical_Documentation.pdf#page=2';
const documentManagementSeed: DocumentManagementData = {
  size: 7,
  number: 1,
  first: true,
  last: false,
  numberOfElements: 2,
  totalElements: 7,
  totalPages: 9,
  items: [
    {
      url: url,
      filename: 'File 1.pdf',
      size: '280 KB',
      timestamp: '1695009485.021266',
      document_id: '01',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 2.pdf',
      size: '380 KB',
      timestamp: '1692014932.845142',
      document_id: '02',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 3.pdf',
      size: '480 KB',
      timestamp: '1692020932.845142',
      document_id: '03',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 4.pdf',
      size: '580 KB',
      timestamp: '1691014932.845142',
      document_id: '04',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 5.pdf',
      size: '680 KB',
      timestamp: '1690014932.845142',
      document_id: '05',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 6.pdf',
      size: '780 KB',
      timestamp: '1692014932.845142',
      document_id: '06',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 7.pdf',
      size: '228 KB',
      timestamp: '1691014932.845142',
      document_id: '07',
      tags: ['Equipment Name'],
    },
    {
      url: url,
      filename: 'File 8.pdf',
      size: '328 KB',
      timestamp: '1695014932.845142',
      document_id: '08',
      tags: ['Equipment Name'],
    },
  ],
};

const getDocumentManagementSeedData = (): DocumentManagementData => {
  return documentManagementSeed;
};

export default getDocumentManagementSeedData;
