import React from 'react';
import { Text } from 'recharts';
import { useTheme } from '@mui/material';

import { CustomAxisTickProps } from '../../../charts/customAxisTick/CustomAxisTick';
import { useTranslations } from '../../../../store/slices/translation.slice';

interface TickLabelPropsTrendingParts {
  tickProps: CustomAxisTickProps;
  isActive: boolean;
  isActiveForUsedVsPredicetd: boolean;
}
const CustomXAxisTickTrendingParts: React.FC<TickLabelPropsTrendingParts> = ({
  tickProps,
  isActive,
  isActiveForUsedVsPredicetd,
}) => {
  const theme = useTheme();
  const { trendingParts: translations } = useTranslations();
  const textColor = theme.palette.primary.light;
  const opacity = isActive ? 1 : 0.1;
  const opacityForUsedVsPredicted = isActiveForUsedVsPredicetd ? 1 : 0.1;

  if (tickProps.index === 1) {
    return null;
  }

  return tickProps.index === 0 ? (
    <>
      <Text
        {...tickProps}
        fontSize={'16px'}
        fill={textColor}
        x={tickProps.x - (translations.chartLabels.usedVsPredicted.length + 20)}
        y={tickProps.y + 4}
        textAnchor="start"
        verticalAnchor="start"
        opacity={opacityForUsedVsPredicted}
      >
        {translations.chartLabels.currentMonth}
      </Text>
      <Text
        {...tickProps}
        fontSize={'16px'}
        fill={textColor}
        x={tickProps.x - (translations.chartLabels.usedVsPredicted.length + 30)}
        y={tickProps.y + 24}
        textAnchor="start"
        verticalAnchor="start"
        opacity={opacityForUsedVsPredicted}
      >
        {translations.chartLabels.usedVsPredicted}
      </Text>
    </>
  ) : (
    <Text
      opacity={opacity}
      {...tickProps}
      fontSize={'16px'}
      width={'12px'}
      fill={textColor}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {tickProps.payload.value}
    </Text>
  );
};
export default CustomXAxisTickTrendingParts;
