import React, { useMemo } from 'react';
import { truncateChartLabel } from '../../../utils/measurements';
import { Text } from 'recharts';
import { SelectChartProps } from '../../../hooks/UseSelectChart';
import { getAxisTickOpacity } from '../utils/getAxisTickOpacity';

interface TickLabelProps {
  tickProps: CustomAxisTickProps;
  selectChartProps?: SelectChartProps<number>;
  textColor: string;
}

export interface CustomAxisTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
    index: number;
  };
  index: number;
}

const CustomAxisTick: React.FC<TickLabelProps> = ({
  tickProps,
  selectChartProps,
  textColor,
}) => {
  const opacity = useMemo(
    () => getAxisTickOpacity(selectChartProps, tickProps),
    [selectChartProps, tickProps],
  );
  const truncatedLabel = useMemo(
    () => truncateChartLabel(tickProps.payload.value),
    [tickProps.payload.value],
  );

  return (
    <Text {...tickProps} style={{ fill: textColor, opacity }}>
      {truncatedLabel}
    </Text>
  );
};

export default CustomAxisTick;
