import React from 'react';
import { WorkflowBottleneckStep } from '../../../../integration/workflowBottleneck.api';
import { Theme } from '@mui/material';
import { getTrend, Trend } from '../utils/getTrend';
import { toPercentage } from '../utils/toPercentage';

interface TrendDurationProps {
  kpiData: WorkflowBottleneckStep;
  theme: Theme;
}

const DurationTrend: React.FC<TrendDurationProps> = ({ kpiData, theme }) => {
  if (getTrend(kpiData.durations.trend) === Trend.Neutral) {
    return <span>N/A</span>;
  }

  if (getTrend(kpiData.durations.trend) === Trend.Positive) {
    return (
      <span style={{ color: theme.palette.error.main }}>
        ▲ {toPercentage(kpiData.durations.trend, 0)}
      </span>
    );
  } else if (getTrend(kpiData.durations.trend) === Trend.Negative) {
    return (
      <span style={{ color: theme.palette.success.main }}>
        ▼ {toPercentage(kpiData.durations.trend, 0)}
      </span>
    );
  }
  return null;
};

export default DurationTrend;
