import { RatingsAndReviewsDataset } from '../../integration/ratingsAndReviews.api';

const ratingsAndReviewSeed: RatingsAndReviewsDataset = {
  comments: [
    {
      rating: 4,
      name: 'Name One',
      title: 'title 1',
      descriptionTitle: 'Good',
      description: 'We are very happy with the provided service.',
    },
    {
      rating: 5,
      name: 'Name Two',
      title: 'title 2',
      descriptionTitle: 'Very quick',
      description:
        'We are very happy with the service. The issue was fixed in a very short time.',
    },
    {
      rating: 3,
      name: 'Name Three',
      title: 'title 3',
      descriptionTitle: 'Happy',
      description: 'We are very happy with the service.',
    },
    {
      rating: 5,
      name: 'Name Four',
      title: 'title 3',
      descriptionTitle: 'Very happy',
      description: 'Good service, issue was fixed in a very short time.',
    },
    {
      rating: 3,
      name: 'Name Five',
      title: 'title 3',
      descriptionTitle: 'Very happy',
      description: 'Great service, issue was fixed in a very short time.',
    },
    {
      rating: 4,
      name: 'Name Six',
      title: 'title 3',
      descriptionTitle: 'Very happy',
      description: 'happy with the provided service.',
    },
  ],
  ratings: {
    rating: 4.5,
    totalReviews: 24,
    service: [
      { ratingText: 'Service', progressValue: 80, rating: 4 },
      { ratingText: 'Hospitality', progressValue: 50, rating: 2.5 },
      { ratingText: 'Time Spent', progressValue: 80, rating: 4.3 },
    ],
    technicianInfo: {
      name: 'Name',
      jobNumber: 236579,
      equipment: 'Blender',
    },
  },

  complaint: {
    rating: 2.3,
    equipment: 'Turbine',
    service: [
      { ratingText: 'Service', progressValue: 80, rating: 2 },
      { ratingText: 'Hospitality', progressValue: 50, rating: 3 },
      { ratingText: 'Time Spent', progressValue: 80, rating: 3.5 },
    ],
    complaint: [
      {
        rating: 1,
        companyName: 'Company 1',
        description: '“Issue was not fully fixed.”',
      },
      {
        rating: 2,
        companyName: 'Company 2',
        description: '“Issue reappeard again just after a day.”',
      },
    ],
  },
};

const getRatingsAndReviews = (): RatingsAndReviewsDataset => {
  return ratingsAndReviewSeed;
};

export default getRatingsAndReviews;
