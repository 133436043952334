import { FC, ReactElement } from 'react';
import { Provider } from 'react-redux';
import { render as rtlRender, RenderOptions } from '@testing-library/react';
import { createAppStore, RootState } from '../store/store';
import { TimeRange } from '../types/common';
import { SupportedLanguage } from '../assets/translations/common';

interface RenderOptionsWithState extends RenderOptions {
  initialState?: Partial<RootState>;
}

export interface GetSeedParams {
  timeRange: TimeRange;
  country: string | null;
  customer?: string | null;
  equipmentType?: string | null;
}

const render = (
  ui: ReactElement,
  { initialState, ...renderOptions }: RenderOptionsWithState = {},
) => {
  const store = createAppStore({
    ...testTranslationState,
    ...initialState,
  });
  const Wrapper: FC<{ children: ReactElement }> = ({ children }) => (
    <Provider store={store}>{children}</Provider>
  );

  return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
};

const testTranslationState: Partial<RootState> = {
  translation: {
    language: SupportedLanguage.ENGLISH,
    locale: SupportedLanguage.ENGLISH,
  },
};

const delay = (ms: number) =>
  new Promise((resolve) => window.setTimeout(resolve, ms));

export * from '@testing-library/react';

export { render, delay };
