import React from 'react';
import { getIconColor } from '../utils/getIconColor';

interface Props {
  isSelected: boolean;
}

const BuildIconSvg: React.FC<Props> = ({ isSelected }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon - Equipment">
        <path
          id="Ellipse 150"
          d="M13.8791 11.4727C14.2768 10.3552 14.0284 9.05935 13.1339 8.16488C12.1182 7.14913 10.5848 6.96653 9.38291 7.61709L11.5326 9.76676L11.2123 10.7277L10.1399 11.1594L8.02662 9.0461C7.46723 10.2237 7.67483 11.6748 8.64941 12.6494C9.54388 13.5439 10.8398 13.7923 11.9572 13.3946L17.057 18.8591C17.443 19.2728 18.0951 19.284 18.4952 18.8839L19.0021 18.3771C19.3831 17.996 19.3937 17.3816 19.026 16.9877L13.8791 11.4727Z"
          stroke={getIconColor(isSelected)}
        />
        <path
          id="Ellipse 176"
          d="M15.8791 11.598C15.9582 11.2446 16 10.8772 16 10.5C16 7.73858 13.7614 5.5 11 5.5C8.23858 5.5 6 7.73858 6 10.5C6 13.2614 8.23858 15.5 11 15.5C11.3874 15.5 11.7644 15.456 12.1265 15.3726"
          stroke={getIconColor(isSelected)}
        />
        <path
          id="Vector 589"
          d="M17 13L20.0707 12.5613C20.317 12.5261 20.5 12.3152 20.5 12.0664V9.37715C20.5 9.15391 20.352 8.95772 20.1374 8.89639L18.4449 8.41282C17.7581 8.21659 17.4925 7.38436 17.9387 6.8266L18.7204 5.84946C18.8796 5.65051 18.8637 5.36371 18.6836 5.18355L16.7548 3.25483C16.6026 3.10265 16.3702 3.06492 16.1777 3.16117L14.711 3.89449C14.1092 4.19538 13.388 3.82811 13.2774 3.16446L13.0696 1.9178C13.0295 1.67671 12.8209 1.5 12.5764 1.5H9.42356C9.17914 1.5 8.97055 1.67671 8.93037 1.9178L8.72259 3.16446C8.61198 3.82811 7.89076 4.19538 7.28898 3.89449L5.82233 3.16117C5.62984 3.06492 5.39735 3.10265 5.24517 3.25483L3.31645 5.18355C3.13629 5.36371 3.12041 5.65051 3.27956 5.84946L3.90635 6.63294C4.38981 7.23727 4.03304 8.13814 3.26691 8.24758L1.92929 8.43867C1.68296 8.47386 1.5 8.68482 1.5 8.93365V12.0664C1.5 12.3152 1.68296 12.5261 1.92929 12.5613L3.4292 12.7756C4.15755 12.8796 4.52795 13.7081 4.11983 14.3202L3.22737 15.6589C3.09517 15.8573 3.12131 16.1213 3.28984 16.2898L5.21016 18.2102C5.37869 18.3787 5.64275 18.4048 5.84106 18.2726L7.17975 17.3802C7.79192 16.9721 8.62035 17.3425 8.7244 18.0708L8.93867 19.5707C8.97386 19.817 9.18482 20 9.43365 20H12.5664C12.8152 20 13.0261 19.817 13.0613 19.5707L13.5 16.5"
          stroke={getIconColor(isSelected)}
        />
      </g>
    </svg>
  );
};

export default BuildIconSvg;
