import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import styles from './PartDetails.module.scss';
import { useTranslations } from '../../../../store/slices/translation.slice';
import { useAppSelector } from '../../../../store/utils/hooks';
import { formatNumberByLocale } from '../../../../utils/formatNumberByLocale';

const PartDetails: React.FC = () => {
  const { trendingParts: translations, monthsMap, months } = useTranslations();
  const { selectedPart, selectedMonth } = useAppSelector(
    (state) => state.trendingParts,
  );
  const locale = useAppSelector((state) => state.translation.locale);
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <div className={styles.container}>
      {selectedPart === null ? (
        <Typography variant="body1" className={styles.content}>
          {translations.selectPartPrompt}
        </Typography>
      ) : (
        <div>
          <Typography variant="body1">
            {translations.partHistory.lastYear({ year: currentYear - 1 })}:{' '}
            <b>
              {formatNumberByLocale(locale, selectedPart.history.last_year)}
            </b>
          </Typography>
          <Typography variant="body1">
            {translations.partHistory.currentYear({ year: currentYear })}:{' '}
            <b>
              {formatNumberByLocale(locale, selectedPart.history.this_year)}
            </b>
          </Typography>
          <Typography variant="body1">
            {translations.partHistory.lastMonth({
              month:
                monthsMap[
                  selectedMonth ? selectedMonth : months[new Date().getMonth()]
                ],
            })}
            :{' '}
            <b>
              {formatNumberByLocale(
                locale,
                selectedPart.history.last_year_same_month,
              )}
            </b>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PartDetails;
