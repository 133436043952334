import { isDefined } from './isDefined';

export const formatNumberByLocale = (
  locale: string,
  number?: number,
): string => {
  const formattedNumber = new Intl.NumberFormat(locale);

  if (isDefined(number)) {
    return formattedNumber.format(number);
  } else {
    return 'N/A';
  }
};
