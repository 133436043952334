import api, { ApiFetchParams, ApiResponse } from './api';

export interface Customer {
  readonly id: string;
  readonly name: string;
  readonly city?: string; // Customers coming from the trending customer chart have no city
}

type CustomersResponse = ApiResponse<{ customers: Customer[] }>;

interface FetchCountriesParams extends ApiFetchParams {
  searchTerm: string;
  country: string | null;
  equipmentType: string | null;
}

export const fetchCustomers = async ({
  searchTerm,
  country,
  equipmentType,
  signal,
  mock,
}: FetchCountriesParams): Promise<Customer[]> => {
  const { data } = await api.get<CustomersResponse>({
    mock,
    path: '/domain-model/api/v1/customers/actions/search',
    queryParams: {
      searchTerm,
      countryCode: country,
      equipmentType,
    },
    config: { signal },
  });

  return data.customers;
};
