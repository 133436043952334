import { TimeRange } from '../../types/common';
import { GetSeedParams } from '../test-utils';
import countriesSeed from './countries.seed';
import { JobTypeAndCount } from '../../integration/allCustomersAndInterventions.api';
import { TrendingCustomersMetric } from '../../integration/trendingCustomers.api';

const customersInFocusSeed: TrendingCustomersMetric[] = [
  {
    customerId: 'customer1',
    name: 'UK Onshore WF',
    totalCount: 125,
    Maintenance: 40,
    Failure: 35,
    Other: 50,
  },
  {
    customerId: 'customer2',
    name: 'ESB (offshore)',
    totalCount: 88,
    Maintenance: 33,
    Failure: 35,
    Other: 20,
  },
  {
    customerId: 'customer3',
    name: 'Landsvirkjun',
    totalCount: 48,
    Maintenance: 20,
    Failure: 18,
    Other: 10,
  },
  {
    customerId: 'customer4',
    name: 'Borssele Sites I',
    totalCount: 28,
    Maintenance: 15,
    Failure: 12,
    Other: 8,
  },
  {
    customerId: 'customer5',
    name: 'Hollandse Kust (noord)',
    totalCount: 18,
    Maintenance: 12,
    Failure: 10,
    Other: 8,
  },
];

const customersInFocusSeedForCountry: TrendingCustomersMetric[] = [
  {
    customerId: 'customer1',
    name: 'UK Onshore WF',
    totalCount: 65,
    Maintenance: 30,
    Failure: 25,
    Other: 10,
  },

  {
    customerId: 'customer2',
    name: 'ESB (offshore)',
    totalCount: 63,
    Maintenance: 25,
    Failure: 30,
    Other: 8,
  },
  {
    customerId: 'customer3',
    name: 'Landsvirkjun',
    totalCount: 34,
    Maintenance: 15,
    Failure: 12,
    Other: 7,
  },
  {
    customerId: 'customer4',
    name: 'Borssele Sites I',
    totalCount: 30,
    Maintenance: 13,
    Failure: 30,
    Other: 30,
  },
  {
    customerId: 'customer5',
    name: 'Hollandse Kust (noord)',
    totalCount: 26,
    Maintenance: 10,
    Failure: 8,
    Other: 6,
  },
];

const customersInFocusSeedForSixMonth: TrendingCustomersMetric[] = [
  {
    customerId: 'customer1',
    name: 'UK Onshore WF',
    totalCount: 65,
    Maintenance: 20,
    Failure: 20,
    Other: 25,
  },
  {
    customerId: 'customer2',
    name: 'ESB (offshore)',
    totalCount: 50,
    Maintenance: 15,
    Failure: 20,
    Other: 15,
  },
  {
    customerId: 'customer3',
    name: 'Landsvirkjun',
    totalCount: 24,
    Maintenance: 10,
    Failure: 9,
    Other: 5,
  },
  {
    customerId: 'customer4',
    name: 'Borssele Sites I',
    totalCount: 28,
    Maintenance: 12,
    Failure: 10,
    Other: 6,
  },
  {
    customerId: 'customer5',
    name: 'Hollandse Kust (noord)',
    totalCount: 24,
    Maintenance: 8,
    Failure: 6,
    Other: 7,
  },
];

const allCoustomersAndInterventionsSeed = [
  { count: 2594, type: 'Other' },
  { count: 1021, type: 'Maintenance' },
  { count: 1849, type: 'Failure' },
];
const allCoustomersAndInterventionsSeedForSixMonth = [
  { count: 3594, type: 'Other' },
  { count: 2021, type: 'Maintenance' },
];

const allCoustomersAndInterventionsSeedForCountry = [
  { count: 2594, type: 'Other' },
  { count: 9021, type: 'Maintenance' },
  { count: 6849, type: 'Failure' },
];

export const getTrendingCustomers = ({
  timeRange,
  country,
}: GetSeedParams): TrendingCustomersMetric[] | null => {
  if (country === countriesSeed.countries[1].code) {
    return customersInFocusSeedForCountry;
  }

  if (timeRange === TimeRange.SIX_MONTHS) {
    return customersInFocusSeedForSixMonth;
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return [];
  }

  return customersInFocusSeed;
};

export const getAllCoustomersAndInterventions = ({
  timeRange,
  country,
}: GetSeedParams): JobTypeAndCount[] | null => {
  if (country === countriesSeed.countries[1].code) {
    return allCoustomersAndInterventionsSeedForCountry;
  }

  if (timeRange === TimeRange.SIX_MONTHS) {
    return allCoustomersAndInterventionsSeedForSixMonth;
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return null;
  }

  return allCoustomersAndInterventionsSeed;
};
