import React from 'react';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import styles from './KpiCard.module.scss';
import clsx from 'clsx';
import TrendLabel from '../trendLabel/TrendLabel';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';
import { FetchingStatus } from '../../../../types/common';
import { KpiChartDataPoint } from '../kpiDatasetCalculations';
import { useTranslations } from '../../../../store/slices/translation.slice';
import KpisGlanceTooltip from '../KpisGlanceTooltip/KpisGlanceTooltip';

interface Props {
  title: string;
  cardId: string;
  avgValue: string | null;
  valueUnit: string;
  trends: number | null;
  isTrendPositive: boolean | null;
  theme: string;
  status: FetchingStatus;
  dataSet: KpiChartDataPoint[] | null;
  testId: string;
  icon: React.ReactNode;
}

const KpiCard: React.FC<Props> = (props) => {
  const isDarkMode = useDarkModeSelected();
  const isLoading = props.status === FetchingStatus.PENDING;

  const clsName = (trend: boolean | null) => {
    if (trend === null) {
      return styles.trendIsNull;
    }
    return trend ? styles.trendIsPositive : styles.trendIsNegative;
  };
  const { kpiCharts: translations } = useTranslations();

  const tooltipContent = translations.metrics.tooltipContent.find(
    (translation) => translation.id === props.cardId,
  );

  return (
    <Tooltip
      placement="top"
      classes={{ tooltip: styles.kpiTooltip }}
      arrow={true}
      title={
        tooltipContent && (
          <KpisGlanceTooltip
            definition={tooltipContent.definition}
            description={tooltipContent.description}
          />
        )
      }
    >
      <div
        className={clsx(styles.container, clsName(props.isTrendPositive))}
        data-testid={
          props.status === FetchingStatus.SUCCESS ? props.testId : undefined
        }
      >
        <>
          <Typography variant="h3" className={styles.title}>
            {props.title}
          </Typography>
          <div
            className={clsx(
              styles.trendImg,
              isDarkMode && styles.trendImgDarkMode,
            )}
          >
            {props.icon}
          </div>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ height: '20px', width: '60%', margin: '8px auto' }}
            />
          ) : (
            <Typography className={styles.value}>
              {props.avgValue !== null
                ? `${props.avgValue}${props.valueUnit}`
                : 'N/A'}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ height: '14px', width: '40%', margin: '8px auto' }}
            />
          ) : (
            <TrendLabel
              trend={props.trends}
              isTrendPositive={props.isTrendPositive}
            />
          )}
        </>
      </div>
    </Tooltip>
  );
};

export default KpiCard;
