import api, { ApiFetchParams, ApiResponse } from './api';
import { TimeRange } from '../types/common';

export enum JobType {
  MAINTENANCE = 'Maintenance',
  FAILURE = 'Failure',
  OTHER = 'Other',
}

export interface TrendingCustomersMetric {
  customerId: string;
  name: string;
  totalCount: number;
  Maintenance?: number;
  Failure?: number;
  Other?: number;
}

export interface TrendingCustomersData {
  customers_in_focus: TrendingCustomersMetric[];
}

type TrendingCustomersResponse = ApiResponse<TrendingCustomersData>;

interface FetchTrendingCustomersParams extends ApiFetchParams {
  country: string | null;
  timeRange: TimeRange;
  equipmentType: string | null;
}

export const fetchTrendingCustomers = async ({
  country,
  timeRange,
  equipmentType,
  signal,
  mock,
}: FetchTrendingCustomersParams): Promise<TrendingCustomersMetric[]> => {
  const { data } = await api.get<TrendingCustomersResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      timeRange,
      equipmentType,
      country,
      type: 'CustomersInFocus',
    },
    config: { signal },
  });

  return data.customers_in_focus;
};
