import * as React from 'react';

import TimeRangeFilter from './timeRangeFilter/TimeRangeFilter';
import CountriesFilter from './dropdownFilters/CountriesFilter';
import CustomerFilter from './dropdownFilters/CustomerFilter';
import EquipmentTypeFilter from './dropdownFilters/EquipmentTypeFilter';
import styles from './FiltersBar.module.scss';
import TimeRangeDropdown from './dropdownFilters/TimeRangeDropdown';
import { useMediaQuery } from '@mui/material';

const FiltersBar: React.FC = () => {
  const isDeviceTablet = useMediaQuery(
    '(min-width:600px) and (max-width:1199px)',
  );

  return (
    <div className={styles.container}>
      <div className={styles.dropDownFiltersContainer}>
        <div className={styles.dropDown}>
          <CountriesFilter />
        </div>
        <div className={styles.dropDown}>
          <CustomerFilter />
        </div>
        <div className={styles.dropDown}>
          <EquipmentTypeFilter />
        </div>
      </div>
      <div className={styles.timeFilter}>
        {isDeviceTablet ? <TimeRangeDropdown /> : <TimeRangeFilter />}
      </div>
    </div>
  );
};

export default FiltersBar;
