import React, { useCallback, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import {
  PieChart as RePieChart,
  Cell,
  Pie,
  Label,
  Legend,
  Tooltip,
  Sector,
} from 'recharts';

import { FetchingStatus } from '../../../types/common';
import AppChartContainer from '../../charts/chartContainer/AppChartContainer';
import styles from './TrendingCustomersSummaryChart.module.scss';
import AppChartTooltip from '../../charts/tooltip/AppChartTooltip';
import TrendingCustomersSummaryChartLabel from './TrendingCustomersSummaryChartLabel';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { BarChartData } from '../../../types/charts';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';

interface Props {
  chartName: string; // TODO: what is this supposed to be used for?
  data: BarChartData;
  status: FetchingStatus;
  title: string;
  totalLabel: string;
  totalValue: number | null;
  testId: string;
}

// Sector types for recharts are not working properly.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any) => {
  const { outerRadius = 0 } = props;

  return (
    <g>
      <Sector {...props} outerRadius={outerRadius + 5} />
    </g>
  );
};

const TrendingCustomersSummaryChart: React.FC<Props> = ({
  data,
  status,
  title,
  totalLabel,
  totalValue,
  testId,
}) => {
  const isDarkMode = useDarkModeSelected();
  const theme = useTheme();
  // 22px: title height, 24px: title top margin
  const chartHeight = 'calc(100% - 46px)';

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const onMouseOver = useCallback((_: unknown, index: number) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback(() => {
    setActiveIndex(undefined);
  }, []);

  if (status === FetchingStatus.PENDING) {
    return <LoadingOverlay />;
  }

  return (
    data && (
      <>
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
        <AppChartContainer chartHeight={chartHeight} testId={testId}>
          {({ width, height }) => (
            <RePieChart width={width} height={height}>
              <Pie
                data={data}
                innerRadius={80}
                outerRadius={130}
                paddingAngle={4}
                cx={140}
                dataKey="count"
                cornerRadius={6}
                stroke="none"
                activeShape={renderActiveShape}
                activeIndex={activeIndex}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              >
                <Cell fill={isDarkMode ? '#FFFFFF' : '#000000'} />
                <Cell fill="#00BDC7" />
                <Cell fill="#949494" />
                <Label
                  position="center"
                  content={
                    <TrendingCustomersSummaryChartLabel
                      label={totalLabel}
                      value={totalValue}
                    />
                  }
                />
              </Pie>
              <Legend
                layout="vertical"
                verticalAlign="middle"
                align="center"
                iconType="circle"
                formatter={(value) => (
                  <span style={{ color: theme.palette.primary.light }}>
                    {value}
                  </span>
                )}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                content={<AppChartTooltip />}
              />
            </RePieChart>
          )}
        </AppChartContainer>
      </>
    )
  );
};

export default TrendingCustomersSummaryChart;
