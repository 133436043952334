import React from 'react';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';

const ClosureIcon: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const fillColor = isDarkMode ? '#ffffff' : '#2A2A2A';
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7998_10703)">
        <path
          d="M10.5737 8.2193C10.4038 8.04409 10.1206 8.04409 9.94266 8.2193L5.5174 12.5439L3.46253 10.6166C3.28455 10.4493 3.0014 10.4573 2.83151 10.6325C2.66161 10.8077 2.6697 11.0865 2.84769 11.2537L5.52549 13.7704L10.5656 8.84848C10.7436 8.67327 10.7436 8.39452 10.5737 8.2193Z"
          fill={fillColor}
        />
        <path
          d="M21.0503 0H6.73901C5.66303 0 4.7974 0.860147 4.7974 1.91144V4.79452C2.0306 5.61485 0 8.13953 0 11.1262C0 14.1128 2.0306 16.6454 4.7974 17.4578V23.0886C4.7974 24.1478 5.67112 25 6.73901 25H21.0503C22.1263 25 22.9919 24.1399 22.9919 23.0886V1.91144C23 0.860147 22.1263 0 21.0503 0ZM6.73901 0.876075H21.0503C21.6328 0.876075 22.102 1.34597 22.102 1.91144V2.6123H5.67921V1.91144C5.6873 1.34597 6.15653 0.876075 6.73901 0.876075ZM0.889905 11.1262C0.889905 7.97228 3.50299 5.39981 6.70665 5.39981C9.91031 5.39981 12.5234 7.96432 12.5234 11.1262C12.5234 14.288 9.91031 16.8525 6.70665 16.8525C3.50299 16.8525 0.889905 14.288 0.889905 11.1262ZM21.0503 24.1239H6.73901C6.15653 24.1239 5.6873 23.654 5.6873 23.0886V17.6569C6.019 17.7047 6.35878 17.7365 6.70665 17.7365C8.73725 17.7365 10.5656 16.8366 11.7953 15.4269H15.6461C15.8889 15.4269 16.0911 15.2278 16.0911 14.9888C16.0911 14.7499 15.8889 14.5508 15.6461 14.5508H12.4425C12.9845 13.6747 13.3243 12.6553 13.3971 11.5722H15.6461C15.8889 11.5722 16.0911 11.373 16.0911 11.1341C16.0911 10.8952 15.8889 10.6961 15.6461 10.6961H13.3971C13.3324 9.67665 13.025 8.72093 12.5396 7.87671H15.6542C15.8969 7.87671 16.0992 7.6776 16.0992 7.43867C16.0992 7.19975 15.8969 7.00064 15.6542 7.00064H11.9409C10.7112 5.49538 8.82624 4.5317 6.71474 4.5317C6.36687 4.5317 6.02708 4.55559 5.69539 4.61134V3.49634H22.1182V23.0886C22.1101 23.654 21.6328 24.1239 21.0503 24.1239Z"
          fill={fillColor}
        />
        <path
          d="M19.5773 6.99268H16.9157C16.673 6.99268 16.4707 7.19178 16.4707 7.43071C16.4707 7.66964 16.673 7.86875 16.9157 7.86875H19.5773C19.82 7.86875 20.0222 7.66964 20.0222 7.43071C20.0222 7.19178 19.82 6.99268 19.5773 6.99268Z"
          fill={fillColor}
        />
        <path
          d="M19.5773 10.688H16.9157C16.673 10.688 16.4707 10.8871 16.4707 11.126C16.4707 11.365 16.673 11.5641 16.9157 11.5641H19.5773C19.82 11.5641 20.0222 11.365 20.0222 11.126C20.0222 10.8871 19.82 10.688 19.5773 10.688Z"
          fill={fillColor}
        />
        <path
          d="M19.5773 14.543H16.9157C16.673 14.543 16.4707 14.7421 16.4707 14.981C16.4707 15.2199 16.673 15.419 16.9157 15.419H19.5773C19.82 15.419 20.0222 15.2199 20.0222 14.981C20.0222 14.7421 19.82 14.543 19.5773 14.543Z"
          fill={fillColor}
        />
        <path
          d="M19.577 18.27H13.6793C13.4366 18.27 13.2344 18.4691 13.2344 18.7081C13.2344 18.947 13.4366 19.1461 13.6793 19.1461H19.577C19.8197 19.1461 20.0219 18.947 20.0219 18.7081C20.0219 18.4691 19.8197 18.27 19.577 18.27Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_7998_10703">
          <rect width="23" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClosureIcon;
