import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  JobTypeAndCount,
  fetchAllCustomersAndInterventions,
} from '../../integration/allCustomersAndInterventions.api';

export const COUSTOMERS_AND_INTERVENTIONS_KEY = 'allCustomersAndInterventions';

export interface AllCustomersAndInterventionsState {
  status: FetchingStatus;
  error: string | null;
  metrics: JobTypeAndCount[] | null;
}

const initialState: AllCustomersAndInterventionsState = {
  status: FetchingStatus.IDLE,
  error: null,
  metrics: null,
};

export const getAllCustomersAndInterventions = createAsyncThunk<
  JobTypeAndCount[],
  void,
  AppThunkConfig
>('getAllCustomersAndInterventions', async (_, { getState, signal }) => {
  const {
    filters: {
      dashboard: { country, equipmentType, timeRange },
    },
    mockServer,
  } = getState();

  return fetchAllCustomersAndInterventions({
    country,
    timeRange,
    equipmentType,
    signal,
    mock: mockServer.active,
  });
});

const allCustomersAndInterventionsSlice = createSlice({
  name: COUSTOMERS_AND_INTERVENTIONS_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllCustomersAndInterventions.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getAllCustomersAndInterventions.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.metrics = action.payload;
      })
      .addCase(getAllCustomersAndInterventions.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.metrics = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = allCustomersAndInterventionsSlice;

export const allCustomersAndInterventionsReducer = reducer;
