import getFtfrSeed from '../seed/firstTimeFixRate.seed';
import { TimeRange } from '../../types/common';
import countriesSeed from '../seed/countries.seed';
import searchSeed from '../seed/search.seed';
import openJobsSeed from '../seed/openJobs.seed';
import getFailureRatesSeed from '../seed/failureRates.seed';
import getMttrSeed from '../seed/meanTimeToResolution.seed';
import getMtbvSeed from '../seed/meanTimeBetweenVisits.seed';
import getTrendingEquipment from '../seed/trendingEquipment.seed';
import {
  getAllCoustomersAndInterventions,
  getTrendingCustomers,
} from '../seed/trendingCustomers.seed';
import getSymptomsSeed from '../seed/symptoms.seed';
import getTrendingParts from '../seed/trendingParts.seed';
import getRatingsAndReviews from '../seed/ratingsAndReviews.seed';
import { workflowBottleneckSeed } from '../seed/workflowBottleneck.seed';
import getAllSymptomsSeed from '../seed/all-symptoms.seed';
import allPredictionsSeed from '../seed/allPredictions.seed';
import {
  partPredictionQualitySeed,
  rcPredictionQualitySeed,
} from '../seed/predictionQuality.seed';
import getDocumentManagementSeedData from '../seed/documentManagement.seed';
import { ApiResponse, RequestParams } from '../../integration/api';
import { isDefined } from '../../utils/isDefined';

const isTestEnv = process.env.NODE_ENV === 'test';
const RESPONSE_DELAY_MS = isTestEnv ? 50 : 1500;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MockHandler = (params: RequestParams) => ApiResponse<any>;

export const handleMockRequest = async (
  params: RequestParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const handler = serverHandlers[params.path];
  if (!isDefined(handler)) {
    throw new Error(`Handler not found for path ${params.path}`);
  }
  const result = handler(params);

  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(result);
    }, RESPONSE_DELAY_MS);
  });
};

const serverHandlers: Record<string, MockHandler> = {
  '/domain-model/api/v1/countries/actions/search': () => ({
    data: countriesSeed,
  }),
  '/domain-model/api/v1/equipments/actions/search': () => ({
    data: searchSeed,
  }),
  '/domain-model/api/v1/customers/actions/search': () => ({
    data: searchSeed,
  }),
  '/analytics/api/v1/ratings': () => ({
    data: getRatingsAndReviews(),
  }),
  '/knowledge-base/api/v1/documents': () => ({
    data: getDocumentManagementSeedData(),
  }),
  '/knowledge-base/api/v1/presigned_urls': ({ body }) => {
    if (!isDefined(body)) {
      return { data: {} };
    }
    const fileNames = (body as { filename: string }[]).map(
      (file) => file.filename,
    );
    const data = fileNames.reduce((acc, fileName) => {
      return {
        ...acc,
        [fileName]:
          'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Wind_Turbine_Generator_Systems_3.6-137_50+60Hz_Technical_Documentation.pdf#page=2',
      };
    }, {});

    return { data };
  },
  '/analytics/api/v1/metrics': ({ queryParams = {} }) => {
    const {
      type,
      timeRange,
      country,
      customerId: customer,
      equipmentType,
    } = queryParams as Record<string, string>;
    const allFilters = {
      timeRange: timeRange as TimeRange,
      country,
      customer,
      equipmentType,
    };

    switch (type) {
      case 'FirstTimeFixRate':
        return {
          data: getFtfrSeed(allFilters),
        };
      case 'MeanTimeToResolution':
        return {
          data: getMttrSeed({
            timeRange: timeRange as TimeRange,
            country,
            customer,
            equipmentType,
          }),
        };
      case 'MeanTimeBetweenVisits':
        return {
          data: getMtbvSeed({
            timeRange: timeRange as TimeRange,
            country,
            customer,
            equipmentType,
          }),
        };
      case 'FailureRate':
        return {
          data: getFailureRatesSeed(allFilters),
        };
      case 'CustomersInFocus':
        return {
          data: {
            customers_in_focus: getTrendingCustomers(allFilters),
          },
        };
      case 'JobTypesInFocus':
        return {
          data: {
            job_types_in_focus: getAllCoustomersAndInterventions(allFilters),
          },
        };
      case 'TopEquipmentFailure':
        return {
          data: getTrendingEquipment({
            timeRange: timeRange as TimeRange,
            country,
            customer,
          }),
        };
      case 'TopSymptoms':
        return {
          data: getSymptomsSeed(allFilters),
        };
      case 'AllSymptoms':
        return {
          data: getAllSymptomsSeed(),
        };
      default:
        throw new Error(`Missing analytics handler for type ${type}!`);
    }
  },
  '/domain-model/api/v1/jobs': ({ queryParams = {} }) => {
    const {
      timeRange,
      country,
      equipmentType,
      page,
      size,
      sort,
      customerId: customer,
    } = queryParams as Record<string, string>;

    return openJobsSeed({
      country,
      customer,
      equipmentType,
      sort,
      timeRange: timeRange as TimeRange,
      page: Number(page),
      size: Number(size),
    });
  },
  '/parts-forecast/api/v1/forecast': () => ({
    data: getTrendingParts(),
  }),
  '/workflow/api/v1/workflows': () => ({
    data: workflowBottleneckSeed,
  }),
  '/fault/api/v2/predictions': () => {
    return { data: { predictions: allPredictionsSeed } };
  },
  '/fault/api/v1/symptoms': () => {
    return {
      data: { symptoms: [] },
    };
  },
  '/rc-prediction-quality': () => ({
    data: rcPredictionQualitySeed,
  }),
  '/accuracy-check/api/v1/prediction-quality-summary': () => ({
    data: partPredictionQualitySeed,
  }),
};
