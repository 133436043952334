import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  DocumentManagementData,
  DocumentSortType,
  DocumentUploadResponse,
  fetchDocumentManagementData,
  getPreSignedUrls,
  uploadFiles,
} from '../../integration/documentManagement.api';

export const DOCUMENT_MANAGEMENT_KEY = 'documentManagement';

export interface DocumentManagementParameters {
  documentData: {
    data: DocumentManagementData;
    status: FetchingStatus;
    error: string | null;
  };
  documentUpload: {
    data: DocumentUploadResponse;
    status: FetchingStatus;
    error: string | null;
  };
}
interface PaginationParams {
  page: number;
  size: number;
  sort: DocumentSortType;
}

const initialState: DocumentManagementParameters = {
  documentData: {
    data: {
      size: 0,
      number: 0,
      first: false,
      last: false,
      numberOfElements: 0,
      totalElements: 0,
      totalPages: 0,
      items: [],
    },
    status: FetchingStatus.IDLE,
    error: null,
  },
  documentUpload: {
    data: {
      successes: [],
      failures: [],
    },
    status: FetchingStatus.IDLE,
    error: null,
  },
};

export const getDocumentManagementData = createAsyncThunk<
  DocumentManagementData,
  PaginationParams,
  AppThunkConfig
>(
  'getDocumentManagementData',
  async ({ page, size, sort }, { getState, signal }) => {
    const {
      filters: {
        dashboard: { equipmentType },
      },
      mockServer,
    } = getState();
    const tags = equipmentType !== null ? [equipmentType] : null;
    return fetchDocumentManagementData({
      tags,
      signal,
      page,
      size,
      sort,
      mock: mockServer.active,
    });
  },
);

export const postDocumentManagementData = createAsyncThunk<
  DocumentUploadResponse,
  File[],
  AppThunkConfig
>('postDocumentManagementData', async (files, { getState, signal }) => {
  const {
    filters: {
      dashboard: { equipmentType },
    },
    mockServer,
  } = getState();
  const mock = mockServer.active;
  const preSignedUrls = await getPreSignedUrls({
    equipmentType,
    files,
    signal,
    mock,
  });

  return uploadFiles({
    files,
    preSignedUrls,
    signal,
    mock,
  });
});

const documentManagementSlice = createSlice({
  name: DOCUMENT_MANAGEMENT_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDocumentManagementData.pending, (state) => {
        state.documentData.status = FetchingStatus.PENDING;
      })
      .addCase(getDocumentManagementData.fulfilled, (state, action) => {
        state.documentData.status = FetchingStatus.SUCCESS;
        state.documentData.data = action.payload;
      })
      .addCase(getDocumentManagementData.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.documentData.status = FetchingStatus.ERROR;
        state.documentData.error =
          action.error.message || 'Something went wrong.';
      })
      .addCase(postDocumentManagementData.pending, (state) => {
        state.documentUpload.status = FetchingStatus.PENDING;
      })
      .addCase(postDocumentManagementData.fulfilled, (state, action) => {
        state.documentUpload.status = FetchingStatus.SUCCESS;
        state.documentUpload.data = action.payload;
      })
      .addCase(postDocumentManagementData.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.documentUpload.status = FetchingStatus.ERROR;
        state.documentUpload = initialState.documentUpload;
      });
  },
});

const { reducer } = documentManagementSlice;

export const documentManagementReducer = reducer;
