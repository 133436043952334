import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../store/store';

export enum FetchingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum TimeRange {
  ONE_YEAR = '1-year',
  SIX_MONTHS = '6-months',
  ONE_MONTH = '1-month',
  ONE_WEEK = '1-week',
}

export interface FetchKpiParams {
  withTrend?: boolean;
}

export type ApiCall<R, A> =
  | ReturnType<AsyncThunkAction<R, A, AppThunkConfig>>
  | undefined;

export interface MetricWithTrend {
  trend: number | null;
}

export interface AppDialogProps {
  open: boolean;
  handleClose: () => void;
  keepMounted?: boolean;
}

export type Nullable<T> = T | null;

export enum DashboardTour {
  COMPLETE = 'complete',
}
