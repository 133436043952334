import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus, FetchKpiParams } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  FailureRatesData,
  fetchFailureRates,
} from '../../integration/failureRates.api';

export const FAILURE_RATES_KEY = 'failureRates';

export interface FailureRatesState {
  status: FetchingStatus;
  error: string | null;
  metrics: FailureRatesData | null;
}

const initialState: FailureRatesState = {
  status: FetchingStatus.IDLE,
  error: null,
  metrics: null,
};

export const getFailureRates = createAsyncThunk<
  FailureRatesData,
  FetchKpiParams,
  AppThunkConfig
>('getFailureRates', async ({ withTrend = false }, { getState, signal }) => {
  const {
    filters: {
      dashboard: { country, customer, equipmentType, timeRange },
    },
    mockServer,
  } = getState();

  return fetchFailureRates({
    country,
    equipmentType,
    timeRange,
    withTrend,
    signal,
    customerId: customer?.id,
    mock: mockServer.active,
  });
});

const failureRateSlice = createSlice({
  name: FAILURE_RATES_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFailureRates.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getFailureRates.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.metrics = action.payload;
      })
      .addCase(getFailureRates.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.metrics = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = failureRateSlice;

export const failureRatesReducer = reducer;
