import { MetricWithTrend } from '../types/common';
import api, { ApiResponse, FetchMetricsParams } from './api';

export interface MeanTimeBetweenVisitsMetric {
  date_interval: string;
  mean_time_between_visits: string; // TODO: BE is sending string, but actually a number
}

export interface MeanTimeBetweenVisitsData extends MetricWithTrend {
  mean_time_between_visits: MeanTimeBetweenVisitsMetric[];
}

type MtbvResponse = ApiResponse<MeanTimeBetweenVisitsData>;

export const fetchMeanTimeBetweenVisits = async ({
  country,
  equipmentType,
  timeRange,
  withTrend,
  customerId,
  signal,
  mock,
}: FetchMetricsParams): Promise<MeanTimeBetweenVisitsData> => {
  const { data } = await api.get<MtbvResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      equipmentType,
      timeRange,
      withTrend,
      customerId,
      country,
      type: 'MeanTimeBetweenVisits',
    },
    config: { signal },
  });

  return data;
};
