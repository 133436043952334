import React from 'react';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';

const LineGraphSvg: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const fillColor = isDarkMode ? '#ffffff' : '#000000';
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 5.52881L12.5821 9.5717C12.1916 9.96223 11.5584 9.96223 11.1679 9.5717L10.2071 8.61092C9.81658 8.22039 9.18342 8.22039 8.79289 8.61092L5.54167 11.8621"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 3.15381V14.2038C2.375 15.3239 2.375 15.884 2.59299 16.3118C2.78473 16.6881 3.09069 16.9941 3.46702 17.1858C3.89484 17.4038 4.45489 17.4038 5.575 17.4038H16.625"
        stroke={fillColor}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LineGraphSvg;
