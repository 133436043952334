import { TimeRange } from '../../types/common';
import { GetSeedParams } from '../test-utils';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { TrendingSymptomMetric } from '../../integration/trendingSymptoms.api';

const symptomsSeed: TrendingSymptomMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 78,
  },
  {
    symptom: 'Generator failure',
    jobscount: 61,
  },
  {
    symptom: 'Blade failure',
    jobscount: 55,
  },
  {
    symptom: 'Control system failure',
    jobscount: 30,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 28,
  },
];

const symptomsSeedForCountry: TrendingSymptomMetric[] = [
  {
    symptom: 'Control system failure',
    jobscount: 30,
  },
  {
    symptom: 'Gearbox failure',
    jobscount: 29,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 22,
  },
  {
    symptom: 'Generator failure',
    jobscount: 14,
  },
  {
    symptom: 'Blade failure',
    jobscount: 5,
  },
];

const symptomsSeedForCustomer: TrendingSymptomMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 14,
  },
  {
    symptom: 'Blade failure',
    jobscount: 11,
  },
  {
    symptom: 'Generator failure',
    jobscount: 7,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 3,
  },
  {
    symptom: 'Control system failure',
    jobscount: 1,
  },
];

const symptomsSeedForEquipmentType: TrendingSymptomMetric[] = [
  {
    symptom: 'Electrical component failure',
    jobscount: 39,
  },
  {
    symptom: 'Control system failure',
    jobscount: 30,
  },
  {
    symptom: 'Generator failure',
    jobscount: 27,
  },
  {
    symptom: 'Gearbox failure',
    jobscount: 13,
  },
  {
    symptom: 'Blade failure',
    jobscount: 9,
  },
];

const symptomsSeedForSixMonth: TrendingSymptomMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 40,
  },
  {
    symptom: 'Generator failure',
    jobscount: 33,
  },
  {
    symptom: 'Blade failure',
    jobscount: 30,
  },
  {
    symptom: 'Control system failure',
    jobscount: 15,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 10,
  },
];

const getSymptomsSeed = ({
  timeRange,
  country,
  customer,
  equipmentType,
}: GetSeedParams): TrendingSymptomMetric[] | null => {
  if (country === countriesSeed.countries[1].code) {
    return symptomsSeedForCountry;
  }

  if (customer === searchSeed.customers[0].id) {
    return symptomsSeedForCustomer;
  }

  if (timeRange === TimeRange.SIX_MONTHS) {
    return symptomsSeedForSixMonth;
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return [];
  }

  if (equipmentType === searchSeed.equipment_types[1]) {
    return symptomsSeedForEquipmentType;
  }

  return symptomsSeed;
};

export default getSymptomsSeed;
