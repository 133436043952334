import { isDefined } from '@insightloop/common-ui';

import api, { ApiFetchParams, ApiResponse } from './api';

export interface FileInfo {
  document_id?: string;
  filename: string;
  url: string;
  size: string;
  timestamp: string;
  tags?: string[];
}

export interface DocumentUploadResponse {
  successes: string[];
  failures: string[];
}

export interface DocumentManagementData {
  items: FileInfo[];
  size: number;
  number: number;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
}

type DocumentManagementResponse = ApiResponse<DocumentManagementData>;

export enum DocumentSortType {
  Size = 'size,desc',
  FileName = 'filename,asc',
  Date = 'created_at,desc',
}

interface FetchPredictionQualityParams extends ApiFetchParams {
  page: number;
  size: number;
  sort: DocumentSortType;
  tags: string[] | null;
}

export const fetchDocumentManagementData = async ({
  page,
  size,
  sort,
  tags,
  signal,
  mock,
}: FetchPredictionQualityParams): Promise<DocumentManagementData> => {
  const { data } = await api.get<DocumentManagementResponse>({
    mock,
    path: '/knowledge-base/api/v1/documents',
    queryParams: {
      page,
      size,
      sort,
      tags: tags,
    },
    config: { signal },
  });

  return data;
};

interface GetPreSignedUrlsParams extends ApiFetchParams {
  equipmentType: string | null;
  files: File[];
}

export const getPreSignedUrls = async ({
  equipmentType,
  files,
  mock,
  signal,
}: GetPreSignedUrlsParams): Promise<Record<string, string>> => {
  const response = await api.post<ApiResponse<Record<string, string>>>({
    mock,
    path: '/knowledge-base/api/v1/presigned_urls',
    config: { signal },
    body: files.map((file) => ({
      filename: file.name,
      file_size: file.size,
      tags: equipmentType === null ? null : [equipmentType],
    })),
  });

  return response.data;
};

interface UploadFilesParams extends ApiFetchParams {
  files: File[];
  preSignedUrls: Record<string, string>;
}

export const uploadFiles = async ({
  files,
  preSignedUrls,
  signal,
  mock,
}: UploadFilesParams): Promise<DocumentUploadResponse> => {
  if (mock) {
    return {
      successes: files.map((file) => file.name),
      failures: [],
    };
  }
  const results = await Promise.all(
    files.map((file) => {
      const fileName = file.name;
      const url = preSignedUrls[fileName];
      if (!isDefined(url)) {
        return { fileName, success: false };
      }

      return fetch(url, { signal, method: 'PUT', body: file })
        .then(() => registerUploadedFile({ fileName, mock, signal }))
        .then(() => ({ fileName, success: true }))
        .catch(() => ({ fileName, success: false }));
    }),
  );

  return {
    successes: results
      .filter((result) => result.success)
      .map((result) => result.fileName),
    failures: results
      .filter((result) => !result.success)
      .map((result) => result.fileName),
  };
};

interface PatchFileNamesParams extends ApiFetchParams {
  fileName: string;
}

const registerUploadedFile = async ({
  fileName,
  mock,
  signal,
}: PatchFileNamesParams): Promise<void> => {
  return api.patch<void>({
    mock,
    path: '/knowledge-base/api/v1/documents',
    config: { signal },
    queryParams: {
      filename: fileName,
    },
  });
};
