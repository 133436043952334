import { WorkflowBottleneckData } from '../../integration/workflowBottleneck.api';

export const workflowBottleneckSeed: WorkflowBottleneckData = {
  steps: [
    {
      name: 'closing',
      durations: {
        values: [
          { date_interval: '2022-04-01', duration: 1293792 },
          { date_interval: '2022-05-01', duration: 875723 },
          { date_interval: '2022-06-01', duration: 1403453 },
          { date_interval: '2022-07-01', duration: 1627797 },
          { date_interval: '2022-08-01', duration: 1359691 },
          { date_interval: '2022-09-01', duration: 1391189 },
          { date_interval: '2022-10-01', duration: 1119005 },
          { date_interval: '2022-11-01', duration: 885230 },
          { date_interval: '2022-12-01', duration: 720913 },
          { date_interval: '2023-01-01', duration: 581397 },
          { date_interval: '2023-02-01', duration: 485451 },
          { date_interval: '2023-03-01', duration: 306524 },
        ],
        target: 1403453,
        current: 1119005,
        trend: -0.18,
      },
      scores: [
        {
          name: 'Job closed',
          value: 2376,
          trend: {
            value: 0.17,
            direction: 'positive',
            positive_impact: true,
          },
          score_type: 'count',
        },
        {
          name: 'Job closed - invoiced',
          value: 3600,
          trend: {
            value: -0.34,
            direction: 'negative',
            positive_impact: true,
          },
          score_type: 'count',
        },
      ],
    },
    {
      name: 'scheduling',
      durations: {
        values: [
          { date_interval: '2022-04-01', duration: 44104 },
          { date_interval: '2022-05-01', duration: 49047 },
          { date_interval: '2022-06-01', duration: 641224 },
          { date_interval: '2022-07-01', duration: 569463 },
          { date_interval: '2022-08-01', duration: 674631 },
          { date_interval: '2022-09-01', duration: 404255 },
          { date_interval: '2022-10-01', duration: 711629 },
          { date_interval: '2022-11-01', duration: 319166 },
          { date_interval: '2022-12-01', duration: 374428 },
          { date_interval: '2023-01-01', duration: 283530 },
          { date_interval: '2023-02-01', duration: 375157 },
          { date_interval: '2023-03-01', duration: 171001 },
        ],
        target: 674631,
        current: 283530,
        trend: -0.8,
      },
      scores: [
        {
          name: 'Task interruption',
          value: '95/345',
          trend: { value: -0.4, direction: 'negative', positive_impact: true },
          score_type: 'ratio',
        },
        {
          name: 'Time to schedule',
          value: 18000,
          trend: { value: -0.24, direction: 'negative', positive_impact: true },
          score_type: 'duration',
        },
        {
          name: 'Scheduled Travel Time',
          value: 86400,
          trend: { value: 0.6, direction: 'positive', positive_impact: false },
          score_type: 'duration',
        },
        {
          name: 'Time to visit',
          value: 1655849.17,
          trend: { value: -0.11, direction: 'negative', positive_impact: true },
          score_type: 'duration',
        },
        {
          name: 'Dispatcher/Tech Ratio',
          value: '2288 / 3329',
          trend: { value: 0.2, direction: 'positive', positive_impact: true },
          score_type: 'ratio',
        },
      ],
    },
    {
      name: 'jobGeneration',
      durations: {
        values: [
          { date_interval: '2022-04-01', duration: 24980 },
          { date_interval: '2022-05-01', duration: 1145 },
          { date_interval: '2022-06-01', duration: 99921 },
          { date_interval: '2022-07-01', duration: 88956 },
          { date_interval: '2022-08-01', duration: 38585 },
          { date_interval: '2022-09-01', duration: 43912 },
          { date_interval: '2022-10-01', duration: 126617 },
          { date_interval: '2022-11-01', duration: 141506 },
          { date_interval: '2022-12-01', duration: 25445 },
          { date_interval: '2023-01-01', duration: 24628 },
          { date_interval: '2023-02-01', duration: 5215 },
          { date_interval: '2023-03-01', duration: 3435 },
        ],
        target: 38585,
        current: 25445,
        trend: 0.12,
      },
      scores: [
        {
          name: 'Job backlog',
          value: 3434,
          trend: {
            value: 0.6,
            direction: 'positive',
            positive_impact: false,
          },
          score_type: 'count',
        },
        {
          name: 'Job Description Quality',
          value: 0.6,
          trend: { value: 0.17, direction: 'positive', positive_impact: true },
          score_type: 'percentage',
        },
      ],
    },
    {
      name: 'fieldWork',
      durations: {
        values: [
          { date_interval: '2022-04-01', duration: 1042384 },
          { date_interval: '2022-05-01', duration: 1059785 },
          { date_interval: '2022-06-01', duration: 1342188 },
          { date_interval: '2022-07-01', duration: 1150640 },
          { date_interval: '2022-08-01', duration: 1118296 },
          { date_interval: '2022-09-01', duration: 998654 },
          { date_interval: '2022-10-01', duration: 816004 },
          { date_interval: '2022-11-01', duration: 698442 },
          { date_interval: '2022-12-01', duration: 957125 },
          { date_interval: '2023-01-01', duration: 680659 },
          { date_interval: '2023-02-01', duration: 601119 },
          { date_interval: '2023-03-01', duration: 609469 },
        ],
        target: 601119,
        current: 609469,
        trend: 0.14,
      },
      scores: [
        {
          name: 'Utilization rate',
          value: 0.66,
          trend: { value: -0.9, direction: 'negative', positive_impact: false },
          score_type: 'percentage',
        },
        {
          name: 'Field Visits per Day',
          value: 31,
          trend: { value: 0.14, direction: 'positive', positive_impact: true },
          score_type: 'count',
        },
        {
          name: 'Field Visits on Time',
          value: 0.82,
          trend: {
            value: -0.5,
            direction: 'negative',
            positive_impact: false,
          },
          score_type: 'percentage',
        },
        {
          name: 'Technician Idle Time',
          value: 217826.9,
          trend: {
            value: -0.11,
            direction: 'negative',
            positive_impact: true,
          },
          score_type: 'duration',
        },
        {
          name: 'Variance Task Duration',
          value: 765356.2,
          trend: {
            value: 0.12,
            direction: 'positive',
            positive_impact: false,
          },
          score_type: 'duration',
        },
        {
          name: 'Ordered Parts Unused',
          value: 3.2,
          trend: {
            value: 0.19,
            direction: 'positive',
            positive_impact: false,
          },
          score_type: 'count',
        },
      ],
    },
  ],
};
