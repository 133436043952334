import React from 'react';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';

const LogoSvg: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const fillColor = isDarkMode ? '#ffffff' : '#000000';
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 720 370"
      enableBackground="new 0 0 720 370"
      xmlSpace="preserve"
    >
      <path
        fill={fillColor}
        opacity="1.000000"
        stroke="none"
        d="
M483.310211,238.616714 
	C464.145447,215.399612 463.664001,178.535110 482.390869,155.618927 
	C507.350677,125.075470 548.551514,123.186180 576.142578,144.505951 
	C591.131409,156.087830 599.011169,172.241714 599.338257,191.573898 
	C599.408752,195.738022 599.117920,199.920303 599.407654,204.065292 
	C599.701660,208.271729 598.030090,209.444992 593.956238,209.423615 
	C566.299377,209.278381 538.641418,209.333420 510.983856,209.370255 
	C509.044495,209.372833 507.001007,208.840973 504.650299,210.260986 
	C507.964844,220.392715 515.003601,226.468094 525.109253,229.030518 
	C543.325562,233.649536 556.782593,226.983917 566.323364,210.524277 
	C575.214905,215.870361 584.010193,221.077866 592.713562,226.434708 
	C595.813232,228.342529 593.666992,230.570526 592.490051,232.376907 
	C583.626587,245.980835 571.662781,255.546326 555.750854,259.548737 
	C529.201355,266.226929 505.477905,261.455353 485.660278,241.634995 
	C484.848907,240.823486 484.225983,239.823547 483.310211,238.616714 
M557.233215,171.279175 
	C547.931641,162.501526 537.084167,160.474762 525.021667,163.998260 
	C516.549805,166.472900 510.621765,171.897415 505.957733,180.251617 
	C525.305481,180.251617 543.601379,180.251617 561.969971,180.251617 
	C561.225586,176.149673 561.158081,176.018707 557.233215,171.279175 
z"
      />
      <path
        fill={fillColor}
        opacity="1.000000"
        stroke="none"
        d="
M364.537842,241.521454 
	C350.822998,256.315796 333.936737,262.933472 314.393402,262.281097 
	C284.071533,261.268982 258.494110,240.461929 252.812943,210.392715 
	C248.340286,186.719955 253.804825,165.567383 271.490997,148.370590 
	C280.677551,139.438217 291.789246,134.041046 304.299408,131.767090 
	C322.209534,128.511597 338.939606,131.811096 354.104950,142.019882 
	C368.868713,151.958298 377.772430,166.000580 381.145020,183.282089 
	C384.920807,202.629578 380.816528,220.495850 369.165802,236.495804 
	C367.903381,238.229492 366.256470,239.683197 364.537842,241.521454 
M347.622162,195.605103 
	C347.269806,193.653549 346.967133,191.691498 346.557526,189.752045 
	C342.021820,168.276855 317.509949,158.109161 300.010986,170.434067 
	C289.143738,178.088104 284.014099,191.101913 287.607513,204.669525 
	C292.313080,222.436249 311.543304,233.567719 329.487762,224.796051 
	C341.366730,218.989334 346.172058,208.838852 347.622162,195.605103 
z"
      />
      <path
        fill={fillColor}
        opacity="1.000000"
        stroke="none"
        d="
M204.565521,224.625122 
	C212.199631,221.317551 216.635880,215.445419 220.142395,208.016663 
	C231.257019,211.472839 241.489044,216.745804 252.019165,221.205185 
	C243.755112,246.795532 216.522217,264.942902 185.892349,262.176208 
	C150.164169,258.949005 127.751694,232.217255 125.787758,201.274719 
	C123.458008,164.568924 146.679123,138.037064 177.852905,131.892349 
	C210.752914,125.407349 243.219315,143.069168 252.126251,171.634262 
	C242.664139,175.755737 233.171555,179.970169 223.603989,184.006897 
	C220.911072,185.143097 219.351761,183.434052 218.108093,181.124634 
	C215.117371,175.571091 210.807159,171.367249 205.123459,168.475327 
	C191.642944,161.616241 175.476242,165.553528 166.047714,178.133484 
	C157.367599,189.714813 159.017075,207.347290 169.093002,218.046600 
	C178.699173,228.247101 193.846512,230.216568 204.565521,224.625122 
z"
      />
      <path
        fill={fillColor}
        opacity="1.000000"
        stroke="none"
        d="
M422.541901,134.469559 
	C423.131317,139.269745 423.377502,143.735001 423.697571,149.540665 
	C426.173309,146.905579 428.003754,144.891373 429.904755,142.946091 
	C435.898071,136.813187 443.283020,133.870926 451.775848,133.680786 
	C456.771912,133.568939 461.774719,133.755768 466.770691,133.641617 
	C469.785736,133.572708 471.426147,134.420013 471.373688,137.838760 
	C471.238190,146.667664 471.252899,155.501099 471.373505,164.330460 
	C471.415710,167.419800 470.078705,168.417175 467.179321,168.372070 
	C461.015900,168.276199 454.849762,168.352722 448.684723,168.364441 
	C433.870178,168.392609 424.458435,177.663986 424.368225,192.657547 
	C424.246918,212.818283 424.212830,232.981552 424.424408,253.140762 
	C424.473724,257.839996 423.187286,259.653656 418.265625,259.452026 
	C409.615448,259.097687 400.938873,259.232910 392.277313,259.385773 
	C388.803864,259.447021 387.610596,258.130127 387.616852,254.705963 
	C387.687988,215.882782 387.658691,177.059448 387.667938,138.236145 
	C387.668457,136.058319 387.446655,133.664276 390.760590,133.713211 
	C401.239746,133.867966 411.731110,133.111526 422.541901,134.469559 
z"
      />
    </svg>
  );
};

export default LogoSvg;
