import api, { ApiResponse, FetchMetricsParams } from './api';

export interface TrendingSymptomMetric {
  jobscount: number;
  symptom: string;
}

type TrendingSymptomsResponse = ApiResponse<TrendingSymptomMetric[]>;

export const fetchTrendingSymptoms = async ({
  country,
  equipmentType,
  timeRange,
  customerId,
  signal,
  mock,
}: FetchMetricsParams): Promise<TrendingSymptomMetric[]> => {
  const { data } = await api.get<TrendingSymptomsResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      equipmentType,
      timeRange,
      customerId,
      country,
      type: 'TopSymptoms',
    },
    config: { signal },
  });

  return data;
};
