import { Symptom } from '../../store/slices/symptomSearch.slice';

const symptomsSeed: Symptom[] = [
  {
    name: 'Gearbox failure',
    count: 51,
  },
  {
    name: 'Generator failure',
    count: 32,
  },
  {
    name: 'Blade failure',
    count: 25,
  },
  {
    name: 'Control system failure',
    count: 15,
  },
  {
    name: 'Electrical component failure',
    count: 7,
  },
];

const getAllSymptomsSeed = (): Symptom[] | null => {
  return symptomsSeed;
};

export default getAllSymptomsSeed;
