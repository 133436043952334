import React from 'react';

interface AxisArrowProps {
  color: string;
}

const AxisArrow: React.FC<AxisArrowProps> = (props) => {
  return (
    <marker
      id="arrow"
      viewBox="0 0 15 10"
      refX="9"
      refY="5"
      markerWidth="15"
      markerHeight="10"
      orient="auto-start-reverse"
    >
      <path d="M 5 0 L 10 5 L 5 10" stroke={props.color} fill="none" />
    </marker>
  );
};
export default AxisArrow;
