import { useDarkModeSelected } from '../../../../store/slices/theme.slice';

export const getIconColor = (isSelected: boolean) => {
  const isDarkMode = useDarkModeSelected();
  if (isDarkMode) {
    return isSelected ? 'white' : '#00BDC7';
  } else {
    return '#00BDC7';
  }
};
