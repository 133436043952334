import React from 'react';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';

const ArrowSvg: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const fillColor = isDarkMode ? '#ffffff' : '#000000';
  return (
    <svg
      width="14"
      height="6"
      viewBox="0 0 14 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 5L13 1" stroke={fillColor} strokeLinecap="round" />
    </svg>
  );
};

export default ArrowSvg;
