import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchRatingsAndReviews,
  RatingsAndReviewsDataset,
} from '../../integration/ratingsAndReviews.api';

export const RATINGS_AND_REVIEWS_KEY = 'ratingsAndReviews';

export interface RatingsAndReviewsState {
  status: FetchingStatus;
  error: string | null;
  data: RatingsAndReviewsDataset | null;
}
const initialState: RatingsAndReviewsState = {
  status: FetchingStatus.IDLE,
  error: null,
  data: null,
};

export const getRatingsAndReviews = createAsyncThunk<
  RatingsAndReviewsDataset,
  void,
  AppThunkConfig
>('getRatingsAndReviews', async (_, { getState, signal }) => {
  const { mockServer } = getState();

  return fetchRatingsAndReviews({ signal, mock: mockServer.active });
});

const ratingsAndReviewsSlice = createSlice({
  name: RATINGS_AND_REVIEWS_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRatingsAndReviews.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getRatingsAndReviews.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getRatingsAndReviews.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.data = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = ratingsAndReviewsSlice;

export const ratingsAndReviewsReducer = reducer;
