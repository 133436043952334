import React, { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material';

import InfoCard from './infoCard/InfoCard';
import styles from './FailureRates.module.scss';
import LineGraphSvg from './icons/LineGraphSvg';
import BuildIconSvg from './icons/BuildIconSvg';
import CorporateFareSvg from './icons/CorporateFareSvg';
import DescriptionSvg from './icons/DescriptionSvg';
import FailureRatesChart from './chart/FailureRatesChart';
import { ReactComponent as FailureRatesIcon } from './icons/failure-rates.svg';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { useMockServerActive } from '../../../store/slices/mockServer.slice';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';
import { useTranslations } from '../../../store/slices/translation.slice';
import { getFailureRates } from '../../../store/slices/failureRates.slice';
import { FetchingStatus } from '../../../types/common';
import CardContainer from '../../cardContainer/CardContainer';
import NoDataAvailableCard from '../../cardContainer/NoDataAvailableCard/NoDataAvailableCard';
import { formatNumberByLocale } from '../../../utils/formatNumberByLocale';
import { isDefined } from '../../../utils/isDefined';

const FailureRates: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMockServerActive = useMockServerActive();
  const { country, customer, equipmentType, timeRange } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const { status: failureRatesStatus, metrics: failureRatesData } =
    useAppSelector((state) => state.failureRates);
  const locale = useAppSelector((state) => state.translation.locale);

  const { timeRanges, failureRate: translations } = useTranslations();
  const isDarkMode = useDarkModeSelected();

  useEffect(() => {
    const promise = dispatch(getFailureRates({ withTrend: true }));

    return () => {
      promise.abort();
    };
  }, [
    country,
    customer,
    equipmentType,
    timeRange,
    isMockServerActive,
    dispatch,
  ]);

  const timeRangeText = timeRanges[timeRange];

  const result = useMemo(() => {
    // Oddly enough, the server sends back an array instead of the expected object when there is no data.
    if (failureRatesData === null || Array.isArray(failureRatesData)) {
      return {
        failureRate: null,
        trend: null,
        inTheField: null,
        withIssues: null,
        customersImpacted: null,
      };
    }
    return {
      failureRate:
        failureRatesData.failure_rate === null
          ? null
          : parseFloat(
              (Number(failureRatesData.failure_rate) * 100).toFixed(2),
            ),
      trend:
        failureRatesData.trend === null
          ? null
          : parseFloat((failureRatesData.trend * 100).toFixed(2)),
      customersImpacted: failureRatesData.customers_impacted,
      withIssues: failureRatesData.with_issues,
      inTheField: failureRatesData.in_the_field,
    };
  }, [failureRatesData]);

  const chartData = useMemo(() => {
    if (result.failureRate === null) {
      return null;
    }
    const failureRateCount = Math.round(result.failureRate);
    const reliabilityCount = 100 - failureRateCount;

    return [
      {
        id: 'failureRate',
        name: translations.failureRateText,
        count: failureRateCount,
      },
      {
        id: 'reliability',
        name: translations.reliability,
        count: reliabilityCount,
      },
    ];
  }, [result, translations]);

  const noDataAvailable =
    failureRatesData === null || Array.isArray(failureRatesData);
  const showNoDataAvailableCard =
    (failureRatesStatus === FetchingStatus.SUCCESS && noDataAvailable) ||
    failureRatesStatus === FetchingStatus.ERROR;

  return (
    <CardContainer
      logo={<FailureRatesIcon />}
      title={`${translations.mainTitle({ equipmentType })}`}
      tooltipContent={translations.tooltip}
      status={failureRatesStatus}
      testId="failure-rates"
      height="326px"
    >
      {showNoDataAvailableCard ? (
        <NoDataAvailableCard />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.infoContainer}>
              <div className={styles.infoCards}>
                <div className={styles.infoCardColumn}>
                  <InfoCard
                    icon={<BuildIconSvg />}
                    title={translations.inTheField}
                    value={formatNumberByLocale(
                      locale,
                      Number(result.inTheField),
                    )}
                    color="#00BDC7"
                    status={failureRatesStatus}
                    testId="in-the-field"
                  />
                  <InfoCard
                    icon={<DescriptionSvg />}
                    title={translations.withIssues}
                    value={formatNumberByLocale(
                      locale,
                      Number(result.withIssues),
                    )}
                    color="#FF8400"
                    status={failureRatesStatus}
                    testId="with-issues"
                  />
                </div>
                <div className={styles.infoCardColumn}>
                  <InfoCard
                    icon={<CorporateFareSvg />}
                    title={translations.locationsImpacted}
                    value={formatNumberByLocale(
                      locale,
                      Number(result.customersImpacted),
                    )}
                    color={isDarkMode ? 'white' : 'black'}
                    status={failureRatesStatus}
                    testId="locations-impacted"
                  />
                  {isDefined(result.trend) && result.trend !== 0 ? (
                    <InfoCard
                      icon={<LineGraphSvg />}
                      title={translations.changesThisPeriod({
                        periodLabel: timeRangeText,
                      })}
                      value={
                        result.trend > 0
                          ? `▲ ${formatNumberByLocale(
                              locale,
                              Number(result.trend.toFixed(1)),
                            )}%`
                          : `▼ ${formatNumberByLocale(
                              locale,
                              Number(result.trend.toFixed(1)),
                            )}%`
                      }
                      valueColor={
                        result.trend > 0
                          ? theme.palette.success.main
                          : theme.palette.error.main
                      }
                      color={isDarkMode ? 'white' : 'black'}
                      status={failureRatesStatus}
                      testId="change"
                    />
                  ) : (
                    <InfoCard
                      icon={<LineGraphSvg />}
                      title={translations.changesThisPeriod({
                        periodLabel: timeRangeText,
                      })}
                      value="N/A"
                      color={isDarkMode ? 'white' : 'black'}
                      status={failureRatesStatus}
                      testId="change"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={styles.chartContainer}
              data-testid="failure-rate-container"
            >
              <FailureRatesChart
                data={chartData}
                label={timeRangeText}
                testId="failure-rates"
                status={failureRatesStatus}
              />
            </div>
          </div>
        </>
      )}
    </CardContainer>
  );
};

export default FailureRates;
