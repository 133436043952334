import api, { ApiFetchParams, ApiResponse } from './api';

export interface Country {
  code: string;
  name: string;
}

type CountriesResponse = ApiResponse<{ countries: Country[] }>;

interface FetchCountriesParams extends ApiFetchParams {
  customerId?: string;
  equipmentType: string | null;
}

export const fetchCountries = async ({
  customerId,
  equipmentType,
  signal,
  mock,
}: FetchCountriesParams): Promise<Country[]> => {
  const { data } = await api.get<CountriesResponse>({
    mock,
    path: '/domain-model/api/v1/countries/actions/search',
    queryParams: {
      customerId,
      equipmentType,
    },
    config: { signal },
  });

  return data.countries;
};
