import React, { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import FiltersBar from './filtersBar/FiltersBar';
import DarkModeToggle from './darkModeToggle/DarkModeToggle';
import MockServerToggle from './mockServerToggle/MockServerToggle';
import LogoSvg from './icon/LogoSvg';
import ContactSupportTopicsList from './ContactSupportTopicsList/ContactSupportTopicsList';
import styles from './Header.module.scss';
import { toggleMockServer } from '../../store/slices/mockServer.slice';
import clsx from 'clsx';
import Tour from '../dashboardTour/Tour/Tour';
import {
  HighlightedComponent,
  updateOnboardingState,
} from '../../store/slices/onboardingTour.slice';
import { useDarkModeSelected } from '../../store/slices/theme.slice';
import { useAuthData } from '../../utils/auth';

const CLICKS_TO_TRIGGER_MOCK_TOGGLE = 6;

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'production';
  const authData = useAuthData();
  const isInternalAccount = authData !== null && authData.isInternalAccount;
  const { currentStep, startTour } = useAppSelector(
    (state) => state.onboardingTour,
  );
  const isDarkMode = useDarkModeSelected();

  const isHighlightedForFilter = useMemo(() => {
    return currentStep === HighlightedComponent.HeaderFilters;
  }, [currentStep]);

  const isHighlightedForContactSupport = useMemo(() => {
    return currentStep === HighlightedComponent.ContactSupport;
  }, [currentStep]);

  const [clickCount, setClickCount] = useState(0);
  const handleHiddenMockToggle = () => {
    if (!isInternalAccount) {
      return;
    }
    if (clickCount === CLICKS_TO_TRIGGER_MOCK_TOGGLE - 1) {
      setClickCount(0);
      dispatch(toggleMockServer());
    } else {
      setClickCount((prev) => prev + 1);
    }
  };

  const isHighlighted =
    isHighlightedForFilter || isHighlightedForContactSupport;

  return (
    <>
      <div
        className={clsx(
          styles.container,
          isHighlighted && styles.highlighted,
          isDarkMode && isHighlighted && styles.darkModeHighlighted,
        )}
      >
        <div
          className={styles.logo}
          data-testid="logo"
          onClick={handleHiddenMockToggle}
        >
          <LogoSvg />
        </div>
        <FiltersBar />
        <ContactSupportTopicsList />
        <div className={styles.toggle}>
          {!isProduction && isInternalAccount && <MockServerToggle />}
          <DarkModeToggle />
        </div>
      </div>
      {isHighlightedForFilter && (
        <Tour
          style={{ transform: 'translate(124px,10px)' }}
          shouldStartTour={startTour}
          nextStep={() =>
            dispatch(
              updateOnboardingState({
                currentStep: HighlightedComponent.KpisGlanceAndProcessStatus,
              }),
            )
          }
        />
      )}
    </>
  );
};

export default Header;
