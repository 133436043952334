import { useCallback, useState } from 'react';

export type SelectedChartData<T, I = string> = {
  id: I;
  name: string;
  value: T;
} | null;

export interface SelectChartProps<T> {
  activeChartIndex: number | null;
  handleChartClicked: (
    data: SelectedChartData<T>,
    newIndex: number | null,
  ) => void;
  setIndex: (index: number | null) => void;
}

interface SelectChartParams<T> {
  handleSelect: (data: SelectedChartData<T>, index: number | null) => void;
}

const useSelectChart = <T>({
  handleSelect,
}: SelectChartParams<T>): SelectChartProps<T> => {
  const [index, setIndex] = useState<number | null>(null);
  const handler = useCallback(
    (data: SelectedChartData<T>, newIndex: number | null) => {
      setIndex((prevIndex) => {
        if (prevIndex === newIndex) {
          handleSelect(null, null);
          return null;
        } else {
          handleSelect(data, newIndex);
          return newIndex;
        }
      });
    },
    [handleSelect],
  );

  return {
    setIndex,
    activeChartIndex: index,
    handleChartClicked: handler,
  };
};

export default useSelectChart;
