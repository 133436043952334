import React, { useEffect } from 'react';
import { Button, Dialog, Typography } from '@mui/material';

import styles from './OnboardingDialog.module.scss';
import { ReactComponent as AstronautSvg } from '../assets/astronautSvg.svg';
import { useTranslations } from '../../../store/slices/translation.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import {
  HighlightedComponent,
  updateOnboardingState,
} from '../../../store/slices/onboardingTour.slice';
import { DashboardTour } from '../../../types/common';

const OnboardingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dashboardTour: translation } = useTranslations();
  const { startTour, restartTour } = useAppSelector(
    (state) => state.onboardingTour,
  );
  const isOnboardingDialogComplete = localStorage.getItem(
    'isOnboardingDialogComplete',
  );

  const startOnboarding = () => {
    dispatch(
      updateOnboardingState({
        currentStep: HighlightedComponent.HeaderFilters,
        startTour: false,
        restartTour: false,
      }),
    );
  };

  const closeTour = () => {
    localStorage.setItem('isOnboardingDialogComplete', DashboardTour.COMPLETE);
    dispatch(
      updateOnboardingState({
        startTour: false,
        skipTour: true,
        currentStep: HighlightedComponent.None,
      }),
    );
  };

  useEffect(() => {
    if (isOnboardingDialogComplete === null && restartTour) {
      dispatch(updateOnboardingState({ startTour: true }));
    }
  }, [startTour, dispatch, isOnboardingDialogComplete, restartTour]);

  return (
    <Dialog maxWidth={false} keepMounted={true} open={startTour}>
      <div className={styles.container}>
        <div className={styles.info}>
          <Typography className={styles.title}>
            {translation.welcome}
          </Typography>
          <Typography className={styles.description}>
            {translation.description}
          </Typography>
        </div>
        <div className={styles.astronautSvg}>
          <AstronautSvg />
        </div>
      </div>
      <div className={styles.actions}>
        <Typography onClick={closeTour} className={styles.skipTitle}>
          {translation.skipOnboarding}
        </Typography>
        <Button onClick={startOnboarding} className={styles.onboardingButton}>
          {translation.startOnboarding}
        </Button>
      </div>
    </Dialog>
  );
};

export default OnboardingDialog;
