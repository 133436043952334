import React, { useCallback } from 'react';
import clsx from 'clsx';
import { AnalyticsEventType } from '@insightloop/common-ui';

import { updateFilters } from '../../../../store/slices/filters.slice';
import { TimeRange } from '../../../../types/common';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { useTranslations } from '../../../../store/slices/translation.slice';
import { sendAnalytics } from '../../../../utils/sendAnalytics';
import styles from './TimeRangeFilter.module.scss';

const TimeRangeFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedTimeRange = useAppSelector(
    (state) => state.filters.dashboard.timeRange,
  );
  const {
    filters: { time },
  } = useTranslations();

  // It will always be of type TimeRange, but the MUI typings are not flexible.
  const handleChange = useCallback(
    (timeRange: TimeRange) => {
      dispatch(updateFilters({ timeRange }));
      sendAnalytics({
        name: AnalyticsEventType.FILTER_TIME_RANGE,
        attributes: { timeRange },
      });
    },
    [dispatch],
  );

  return (
    <div className={styles.timeRanges}>
      <button
        className={clsx(
          styles.timeRange,
          selectedTimeRange === TimeRange.ONE_YEAR && styles.selected,
        )}
        data-testid="time-range-1-year"
        onClick={() => handleChange(TimeRange.ONE_YEAR)}
      >
        {time[TimeRange.ONE_YEAR]}
      </button>
      <button
        className={clsx(
          styles.timeRange,
          selectedTimeRange === TimeRange.SIX_MONTHS && styles.selected,
        )}
        data-testid="time-range-6-months"
        onClick={() => handleChange(TimeRange.SIX_MONTHS)}
      >
        {time[TimeRange.SIX_MONTHS]}
      </button>
      <button
        className={clsx(
          styles.timeRange,
          selectedTimeRange === TimeRange.ONE_MONTH && styles.selected,
        )}
        data-testid="time-range-1-month"
        onClick={() => handleChange(TimeRange.ONE_MONTH)}
      >
        {time[TimeRange.ONE_MONTH]}
      </button>
      <button
        className={clsx(
          styles.timeRange,
          selectedTimeRange === TimeRange.ONE_WEEK && styles.selected,
        )}
        data-testid="time-range-1-week"
        onClick={() => handleChange(TimeRange.ONE_WEEK)}
      >
        {time[TimeRange.ONE_WEEK]}
      </button>
    </div>
  );
};

export default TimeRangeFilter;
