import React from 'react';

import { isDefined } from '../../../utils/isDefined';
import { useTheme } from '@mui/material';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';
import { formatNumberByLocale } from '../../../utils/formatNumberByLocale';
import { useAppSelector } from '../../../store/utils/hooks';

interface Props {
  viewBox?: {
    cx: number;
    cy: number;
  };
  label: string;
  value: number | null;
}

const TrendingCustomersSummaryChartLabel: React.FC<Props> = ({
  viewBox,
  label,
  value,
}) => {
  const theme = useTheme();
  const isDarkMode = useDarkModeSelected();
  const locale = useAppSelector((state) => state.translation.locale);

  if (!isDefined(viewBox)) {
    return null;
  }
  const { cx, cy } = viewBox;

  return (
    <>
      <text textAnchor="middle">
        <tspan
          x={cx}
          y={cy - 5}
          style={{
            fontSize: '20px',
            fontWeight: 400,
            fill: theme.palette.primary.light,
          }}
        >
          {label}
        </tspan>
        <tspan
          x={cx}
          y={cy + 15}
          style={{
            fontSize: '20px',
            fontWeight: 700,
            fill: isDarkMode ? 'white' : 'black',
          }}
        >
          {value === null ? 'N/A' : formatNumberByLocale(locale, value)}
        </tspan>
      </text>
    </>
  );
};

export default TrendingCustomersSummaryChartLabel;
