import React from 'react';
import { WorkflowBottleneckCardFillColor } from './types';

const ProcessStatusBackground: React.FC<WorkflowBottleneckCardFillColor> = ({
  fillColor,
}) => {
  return (
    <svg
      width="328"
      height="121"
      viewBox="0 0 342 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8286_12615)">
        <path
          opacity="0.2"
          d="M298.776 106H15.9988L44.5759 60.5L15 15H298.776L327 60.5L298.776 106Z"
          fill={fillColor}
        />
        <path
          d="M24.3867 15H16L44.12 60.237L16 106H24.3867L53 60.237L24.3867 15Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8286_12615"
          x="0"
          y="0"
          width="342"
          height="121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8286_12615"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8286_12615"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_8286_12615">
          <rect
            width="33"
            height="22"
            fill="white"
            transform="translate(50 23)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProcessStatusBackground;
