import { MetricWithTrend } from '../types/common';
import api, { ApiResponse, FetchMetricsParams } from './api';

export interface MeanTimeToResolutionMetrics {
  date_interval: string;
  mean_time_to_res: string; // TODO: BE is sending string, but actually a number
}

export interface MeanTimeToResolutionData extends MetricWithTrend {
  mean_time_to_resolution: MeanTimeToResolutionMetrics[];
}

type MttrResponse = ApiResponse<MeanTimeToResolutionData>;

export const fetchMeanTimeToResolution = async ({
  country,
  equipmentType,
  timeRange,
  withTrend,
  customerId,
  signal,
  mock,
}: FetchMetricsParams): Promise<MeanTimeToResolutionData> => {
  const { data } = await api.get<MttrResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      equipmentType,
      timeRange,
      withTrend,
      customerId,
      country,
      type: 'MeanTimeToResolution',
    },
    config: { signal },
  });

  return data;
};
