import { TimeRange } from '../../types/common';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { GetSeedParams } from '../test-utils';
import { FailureRatesData } from '../../integration/failureRates.api';

const getFailureRatesSeed = ({
  timeRange,
  country,
  customer,
  equipmentType,
}: GetSeedParams): FailureRatesData | null => {
  if (equipmentType === null) {
    throw new Error(
      'Equipment type must be selected for the Failure Rates API call!',
    );
  }

  if (equipmentType === searchSeed.equipment_types[0]) {
    return {
      customers_impacted: '49',
      failure_rate: '0.1234567',
      in_the_field: '891',
      with_issues: '94',
      trend: 0.03456,
    };
  }

  if (country === countriesSeed.countries[1].code) {
    return {
      customers_impacted: '12',
      failure_rate: '0.48',
      in_the_field: '97',
      with_issues: '51',
      trend: -0.71,
    };
  }

  if (customer === searchSeed.customers[0].id) {
    return {
      customers_impacted: '1',
      failure_rate: '0.1274',
      in_the_field: '42',
      with_issues: '5',
      trend: 0.4326,
    };
  }

  if (timeRange === TimeRange.SIX_MONTHS) {
    return {
      customers_impacted: '20',
      failure_rate: '0.27112',
      in_the_field: '199',
      with_issues: '51',
      trend: -0.02112,
    };
  }

  if (timeRange === TimeRange.ONE_MONTH) {
    return {
      customers_impacted: '15',
      failure_rate: '0.21',
      in_the_field: '178',
      with_issues: '34',
      trend: 0.33,
    };
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return null;
  }

  return {
    customers_impacted: '18',
    failure_rate: '0.34',
    in_the_field: '240',
    with_issues: '76',
    trend: 0.043,
  };
};

export default getFailureRatesSeed;
