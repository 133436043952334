import React from 'react';
import { TooltipProps } from 'recharts';
import { Paper, Typography } from '@mui/material';
import { useTranslations } from '../../../store/slices/translation.slice';
import { formatNumberByLocale } from '../../../utils/formatNumberByLocale';
import { useAppSelector } from '../../../store/utils/hooks';

const JobTypesChartTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  const { trendingParts: translations } = useTranslations();
  const locale = useAppSelector((state) => state.translation.locale);
  if (active && payload && payload.length) {
    const label = payload[0].payload.name;
    const values = payload.map((dataPoint) => dataPoint.value || 0);
    const total = values.reduce((acc, value) => acc + value, 0);
    const maintenance = values[0];
    const failure = values[1];
    const other = values[2];

    return (
      <Paper
        elevation={2}
        sx={{
          padding: '8px',
          maxWidth: '360px',
          overflow: 'hidden',
        }}
      >
        <Typography variant="subtitle2">
          <b>{label}</b>
          <br />
          {maintenance !== 0 && (
            <>
              <b>{translations.maintenance}</b>:{' '}
              {formatNumberByLocale(locale, maintenance)}
              <br />
            </>
          )}
          {failure !== 0 && (
            <>
              <b>{translations.failure}</b>:{' '}
              {formatNumberByLocale(locale, failure)}
              <br />
            </>
          )}
          {other !== 0 && (
            <>
              <b>{translations.other}</b>: {formatNumberByLocale(locale, other)}
              <br />
            </>
          )}
          <b>{translations.total}</b>: {formatNumberByLocale(locale, total)}
        </Typography>
      </Paper>
    );
  }

  return null;
};

export default JobTypesChartTooltip;
