import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import { Country, fetchCountries } from '../../integration/countries.api';

export const COUNTRIES_KEY = 'countries';

export interface CountriesState {
  status: FetchingStatus;
  error: string | null;
  countries: Country[];
}
const initialState: CountriesState = {
  status: FetchingStatus.IDLE,
  error: null,
  countries: [],
};

export const getCountries = createAsyncThunk<Country[], void, AppThunkConfig>(
  'getCountries',
  async (_, { getState, signal }) => {
    const {
      filters: {
        dashboard: { customer, equipmentType },
      },
      mockServer,
    } = getState();

    return fetchCountries({
      equipmentType,
      signal,
      customerId: customer?.id,
      mock: mockServer.active,
    });
  },
);

const countriesSlice = createSlice({
  name: COUNTRIES_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCountries.pending, (state) => {
        state.status = FetchingStatus.PENDING;
        state.countries = [];
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.status = FetchingStatus.ERROR;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = countriesSlice;

export const countriesReducer = reducer;
