import { MetricWithTrend } from '../types/common';
import api, { ApiResponse, FetchMetricsParams } from './api';

export interface FirstTimeFixedRateMetric {
  date_interval: string;
  rate: string; // TODO: BE is sending string, but actually a number
}

export interface FirstTimeFixedRateData extends MetricWithTrend {
  first_time_fix_rate: FirstTimeFixedRateMetric[];
}

type FtfrResponse = ApiResponse<FirstTimeFixedRateData>;

export const fetchFirstTimeFixedRate = async ({
  country,
  equipmentType,
  timeRange,
  withTrend,
  customerId,
  signal,
  mock,
}: FetchMetricsParams): Promise<FirstTimeFixedRateData> => {
  const { data } = await api.get<FtfrResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      equipmentType,
      timeRange,
      withTrend,
      customerId,
      country,
      type: 'FirstTimeFixRate',
    },
    config: { signal },
  });

  return data;
};
