import { JobTypeCounts } from '../../../../types/charts';

interface TrendingPartsData {
  part_id: string;
  name: string;
  failure: number;
  maintenance: number;
  other: number;
  history: {
    last_year: number;
    this_year: number;
    last_year_same_month: number;
  };
}

interface SortCriteria {
  (jobType: JobTypeCounts): number;
}

export const sortByDesc = (
  dataSet: TrendingPartsData[],
  criteria: SortCriteria,
) => {
  return dataSet.sort((a, b) => criteria(b) - criteria(a));
};
