import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus, FetchKpiParams } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchMeanTimeBetweenVisits,
  MeanTimeBetweenVisitsData,
} from '../../integration/mtbv.api';

export const MEAN_TIME_BETWEEN_VISITS_KEY = 'meanTimeBetweenVisits';

export interface MeanTimeBetweenVisitsState {
  status: FetchingStatus;
  error: string | null;
  metrics: MeanTimeBetweenVisitsData | null;
}
const initialState: MeanTimeBetweenVisitsState = {
  status: FetchingStatus.IDLE,
  error: null,
  metrics: null,
};

export const getMeanTimeBetweenVisits = createAsyncThunk<
  MeanTimeBetweenVisitsData,
  FetchKpiParams,
  AppThunkConfig
>(
  'getMeanTimeBetweenVisits',
  async ({ withTrend = false }, { getState, signal }) => {
    const {
      filters: {
        dashboard: { country, customer, equipmentType, timeRange },
      },
      mockServer,
    } = getState();

    return fetchMeanTimeBetweenVisits({
      country,
      equipmentType,
      timeRange,
      withTrend,
      signal,
      customerId: customer?.id,
      mock: mockServer.active,
    });
  },
);

const meanTimeBetweenVisitsSlice = createSlice({
  name: MEAN_TIME_BETWEEN_VISITS_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMeanTimeBetweenVisits.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getMeanTimeBetweenVisits.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.metrics = action.payload;
      })
      .addCase(getMeanTimeBetweenVisits.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.metrics = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = meanTimeBetweenVisitsSlice;

export const meanTimeBetweenVisitsReducer = reducer;
