import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { AnalyticsEventType, NULL_SELECTION } from '@insightloop/common-ui';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import {
  clearEquipmentSearch,
  getEquipmentTypes,
} from '../../../../store/slices/search.slice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  clearEquipmentTypeFilter,
  updateFilters,
} from '../../../../store/slices/filters.slice';
import { ApiCall, FetchingStatus } from '../../../../types/common';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { useTranslations } from '../../../../store/slices/translation.slice';
import EquipmentSvg from '../icons/EquipmentSvg';
import { sendAnalytics } from '../../../../utils/sendAnalytics';
import styles from './Filters.module.scss';

const isTestEnv = process.env.NODE_ENV === 'test';

const EquipmentTypeFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    equipment: { status, results: equipmentTypes },
  } = useAppSelector((state) => state.search);
  const { equipmentType, shouldClearEquipmentTypeInput } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const {
    filters: { equipmentTypeFilter: placeholder },
    loading: loadingText,
    noOptions: noOptionsText,
  } = useTranslations();
  const loading = status === FetchingStatus.PENDING;
  const equipmentTypeSelected = equipmentType !== null;

  const [searchListFetchPromise, setSearchListFetchPromise] =
    useState<ApiCall<string[], string>>();
  const getOptions = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (searchListFetchPromise) {
        searchListFetchPromise.abort();
      }
      if (value.length >= 2) {
        const promise = dispatch(getEquipmentTypes(value));
        setSearchListFetchPromise(promise);
      }
    }, 300),
    [dispatch, searchListFetchPromise],
  );

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // Clicking away triggers reset - we do not want to do anything here
      if (reason === 'reset') {
        return;
      }
      if (!value) {
        setOpen(false);
      }
      setInputValue(value);
      getOptions(value);
      dispatch(clearEquipmentTypeFilter({ shouldClearInput: false }));
    },
    [dispatch, getOptions],
  );

  const handleEquipmentChange = (
    _: SyntheticEvent,
    equipmentType: string | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (equipmentType && reason === 'selectOption') {
      dispatch(updateFilters({ equipmentType }));
      sendAnalytics({
        name: AnalyticsEventType.FILTER_EQUIPMENT_TYPE,
        attributes: { equipmentType },
      });
    } else if (reason === 'clear') {
      dispatch(clearEquipmentTypeFilter({ shouldClearInput: true }));
      sendAnalytics({
        name: AnalyticsEventType.FILTER_EQUIPMENT_TYPE,
        attributes: { equipmentType: NULL_SELECTION },
      });
    }
  };

  // Binding the value to Autocomplete does not refresh the input field content, we need to do it like this
  useEffect(() => {
    if (equipmentType === null && shouldClearEquipmentTypeInput) {
      setInputValue('');
      dispatch(clearEquipmentSearch());
    } else if (equipmentType !== null) {
      setInputValue(equipmentType);
    }
  }, [equipmentType, shouldClearEquipmentTypeInput]);

  const noOptionStatus =
    status === FetchingStatus.SUCCESS && equipmentTypes.length === 0;

  return (
    <Autocomplete
      data-testid="equipment-type-filter"
      autoHighlight={!isTestEnv}
      open={open}
      onOpen={() => {
        if (inputValue) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      popupIcon={<KeyboardArrowDownIcon />}
      value={equipmentType}
      onChange={handleEquipmentChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      loadingText={loadingText}
      noOptionsText={noOptionStatus && noOptionsText}
      className={clsx(
        styles.appAutocomplete,
        equipmentTypeSelected && styles.optionSelected,
      )}
      size="small"
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option || ''}
      options={equipmentTypes}
      loading={loading}
      filterOptions={(x) => x}
      renderOption={(props, equipmentType) => (
        <Box
          component="li"
          sx={{ color: (theme) => theme.palette.secondary.light }}
          {...props}
          key={equipmentType}
        >
          <Typography className={styles.list}>{equipmentType}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          size="small"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <EquipmentSvg isSelected={equipmentTypeSelected} />
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default EquipmentTypeFilter;
