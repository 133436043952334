import React from 'react';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';

const MttrIcon: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const fillColor = isDarkMode ? '#ffffff' : '#000000';
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 3.125C7.32422 3.125 3.125 7.32422 3.125 12.5C3.125 17.6758 7.32422 21.875 12.5 21.875C17.6758 21.875 21.875 17.6758 21.875 12.5C21.875 7.32422 17.6758 3.125 12.5 3.125Z"
        stroke={fillColor}
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5 6.25V13.2812H17.1875"
        stroke={fillColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MttrIcon;
