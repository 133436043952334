import { isValidNumber } from '../../../../utils/isValidNumber';

export const toPercentage = (value: number | null, digits: number): string => {
  if (!isValidNumber(value)) {
    return 'N/A';
  }
  const percentage = (Math.abs(value) * 100).toFixed(digits);

  return `${percentage}%`;
};
