import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import { useAppSelector } from '../utils/hooks';
import { resetFilters } from './filters.slice';
import { resetParts } from './trendingParts.slice';

export const MOCK_SERVER_KEY = 'mockServer';

export interface MockServerState {
  active: boolean;
  status: FetchingStatus;
}

const initialState: MockServerState = {
  active: false,
  status: FetchingStatus.IDLE,
};

export const toggleMockServer = createAsyncThunk<boolean, void, AppThunkConfig>(
  'toggleMockServer',
  async (_, { getState, dispatch }) => {
    const {
      mockServer: { active },
    } = getState();

    batch(() => {
      dispatch(resetFilters());
      dispatch(resetParts());
    });

    return !active;
  },
);

const mockServerSlice = createSlice({
  initialState,
  name: MOCK_SERVER_KEY,
  reducers: {
    setMocking: (state, action: PayloadAction<boolean>) => ({
      ...state,
      active: action.payload,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(toggleMockServer.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(toggleMockServer.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.active = action.payload;
      })
      .addCase(toggleMockServer.rejected, (state) => {
        state.status = FetchingStatus.ERROR;
      });
  },
});

export const useMockServerActive = (): boolean => {
  return useAppSelector((state) => state.mockServer.active);
};

const { actions, reducer } = mockServerSlice;

export const { setMocking } = actions;

export const mockServerReducer = reducer;
