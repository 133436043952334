import React, { useCallback } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { updateFilters } from '../../../../store/slices/filters.slice';
import { TimeRange } from '../../../../types/common';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { useTranslations } from '../../../../store/slices/translation.slice';
import { KeyboardArrowDown } from '@mui/icons-material';
import styles from './TimeRangeDropdown.module.scss';

const TimeRangeDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const timeRange = useAppSelector(
    (state) => state.filters.dashboard.timeRange,
  );
  const {
    filters: { time },
  } = useTranslations();

  const handleChange = useCallback(
    (event: SelectChangeEvent<TimeRange>) => {
      dispatch(updateFilters({ timeRange: event.target.value as TimeRange }));
    },
    [dispatch],
  );

  return (
    <FormControl className={styles.container} size="small">
      <Select
        onChange={handleChange}
        IconComponent={KeyboardArrowDown}
        value={timeRange}
        className={styles.dropdown}
      >
        <MenuItem value={TimeRange.ONE_YEAR}>
          {time[TimeRange.ONE_YEAR]}
        </MenuItem>
        <MenuItem value={TimeRange.SIX_MONTHS}>
          {time[TimeRange.SIX_MONTHS]}
        </MenuItem>
        <MenuItem value={TimeRange.ONE_MONTH}>
          {time[TimeRange.ONE_MONTH]}
        </MenuItem>
        <MenuItem value={TimeRange.ONE_WEEK}>
          {time[TimeRange.ONE_WEEK]}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default TimeRangeDropdown;
