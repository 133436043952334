import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import TrendingEquipmentAndSymptoms from '../../components/trendingEquipmentAndSymptoms/TrendingEquipmentAndSymptoms';
import TrendingParts from '../../components/trendingParts/TrendingParts';
import TrendingCustomers from '../../components/trendingCustomers/TrendingCustomers';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import OpenJobsTable from '../../components/openJobsTable/OpenJobsTable';
import Feature from '../../components/feature/Feature';
import KpisGlanceAndProcessStatus from '../../components/kpisGlanceAndProcessStatus/KpisGlanceAndProcessStatus';
import useMeasureTimeAnalytics from '../../hooks/UseMeasureTimeAnalytics';
import OnboardingDialog from '../../components/dashboardTour/OnboardingDialog/OnboardingDialog';
import Tour from '../../components/dashboardTour/Tour/Tour';
import {
  HighlightedComponent,
  updateOnboardingState,
} from '../../store/slices/onboardingTour.slice';
import { useDarkModeSelected } from '../../store/slices/theme.slice';
import { DashboardTour } from '../../types/common';
import FailureRatesAndDocumentManagement from '../../components/failureRatesAndDocumentManagement/FailureRatesAndDocumentManagement';
import AssistantLink from '../../components/assistantLink/AssistantLink';
import styles from '../dashboard/Dashboard.module.scss';

const Dashboard: React.FC = () => {
  useMeasureTimeAnalytics();
  const dispatch = useAppDispatch();
  const isDarkMode = useDarkModeSelected();
  const { currentStep, startTour, restartTour } = useAppSelector(
    (state) => state.onboardingTour,
  );
  const { customer, equipmentType, country } = useAppSelector(
    (state) => state.filters.dashboard,
  );

  const isKpisGlanceAndProcessStatusHighlighted =
    currentStep === HighlightedComponent.KpisGlanceAndProcessStatus;

  const isTrendingEquipmentAndSymptomsHighlighted =
    currentStep === HighlightedComponent.TrendingEquipmentAndSymptoms;

  const customerFiltered = customer !== null;
  const equipmentFiltered = equipmentType !== null;
  const countryFiltered = country !== null;
  const anyFilterSelected =
    customerFiltered || equipmentFiltered || countryFiltered;

  const refScrollToView = useRef<HTMLDivElement>(null);
  const refScrollToTop = useRef<HTMLDivElement>(null);
  const refKpisGlanceAndProcessStatus = useRef<HTMLDivElement>(null);
  const refTrendingEquipmentAndSymptoms = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refScrollToTop.current && customer) {
      window.scrollTo({
        top: refScrollToTop.current.offsetTop - 10,
        behavior: 'smooth',
      });
    }
    if (customer === null) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [customer]);

  useEffect(() => {
    if (refScrollToView.current && equipmentType) {
      refScrollToView.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (equipmentType === null) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [equipmentType]);

  useEffect(() => {
    if (
      refKpisGlanceAndProcessStatus.current &&
      isKpisGlanceAndProcessStatusHighlighted
    ) {
      refKpisGlanceAndProcessStatus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (
      refTrendingEquipmentAndSymptoms.current &&
      isTrendingEquipmentAndSymptomsHighlighted
    ) {
      refTrendingEquipmentAndSymptoms.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [
    isKpisGlanceAndProcessStatusHighlighted,
    isTrendingEquipmentAndSymptomsHighlighted,
  ]);

  useEffect(() => {
    if (currentStep === HighlightedComponent.None) {
      localStorage.setItem(
        'isOnboardingDialogComplete',
        DashboardTour.COMPLETE,
      );
    }
  }, [currentStep]);

  const isOnboardingDialogComplete = useMemo(() => {
    return localStorage.getItem('isOnboardingDialogComplete');
  }, [restartTour]);

  return (
    <>
      <div className={styles.container}>
        <Feature
          ref={refKpisGlanceAndProcessStatus}
          className={clsx(
            styles.feature,
            isKpisGlanceAndProcessStatusHighlighted && styles.highlighted,
            isDarkMode &&
              isKpisGlanceAndProcessStatusHighlighted &&
              styles.darkModeHighlighted,
          )}
        >
          <KpisGlanceAndProcessStatus />
          {isKpisGlanceAndProcessStatusHighlighted && (
            <Tour
              style={{ transform: 'translate(124px, 10px)' }}
              shouldStartTour={startTour}
              nextStep={() =>
                dispatch(
                  updateOnboardingState({
                    currentStep:
                      HighlightedComponent.TrendingEquipmentAndSymptoms,
                  }),
                )
              }
            />
          )}
        </Feature>
        <Feature
          ref={refTrendingEquipmentAndSymptoms}
          className={clsx(
            styles.feature,
            isTrendingEquipmentAndSymptomsHighlighted && styles.highlighted,
            isDarkMode &&
              isTrendingEquipmentAndSymptomsHighlighted &&
              styles.darkModeHighlighted,
          )}
        >
          <TrendingEquipmentAndSymptoms />
          {isTrendingEquipmentAndSymptomsHighlighted && (
            <Tour
              style={{ transform: 'translate(124px,10px)' }}
              shouldStartTour={startTour}
              nextStep={() =>
                dispatch(
                  updateOnboardingState({
                    currentStep: HighlightedComponent.ContactSupport,
                  }),
                )
              }
            />
          )}
        </Feature>
        <div
          className={clsx(
            styles.transitionEffect,
            !equipmentFiltered ? styles.hide : styles.show,
          )}
        >
          {equipmentFiltered && (
            <Feature ref={refScrollToView} className={clsx(styles.feature)}>
              <FailureRatesAndDocumentManagement />
            </Feature>
          )}
        </div>

        <Feature className={styles.feature}>
          <TrendingCustomers />
        </Feature>
        <div
          className={clsx(
            styles.transitionEffect,
            !customerFiltered ? styles.hide : styles.show,
          )}
        >
          {customerFiltered && (
            <Feature ref={refScrollToTop} className={styles.feature}>
              <OpenJobsTable />
            </Feature>
          )}
        </div>
        {!anyFilterSelected && (
          <Feature className={styles.feature}>
            <TrendingParts />
          </Feature>
        )}
        <AssistantLink />
      </div>
      {!isOnboardingDialogComplete && <OnboardingDialog />}
    </>
  );
};

export default Dashboard;
