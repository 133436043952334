import React from 'react';
import { TooltipProps } from 'recharts';
import { Paper, Typography } from '@mui/material';
import { formatNumberByLocale } from '../../../utils/formatNumberByLocale';
import { useAppSelector } from '../../../store/utils/hooks';

interface Props extends TooltipProps<number, string> {
  unitOfMeasurement?: string;
}

const AppChartTooltip: React.FC<Props> = ({
  active,
  payload,
  unitOfMeasurement = '',
}) => {
  const locale = useAppSelector((state) => state.translation.locale);

  if (active && payload && payload.length) {
    const label = payload[0].payload.name;
    const value = payload[0].value;

    return (
      <Paper
        elevation={2}
        sx={{
          padding: '8px',
          maxWidth: '360px',
          overflow: 'hidden',
        }}
      >
        <Typography variant="subtitle2">
          <b>{label}</b>: {formatNumberByLocale(locale, Number(value))}
          {unitOfMeasurement}
        </Typography>
      </Paper>
    );
  }

  return null;
};

export default AppChartTooltip;
