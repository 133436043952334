import React, { useEffect } from 'react';

import styles from './ProcessStatus.module.scss';
import { getStepByName } from './utils/getStepByName';
import WorkflowBottleneckCard from './card/WorkflowBottleneckCard';
import FieldWorkIcon from './icons/FieldWork';
import JobGenerationIcon from './icons/JobGeneration';
import ClosureIcon from './icons/Closure';
import SchedulingIcon from './icons/Scheduling';
import { useMockServerActive } from '../../../store/slices/mockServer.slice';
import { useTranslations } from '../../../store/slices/translation.slice';
import { getWorkflowBottleneck } from '../../../store/slices/workflowBottleneck.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import JobGenerationBackground from './icons/JobGenerationBackground';
import ProcessStatusBackground from './icons/ProcessStatusBackground';
import { WorkflowBottleneckStep } from '../../../integration/workflowBottleneck.api';
import { Trend, getTrend, trendColors } from './utils/getTrend';
import clsx from 'clsx';

const ProcessStatus: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();
  const { data } = useAppSelector((state) => state.workflowBottleneck);
  const { timeRange, country, customer, equipmentType } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const { workflowBottleneck: translations } = useTranslations();

  useEffect(() => {
    const promise = dispatch(getWorkflowBottleneck());

    return () => {
      promise.abort();
    };
  }, [isMockServerActive, dispatch, timeRange]);

  const jobGeneration = getStepByName('jobGeneration', data);
  const schedulingAndDispatching = getStepByName('scheduling', data);
  const fieldWork = getStepByName('fieldWork', data);
  const closureAndInvoicing = getStepByName('closing', data);

  const getDurationTrendStyle = (kpiData: WorkflowBottleneckStep | null) => {
    if (kpiData === null) {
      return trendColors[Trend.Neutral];
    }
    if (getTrend(kpiData.durations.trend) === Trend.Neutral) {
      return trendColors[Trend.Neutral];
    }

    if (getTrend(kpiData.durations.trend) === Trend.Positive) {
      return trendColors[Trend.Positive];
    } else if (getTrend(kpiData.durations.trend) === Trend.Negative) {
      return trendColors[Trend.Negative];
    }
  };

  const isFilterSelected =
    country !== null || customer !== null || equipmentType !== null;
  return (
    <div
      className={clsx(styles.cards, isFilterSelected && styles.filterSelected)}
    >
      <WorkflowBottleneckCard
        title={translations.kpiTitle.jobGeneration}
        icon={<JobGenerationIcon />}
        testId={'job-generation'}
        kpiData={jobGeneration}
        background={
          <JobGenerationBackground
            fillColor={getDurationTrendStyle(jobGeneration)}
          />
        }
        tooltipContent={
          translations.tooltipDescription.kpiDetails[0].description
        }
      />
      <WorkflowBottleneckCard
        title={translations.kpiTitle.scheduling}
        icon={<SchedulingIcon />}
        testId={'scheduling'}
        kpiData={schedulingAndDispatching}
        background={
          <ProcessStatusBackground
            fillColor={getDurationTrendStyle(schedulingAndDispatching)}
          />
        }
        tooltipContent={
          translations.tooltipDescription.kpiDetails[1].description
        }
      />
      <WorkflowBottleneckCard
        title={translations.kpiTitle.fieldWork}
        icon={<FieldWorkIcon />}
        testId={'field-work'}
        kpiData={fieldWork}
        background={
          <ProcessStatusBackground
            fillColor={getDurationTrendStyle(fieldWork)}
          />
        }
        tooltipContent={
          translations.tooltipDescription.kpiDetails[2].description
        }
      />
      <WorkflowBottleneckCard
        title={translations.kpiTitle.closing}
        icon={<ClosureIcon />}
        testId={'closing'}
        kpiData={closureAndInvoicing}
        background={
          <ProcessStatusBackground
            fillColor={getDurationTrendStyle(closureAndInvoicing)}
          />
        }
        tooltipContent={
          translations.tooltipDescription.kpiDetails[3].description
        }
      />
    </div>
  );
};

export default ProcessStatus;
