import React, { ReactNode, useMemo } from 'react';
import { Skeleton, Tooltip, useTheme } from '@mui/material';

import styles from './WorkflowBottleneckCard.module.scss';
import clsx from 'clsx';
import { WorkflowBottleneckStep } from '../../../../integration/workflowBottleneck.api';
import { useTranslations } from '../../../../store/slices/translation.slice';
import { convertSecondsToDays } from '../../../../utils/convertSecondsToDays';
import { calculateAverageDuration } from '../utils/calculateAverageDuration';
import { formatByScoreTypes } from '../utils/formatByScoreTypes';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';
import { useAppSelector } from '../../../../store/utils/hooks';
import { FetchingStatus } from '../../../../types/common';
import TrendValue from './TrendValue';
import DurationTrend from './DurationTrend';
import { ScoreTypes } from '../types/types';
import { isValidNumber } from '../../../../utils/isValidNumber';
import { isDefined } from '../../../../utils/isDefined';
import { getWorkflowKpiByTitle } from '../../utils/getWorkflowKpiByTitle';

interface Props {
  title: string;
  icon: ReactNode;
  testId: string;
  kpiData: WorkflowBottleneckStep | null;
  background: ReactNode;
  tooltipContent: string;
}

const WorkflowBottleneckCard: React.FC<Props> = ({
  title,
  icon,
  testId,
  kpiData,
  background,
  tooltipContent,
}) => {
  const theme = useTheme();
  const isDarkMode = useDarkModeSelected();
  const { timeSpan, durationInDays } = useTranslations();
  const { timeRange } = useAppSelector((state) => state.filters.dashboard);
  const { status } = useAppSelector((state) => state.workflowBottleneck);
  const locale = useAppSelector((state) => state.translation.locale);
  const translations = useTranslations();

  const averageDuration = useMemo(() => {
    if (kpiData === null) {
      return null;
    }
    return calculateAverageDuration(kpiData.durations.values);
  }, [kpiData]);

  const duration = isValidNumber(averageDuration)
    ? durationInDays(convertSecondsToDays(averageDuration, 1))
    : 'N/A';

  if (kpiData === null) {
    return null;
  }

  const isLoading = status === FetchingStatus.PENDING;

  const isFirstCard = testId === 'job-generation';

  return (
    <div className={styles.container}>
      <Tooltip
        classes={{ tooltip: styles.workflowBottleneckTooltip }}
        title={tooltipContent}
        placement="top"
        arrow={true}
      >
        <div
          className={clsx(styles.card, isFirstCard && styles.firstCard)}
          data-testid={`workflow-bottleneck-card-${testId}`}
        >
          {background}
          <div>{icon}</div>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
            <div>
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={58}
                  height={20}
                />
              ) : (
                <span className={styles.duration}>{duration}</span>
              )}
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={120}
                  height={20}
                />
              ) : (
                <div className={styles.description}>
                  <span className={styles.subtitle}>
                    <DurationTrend kpiData={kpiData} theme={theme} />
                  </span>
                  <span className={styles.timeSpan}>{timeSpan[timeRange]}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Tooltip>
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="text"
          width={294}
          height={20}
          sx={{ margin: '8px 8px 0 0' }}
        />
      ) : (
        <div className={styles.kpis}>
          {kpiData.scores.map((data, index) => (
            <Tooltip
              classes={{ tooltip: styles.workflowBottleneckTooltip }}
              key={index}
              title={
                getWorkflowKpiByTitle(
                  data.name,
                  translations.workflowBottleneck.kpis,
                ).tooltip
              }
              placement="top"
              arrow={true}
            >
              <div
                className={clsx(
                  styles.kpiValues,
                  isDarkMode && styles.kpiValuesDarkMode,
                )}
                key={index}
              >
                <span className={styles.name}>
                  {
                    getWorkflowKpiByTitle(
                      data.name,
                      translations.workflowBottleneck.kpis,
                    ).title
                  }
                </span>
                <span className={styles.kpiValue}>
                  {isDefined(data.value) ? (
                    formatByScoreTypes(
                      locale,
                      data.value,
                      data.score_type as ScoreTypes,
                      translations,
                    )
                  ) : (
                    <span className={styles.nullValue}>--</span>
                  )}
                </span>
                <TrendValue data={data} styles={styles} />
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkflowBottleneckCard;
