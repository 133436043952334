import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import { AnalyticsEventType, NULL_SELECTION } from '@insightloop/common-ui';

import {
  getCustomers,
  clearCustomerSearch,
} from '../../../../store/slices/search.slice';
import {
  clearCustomerFilter,
  updateFilters,
} from '../../../../store/slices/filters.slice';
import { ApiCall, FetchingStatus } from '../../../../types/common';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { useTranslations } from '../../../../store/slices/translation.slice';
import BuildIconSvg from '../icons/BuildIconSvg';
import { Customer } from '../../../../integration/customer.api';
import { sendAnalytics } from '../../../../utils/sendAnalytics';
import styles from './Filters.module.scss';

const isTestEnv = process.env.NODE_ENV === 'test';

const CustomerFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    customers: { status, results: customers },
  } = useAppSelector((state) => state.search);
  const {
    filters: { customerFilter: placeholder },
    loading: loadingText,
    noOptions: noOptionsText,
  } = useTranslations();
  const { customer, shouldClearCustomerInput } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const loading = status === FetchingStatus.PENDING;
  const customerSelected = customer !== null;

  const [searchListFetchPromise, setSearchListFetchPromise] =
    useState<ApiCall<Customer[], string>>();
  const getOptions = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (searchListFetchPromise) {
        searchListFetchPromise.abort();
      }
      if (value.length >= 2) {
        const promise = dispatch(getCustomers(value));
        setSearchListFetchPromise(promise);
      }
    }, 300),
    [dispatch, searchListFetchPromise],
  );

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // Clicking away triggers reset - we do not want to do anything here
      if (reason === 'reset') {
        return;
      }
      if (!value) {
        setOpen(false);
      }
      setInputValue(value);
      getOptions(value);
      dispatch(clearCustomerFilter({ shouldClearInput: false }));
    },
    [dispatch, getOptions],
  );

  const handleCustomerChange = (
    _: SyntheticEvent,
    customer: Customer | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (customer && reason === 'selectOption') {
      dispatch(updateFilters({ customer }));
      sendAnalytics({
        name: AnalyticsEventType.FILTER_CUSTOMER,
        attributes: { customer: customer.id },
      });
    } else if (reason === 'clear') {
      dispatch(clearCustomerFilter({ shouldClearInput: true }));
      sendAnalytics({
        name: AnalyticsEventType.FILTER_CUSTOMER,
        attributes: { customer: NULL_SELECTION },
      });
    }
  };

  // Binding the value to Autocomplete does not refresh the input field content, we need to do it like this
  useEffect(() => {
    if (customer === null && shouldClearCustomerInput) {
      setInputValue('');
      dispatch(clearCustomerSearch());
    } else if (customer !== null) {
      setInputValue(customer.name);
    }
  }, [customer, shouldClearCustomerInput]);

  const noOptionStatus =
    status === FetchingStatus.SUCCESS && customers.length === 0;

  return (
    <Autocomplete
      data-testid="customer-filter"
      autoHighlight={!isTestEnv}
      open={open}
      onOpen={() => {
        if (inputValue) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      popupIcon={<KeyboardArrowDownIcon />}
      value={customer}
      onChange={handleCustomerChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      loadingText={loadingText}
      noOptionsText={noOptionStatus && noOptionsText}
      className={clsx(
        styles.appAutocomplete,
        customerSelected && styles.optionSelected,
      )}
      size="small"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={customers}
      loading={loading}
      filterOptions={(x) => x}
      renderOption={(props, customer) => (
        <Box
          component="span"
          {...props}
          sx={{ color: (theme) => theme.palette.secondary.light }}
          key={customer.id}
        >
          <Typography className={styles.list}>
            {customer.name}
            {customer.city && (
              <>
                <br />
                <span className={styles.city}>{customer.city}</span>
              </>
            )}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          size="small"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <BuildIconSvg isSelected={customerSelected} />
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomerFilter;
