export enum Trend {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export const getTrend = (num: number | null): Trend => {
  if (num === null || num === 0) {
    return Trend.Neutral;
  }
  return num < 0 ? Trend.Negative : Trend.Positive;
};

export const trendColors: Record<Trend, string> = {
  [Trend.Positive]: '#FF5722',
  [Trend.Negative]: '#5FD855',
  [Trend.Neutral]: '#C7C5BD',
};

export const fillColor: Record<Trend, string> = {
  [Trend.Positive]: '#FF572230',
  [Trend.Negative]: '#5FD85530',
  [Trend.Neutral]: '#C7C5BD30',
};
