import { isDefined } from './isDefined';

const CHART_LABEL_PADDING_PX = 28;

export const DEFAULT_LABEL_MAX_WIDTH_PX = 172;
export const DEFAULT_LABEL_MAX_LINES = 2;

// TODO: add ruler text style config if a use-case for differently styled text lengths arises
const measureTextLength = (text: string): number => {
  const isTestEnv = process.env.NODE_ENV === 'test';
  if (isTestEnv) {
    return DEFAULT_LABEL_MAX_WIDTH_PX;
  }

  const ruler = document.getElementById('smart-loop-ruler');
  if (!isDefined(ruler)) {
    throw new Error('Ruler element not found in DOM!');
  }
  ruler.textContent = text;

  return ruler.getBoundingClientRect().width;
};

export const truncateLabel =
  (maxWidthPx: number) =>
  (label: string): string => {
    if (measureTextLength(label) <= maxWidthPx) {
      return label;
    }

    let result = label;
    while (measureTextLength(result) / 2 >= maxWidthPx) {
      result = result.slice(0, result.length / 2);
    }

    let charactersSliced = -3;
    while (
      measureTextLength(result.slice(0, charactersSliced) + '...') > maxWidthPx
    ) {
      charactersSliced--;
    }

    return `${result.slice(0, charactersSliced)}...`;
  };

export const truncateChartLabel = (label: string): string => {
  // When the label contains no spaces, we want to truncate it to one line only,
  // because the chart library will not break it up into multiple lines, so it will overflow.
  if (label.match(/^\S+$/)) {
    return truncateLabel(DEFAULT_LABEL_MAX_WIDTH_PX)(label);
  }
  return truncateLabel(DEFAULT_LABEL_MAX_WIDTH_PX * DEFAULT_LABEL_MAX_LINES)(
    label,
  );
};

export const getLongestLabelWidthPx = (
  labels: string[],
  maxWidthPx = DEFAULT_LABEL_MAX_WIDTH_PX,
): number => {
  return (
    Math.max(
      ...labels
        .map(measureTextLength)
        .map((textWidth) => (textWidth > maxWidthPx ? maxWidthPx : textWidth)),
    ) + CHART_LABEL_PADDING_PX
  );
};
