import { useEffect, useMemo, useState } from 'react';
import { AnalyticsEventType } from '@insightloop/common-ui';

import { sendAnalytics } from '../utils/sendAnalytics';

const useMeasureTimeAnalytics = (): void => {
  const initialTime = useMemo(() => {
    return Date.now();
  }, []);
  // TODO: broken
  const chatbotOpen = false;
  const [timeOnDashboard, setTimeOnDashboard] = useState(0);
  const [timeOnChatbot, setTimeOnChatbot] = useState(0);

  useEffect(() => {
    const currentTime = Date.now();
    if (chatbotOpen) {
      setTimeOnDashboard(currentTime - initialTime - timeOnChatbot);
    }
  }, [initialTime, chatbotOpen, timeOnChatbot]);

  useEffect(() => {
    // Skipping initial effect run
    if (!chatbotOpen && timeOnDashboard === 0) {
      return;
    }

    const currentTime = Date.now();
    if (!chatbotOpen) {
      setTimeOnChatbot(currentTime - initialTime - timeOnDashboard);
    }
  }, [initialTime, chatbotOpen, timeOnDashboard]);

  useEffect(() => {
    const handleUnload = () => {
      const currentTime = Date.now();
      const totalTimeOnDashboard = chatbotOpen
        ? timeOnDashboard
        : currentTime - initialTime - timeOnChatbot;
      const totalTimeOnChatbot = chatbotOpen
        ? currentTime - initialTime - timeOnDashboard
        : timeOnChatbot;
      sendAnalytics({
        name: AnalyticsEventType.TIME_SPENT,
        attributes: {
          secondsOnChatbot: String(Math.round(totalTimeOnChatbot / 1000)),
          secondsOnDashboard: String(Math.round(totalTimeOnDashboard / 1000)),
        },
      });
    };
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [initialTime, timeOnDashboard, timeOnChatbot, chatbotOpen]);
};

export default useMeasureTimeAnalytics;
