import api, { ApiResponse, FetchMetricsParams } from './api';
import { MetricWithTrend } from '../types/common';

// TODO: the BE is sending strings, but all the values are actually numbers
export interface FailureRatesData extends MetricWithTrend {
  customers_impacted: string;
  failure_rate: string;
  in_the_field: string;
  with_issues: string;
}

type FailureRatesResponse = ApiResponse<FailureRatesData>;

export const fetchFailureRates = async ({
  country,
  equipmentType,
  timeRange,
  withTrend,
  customerId,
  signal,
  mock,
}: FetchMetricsParams): Promise<FailureRatesData> => {
  const { data } = await api.get<FailureRatesResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      equipmentType,
      timeRange,
      withTrend,
      customerId,
      country,
      type: 'FailureRate',
    },
    config: { signal },
  });

  return data;
};
