import { JobTypeCounts } from '../types/charts';
import api, { ApiFetchParams, ApiResponse } from './api';

export interface Demands {
  part_id: string;
  part_name: string;
  demands: JobTypeCounts;
  history: {
    last_year: number;
    this_year: number;
    last_year_same_month: number;
  };
}

export interface TrendingPartsChartDataPoint {
  month: string;
  demands: Demands[];
}

export interface TrendingPartsSummaryChartDataPoint {
  id: number;
  name: string;
  demands: JobTypeCounts;
}

export type TrendingPartsChartData = TrendingPartsChartDataPoint[] | null;

export type TrendingPartsSummaryChartData =
  | TrendingPartsSummaryChartDataPoint[]
  | null;

export interface TrendingPartsSummaryMetric {
  id?: number;
  month: string;
  demands: JobTypeCounts;
}

export interface ActualDemandMetrics {
  month: string;
  summary: JobTypeCounts;
  details: Demands[];
}

export interface TrendingPartsData {
  yearly_summary: TrendingPartsSummaryMetric[] | null;
  monthly_details: TrendingPartsChartData;
  actual_demand_current_month: ActualDemandMetrics;
}

type TrendingPartsResponse = ApiResponse<TrendingPartsData>;

export const fetchTrendingParts = async ({
  signal,
  mock,
}: ApiFetchParams): Promise<TrendingPartsData> => {
  const { data } = await api.get<TrendingPartsResponse>({
    mock,
    path: '/parts-forecast/api/v1/forecast',
    config: { signal },
  });

  return data;
};
