import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus, FetchKpiParams } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchFirstTimeFixedRate,
  FirstTimeFixedRateData,
} from '../../integration/ftfr.api';

export const FIRST_TIME_FIXED_RATE_KEY = 'firstTimeFixedRate';

export interface FirstTimeFixedRateState {
  status: FetchingStatus;
  error: string | null;
  metrics: FirstTimeFixedRateData | null;
}
const initialState: FirstTimeFixedRateState = {
  status: FetchingStatus.IDLE,
  error: null,
  metrics: null,
};
export const getFirstTimeFixedRate = createAsyncThunk<
  FirstTimeFixedRateData,
  FetchKpiParams,
  AppThunkConfig
>(
  'getFirstTimeFixedRate',
  async ({ withTrend = false }, { getState, signal }) => {
    const {
      filters: {
        dashboard: { country, customer, equipmentType, timeRange },
      },
      mockServer,
    } = getState();

    return fetchFirstTimeFixedRate({
      country,
      equipmentType,
      timeRange,
      withTrend,
      signal,
      customerId: customer?.id,
      mock: mockServer.active,
    });
  },
);

const firstTimeFixedRateSlice = createSlice({
  name: FIRST_TIME_FIXED_RATE_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFirstTimeFixedRate.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getFirstTimeFixedRate.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.metrics = action.payload;
      })
      .addCase(getFirstTimeFixedRate.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.metrics = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = firstTimeFixedRateSlice;

export const firstTimeFixedRateReducer = reducer;
