import React from 'react';
import { formatNumberByLocale } from '../../../utils/formatNumberByLocale';
import { isDefined } from '../../../utils/isDefined';
import { useAppSelector } from '../../../store/utils/hooks';

interface CustomBarChartLabelProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fillColor: string;
  value: string;
  isActive?: boolean;
  onClick?: () => void;
  fontSize?: number;
  fontWeight?: number;
}

const CustomBarChartLabel: React.FC<CustomBarChartLabelProps> = ({
  x,
  y,
  width,
  height,
  fillColor,
  value,
  isActive,
  onClick,
  fontSize,
  fontWeight,
}) => {
  const getOpacity = () => {
    if (isDefined(isActive)) {
      return isActive ? 1 : 0.1;
    } else {
      return 1;
    }
  };
  const locale = useAppSelector((state) => state.translation.locale);

  return (
    <text
      x={x + width + 4}
      y={y + height / 2}
      fill={fillColor}
      dominantBaseline="middle"
      textAnchor="start"
      style={{ cursor: 'pointer', opacity: getOpacity(), fontSize, fontWeight }}
      onClick={onClick}
    >
      {formatNumberByLocale(locale, Number(value))}
    </text>
  );
};

export default CustomBarChartLabel;
