import { TimeRange } from '../../types/common';
import { GetSeedParams } from '../test-utils';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { MeanTimeToResolutionData } from '../../integration/mttr.api';

const mttrBaseSeed: MeanTimeToResolutionData = {
  trend: 0.01,
  mean_time_to_resolution: [
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '11000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '20000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '14000', date_interval: '2022-11-23T12:11:19.379Z' },
    { mean_time_to_res: '10000', date_interval: '2022-11-23T12:11:19.379Z' },
  ],
};

const getMttrSeed = ({
  timeRange,
  country,
  customer,
  equipmentType,
}: GetSeedParams): MeanTimeToResolutionData => {
  if (country === countriesSeed.countries[1].code) {
    return {
      trend: null,
      mean_time_to_resolution: [],
    };
  }

  if (customer === searchSeed.customers[0].id) {
    return {
      trend: 0.01,
      mean_time_to_resolution: mttrBaseSeed.mean_time_to_resolution.slice(0, 5),
    };
  }

  if (equipmentType === searchSeed.equipment_types[1]) {
    return {
      trend: -0.01,
      mean_time_to_resolution: mttrBaseSeed.mean_time_to_resolution.slice(0, 6),
    };
  }

  if (timeRange === TimeRange.ONE_MONTH) {
    return {
      trend: 0.01,
      mean_time_to_resolution: mttrBaseSeed.mean_time_to_resolution.map(
        (dataPoint) => ({
          ...dataPoint,
          mean_time_to_res: String(Number(dataPoint.mean_time_to_res) + 2900),
        }),
      ),
    };
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return {
      trend: 0.01,
      mean_time_to_resolution: mttrBaseSeed.mean_time_to_resolution.map(
        (dataPoint) => ({
          ...dataPoint,
          mean_time_to_res: String(Number(dataPoint.mean_time_to_res) + 2900),
        }),
      ),
    };
  }

  return mttrBaseSeed;
};

export default getMttrSeed;
