import api, { ApiFetchParams, ApiResponse } from './api';

export interface TechnicianInfo {
  name: string;
  jobNumber: number;
  equipment: string;
}

export interface Service {
  ratingText: string;
  rating: number;
  progressValue: number;
}

export interface RatingsAndReviewsData {
  rating: number;
  totalReviews: number;
  service: Service[];
  technicianInfo: TechnicianInfo;
}

export interface RatingCommentsData {
  name: string;
  title: string;
  descriptionTitle: string;
  description: string;
  rating: number;
}

export interface TopEquipmentComplaintData {
  rating: number;
  equipment: string;
  service: Service[];
  complaint: Complaint[];
}

export interface Complaint {
  rating: number;
  companyName: string;
  description: string;
}

export interface RatingsAndReviewsDataset {
  ratings: RatingsAndReviewsData;
  comments: RatingCommentsData[];
  complaint: TopEquipmentComplaintData;
}

type RnRResponse = ApiResponse<RatingsAndReviewsDataset>;

export const fetchRatingsAndReviews = async ({
  signal,
  mock,
}: ApiFetchParams): Promise<RatingsAndReviewsDataset> => {
  const { data } = await api.get<RnRResponse>({
    mock,
    path: '/analytics/api/v1/ratings',
    config: { signal },
  });

  return data;
};
