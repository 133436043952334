import { TimeRange } from '../../types/common';
import { GetSeedParams } from '../test-utils';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { MeanTimeBetweenVisitsData } from '../../integration/mtbv.api';

const mtbvBaseSeed: MeanTimeBetweenVisitsData = {
  trend: 0.04,
  mean_time_between_visits: [
    {
      mean_time_between_visits: '95',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '105',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '130',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '125',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '140',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '170',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '160',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '95',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '145',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
    {
      mean_time_between_visits: '125',
      date_interval: '2022-11-23T12:11:19.379Z',
    },
  ],
};

const getMtbvSeed = ({
  timeRange,
  country,
  customer,
  equipmentType,
}: GetSeedParams): MeanTimeBetweenVisitsData => {
  if (country === countriesSeed.countries[1].code) {
    return {
      trend: null,
      mean_time_between_visits: [],
    };
  }

  if (customer === searchSeed.customers[0].id) {
    return {
      trend: 0.04,
      mean_time_between_visits: mtbvBaseSeed.mean_time_between_visits.slice(
        0,
        5,
      ),
    };
  }

  if (equipmentType === searchSeed.equipment_types[1]) {
    return {
      trend: -0.04,
      mean_time_between_visits: mtbvBaseSeed.mean_time_between_visits.slice(
        0,
        6,
      ),
    };
  }

  if (timeRange === TimeRange.ONE_MONTH) {
    return {
      trend: 0.04,
      mean_time_between_visits: mtbvBaseSeed.mean_time_between_visits.map(
        (dataPoint) => ({
          ...dataPoint,
          mean_time_between_visits: String(
            Number(dataPoint.mean_time_between_visits) + 12,
          ),
        }),
      ),
    };
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return {
      trend: 0.04,
      mean_time_between_visits: mtbvBaseSeed.mean_time_between_visits.map(
        (dataPoint) => ({
          ...dataPoint,
          mean_time_between_visits: String(
            Number(dataPoint.mean_time_between_visits) + 12,
          ),
        }),
      ),
    };
  }

  return mtbvBaseSeed;
};

export default getMtbvSeed;
