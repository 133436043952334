import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { FetchingStatus, FetchKpiParams } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchTrendingEquipmentFailure,
  TrendingEquipmentFailureMetric,
} from '../../integration/trendingEquipment.api';

export const TRENDING_EQUIPMENT_FAILURE_KEY = 'trendingEquipmentFailure';

export interface TrendingEquipmentFailureState {
  status: FetchingStatus;
  error: string | null;
  metrics: TrendingEquipmentFailureMetric[] | null;
}

const initialState: TrendingEquipmentFailureState = {
  status: FetchingStatus.IDLE,
  error: null,
  metrics: null,
};

export const getTrendingEquipmentFailure = createAsyncThunk<
  TrendingEquipmentFailureMetric[],
  FetchKpiParams,
  AppThunkConfig
>(
  'fetchTopEquipmentFailure',
  async ({ withTrend = false }, { getState, signal }) => {
    const {
      filters: {
        dashboard: { country, customer, timeRange },
      },
      mockServer,
    } = getState();

    return fetchTrendingEquipmentFailure({
      country,
      timeRange,
      withTrend,
      signal,
      customerId: customer?.id,
      mock: mockServer.active,
    });
  },
);

const trendingEquipmentFailureSlice = createSlice({
  name: TRENDING_EQUIPMENT_FAILURE_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTrendingEquipmentFailure.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getTrendingEquipmentFailure.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.metrics = action.payload;
      })
      .addCase(getTrendingEquipmentFailure.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.metrics = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = trendingEquipmentFailureSlice;

export const trendingEquipmentFailureReducer = reducer;
