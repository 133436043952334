import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchPartPredictionQuality,
  fetchRootCausePredictionQuality,
  PredictionQualitySummary,
  SummaryType,
} from '../../integration/predictionQuality.api';

export const PREDICTION_QUALITY_KEY = 'predictionQuality';

export interface PredictionQualityState {
  rootCausePredictionQuality: PredictionQualitySummary;
  rootCausePredictionStatus: FetchingStatus;
  rootCausePredictionError: string | null;
  partPredictionQuality: PredictionQualitySummary;
  partPredictionStatus: FetchingStatus;
  partPredictionError: string | null;
}

const initialState: PredictionQualityState = {
  rootCausePredictionQuality: {
    accuracy: null,
    trend: null,
    lowConfidence: 0,
    mediumConfidence: 0,
    highConfidence: 0,
    total: 0,
    bestPredictions: [],
    worstPredictions: [],
  },
  rootCausePredictionStatus: FetchingStatus.IDLE,
  rootCausePredictionError: null,
  partPredictionQuality: {
    accuracy: null,
    trend: null,
    lowConfidence: 0,
    mediumConfidence: 0,
    highConfidence: 0,
    total: 0,
    bestPredictions: [],
    worstPredictions: [],
  },
  partPredictionStatus: FetchingStatus.IDLE,
  partPredictionError: null,
};

export const getRootCausePredictionQuality = createAsyncThunk<
  PredictionQualitySummary,
  SummaryType,
  AppThunkConfig
>(
  'getRootCausePredictionQuality',
  async (summaryType, { getState, signal }) => {
    const {
      filters: {
        dashboard: { timeRange },
      },
      mockServer,
    } = getState();

    return fetchRootCausePredictionQuality({
      timeRange,
      summaryType,
      signal,
      mock: mockServer.active,
    });
  },
);

export const getPartPredictionQuality = createAsyncThunk<
  PredictionQualitySummary,
  SummaryType,
  AppThunkConfig
>('getPartPredictionQuality', async (summaryType, { getState, signal }) => {
  const {
    filters: {
      dashboard: { timeRange },
    },
    mockServer,
  } = getState();

  return fetchPartPredictionQuality({
    timeRange,
    summaryType,
    signal,
    mock: mockServer.active,
  });
});

const predictionQualitySlice = createSlice({
  name: PREDICTION_QUALITY_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRootCausePredictionQuality.pending, (state) => {
        state.rootCausePredictionStatus = FetchingStatus.PENDING;
      })
      .addCase(getRootCausePredictionQuality.fulfilled, (state, action) => {
        state.rootCausePredictionStatus = FetchingStatus.SUCCESS;
        state.rootCausePredictionQuality = action.payload;
      })
      .addCase(getRootCausePredictionQuality.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.rootCausePredictionStatus = FetchingStatus.ERROR;
        state.rootCausePredictionQuality =
          initialState.rootCausePredictionQuality;
        state.rootCausePredictionError =
          action.error.message || 'Something went wrong.';
      })
      .addCase(getPartPredictionQuality.pending, (state) => {
        state.partPredictionStatus = FetchingStatus.PENDING;
      })
      .addCase(getPartPredictionQuality.fulfilled, (state, action) => {
        state.partPredictionStatus = FetchingStatus.SUCCESS;
        state.partPredictionQuality = action.payload;
      })
      .addCase(getPartPredictionQuality.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.partPredictionStatus = FetchingStatus.ERROR;
        state.partPredictionQuality = initialState.partPredictionQuality;
        state.partPredictionError =
          action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = predictionQualitySlice;

export const predictionQualityReducer = reducer;
