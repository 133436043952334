import React, { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import { ReactComponent as DocumentIcon } from '../assets/documentIcon.svg';
import { ReactComponent as ReloadIcon } from '../assets/reload.svg';
import { ReactComponent as PdfUploadFailure } from '../assets/pdfUploadfailure.svg';
import { FetchingStatus } from '../../../types/common';
import AppDialog from '../../dialog/Dialog';
import { ReactComponent as PdfIcon } from '../assets/pdfIcon.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { convertTimestampToDateString } from '../../../utils/convertTimestampToDateString';
import { truncateFileName } from '../utils/truncateFileName';
import {
  DocumentSortType,
  FileInfo,
} from '../../../integration/documentManagement.api';
import { useTranslations } from '../../../store/slices/translation.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { getDocumentManagementData } from '../../../store/slices/documentManagement.slice';
import {
  DOCUMENTS_DEFAULT_PAGE_SIZE,
  DOCUMENTS_DEFAULT_SORT_TYPE,
  FileProps,
} from './DocumentManagement';
import { useMockServerActive } from '../../../store/slices/mockServer.slice';
import styles from './DocumentManagementDialog.module.scss';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  equipmentType: string | null;
  isDragging: boolean;
  handleDragEnter: (e: React.DragEvent) => void;
  handleDragLeave: () => void;
  handleDragOver: (event: React.DragEvent) => void;
  handleDrop: (event: React.DragEvent) => void;
  triggerFileInputClick: () => void;
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortOption: DocumentSortType;
  handleChange: (event?: SelectChangeEvent) => void;
  sortedDocuments: FileInfo[];
  filesDataArray: FileProps[];
  failedToUploadDocuments: string[];
  hasNoFiles: boolean;
}

const DocumentManagementDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  equipmentType,
  isDragging,
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
  triggerFileInputClick,
  handleFileInputChange,
  sortOption,
  handleChange,
  sortedDocuments,
  filesDataArray,
  failedToUploadDocuments,
}) => {
  const isMocking = useMockServerActive();
  const { documentUpload: translations } = useTranslations();
  const documentData = useAppSelector(
    (state) => state.documentManagement.documentData.data,
  );
  const documentUploadData = useAppSelector(
    (state) => state.documentManagement.documentUpload.data,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DOCUMENTS_DEFAULT_PAGE_SIZE);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const uploadStatus = useAppSelector(
    (state) => state.documentManagement.documentUpload.status,
  );

  const uploadComplete = uploadStatus === FetchingStatus.SUCCESS;

  useEffect(() => {
    const promise = dispatch(
      getDocumentManagementData({
        page: page + 1,
        size: rowsPerPage,
        sort: sortOption,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    uploadComplete,
    rowsPerPage,
    page,
    sortOption,
    isMocking,
    equipmentType,
    documentUploadData,
  ]);

  const renderFailedUploads = () => {
    if (failedToUploadDocuments.length > 0 && filesDataArray.length === 0) {
      return failedToUploadDocuments.map((fileName, index) => (
        <TableRow key={index}>
          <TableCell size="small" className={styles.failedFilesTableCell}>
            <Typography className={styles.fileNameText}>
              <PdfUploadFailure className={styles.pdfIconList} />
              <span className={styles.failureTextContainer}>
                {truncateFileName(fileName, 30)}
                <Typography className={styles.failureSubtitleDialog}>
                  {translations.uploadFailure}
                </Typography>
              </span>
            </Typography>
          </TableCell>
          <TableCell className={styles.failedFilesTableCell}>--</TableCell>
          <TableCell className={styles.failedFilesTableCell}>
            {convertTimestampToDateString(
              Math.floor(Date.now() / 1000).toString(),
            )}
          </TableCell>
          <TableCell className={styles.failedFilesTableCell}>
            <div className={styles.reload}>
              <ReloadIcon />
            </div>
          </TableCell>
        </TableRow>
      ));
    }
  };
  const renderUploadedFiles = () => {
    if (filesDataArray.length > 0) {
      return filesDataArray.map((document, index) => (
        <TableRow key={index}>
          <TableCell size="small">
            <Typography className={styles.fileNameText}>
              <PdfIcon className={styles.pdfIconList} />
              {truncateFileName(document.fileName, 30)}
            </Typography>
          </TableCell>
          <TableCell>{document.fileSize.toUpperCase()}</TableCell>
          <TableCell>
            {convertTimestampToDateString(
              Math.floor(Date.now() / 1000).toString(),
            )}
          </TableCell>
          <TableCell>
            <Typography className={styles.fileSize}>
              <div className={styles.progress}>
                <CircularProgress size={24} sx={{ color: '#00BDC7' }} />
              </div>
            </Typography>
          </TableCell>
        </TableRow>
      ));
    }
  };
  return (
    <AppDialog
      open={open}
      handleClose={handleClose}
      keepMounted
      showCloseIcon={false}
      className={styles.dialog}
    >
      <div className={styles.iconAndTitle}>
        <Typography variant="h5" className={styles.equipmentName}>
          <DocumentIcon />
          {equipmentType} {translations.documents}
        </Typography>
      </div>
      <div className={styles.docUploadContainer}>
        <div
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={clsx(
            styles.documentUploadDialog,
            isDragging && styles.documentUploadDialogDragOver,
          )}
        >
          <div className={styles.uploadTextDialog}>
            <span
              onClick={triggerFileInputClick}
              className={styles.highlightedTextDialog}
            >
              {translations.clickToUpload}
            </span>{' '}
            <input
              type="file"
              multiple
              onChange={handleFileInputChange}
              hidden
              accept=".pdf"
              ref={inputRef}
            />
            {translations.dragAndDrop}
          </div>
        </div>
        <div className={styles.docTableContainer}>
          <TableContainer className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translations.dialog.fileName}</TableCell>
                  <TableCell>{translations.dialog.fileSize}</TableCell>
                  <TableCell>{translations.dialog.date}</TableCell>
                  <TableCell>
                    <div className={styles.sort}>
                      <Typography className={styles.sortText}>
                        {translations.dialog.sortBy}
                      </Typography>
                      <FormControl className={styles.sortDropDown}>
                        <Select
                          defaultValue={DOCUMENTS_DEFAULT_SORT_TYPE}
                          value={sortOption}
                          onChange={handleChange}
                        >
                          <MenuItem value={DocumentSortType.Date}>
                            {translations.dialog.date}
                          </MenuItem>
                          <MenuItem value={DocumentSortType.FileName}>
                            {translations.dialog.fileName}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDocuments.length === 0 ? (
                  <Typography className={styles.noDocument}>
                    {translations.noFilesMessage}
                  </Typography>
                ) : (
                  <>
                    {renderFailedUploads()}
                    {renderUploadedFiles()}
                    {sortedDocuments.map((document, index) => (
                      <TableRow key={index}>
                        <TableCell size="small">
                          <Typography className={styles.fileNameText}>
                            <PdfIcon className={styles.pdfIconList} />
                            {truncateFileName(document.filename, 25)}
                          </Typography>
                        </TableCell>
                        <TableCell>{document.size.toUpperCase()}</TableCell>
                        <TableCell>
                          {convertTimestampToDateString(document.timestamp)}
                        </TableCell>
                        <TableCell>
                          <div className={styles.downloadAndDelete}>
                            <a href={document.url} download>
                              <DownloadIcon className={styles.downloadIcon} />
                            </a>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={documentData.totalElements}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </div>
    </AppDialog>
  );
};

export default DocumentManagementDialog;
