import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ONBOARDING_TOUR_KEY = 'onboardingTour';

export enum HighlightedComponent {
  HeaderFilters = 'headerFilters',
  KpisGlanceAndProcessStatus = 'kpisGlanceAndProcessStatus',
  TrendingEquipmentAndSymptoms = 'trendingEquipmentAndSymptoms',
  ContactSupport = 'contactSupport',
  None = 'none',
}

export interface OnboardingTourState {
  startTour: boolean;
  restartTour: boolean;
  skipTour: boolean;
  currentStep: HighlightedComponent | null;
}

const initialState: OnboardingTourState = {
  startTour: true,
  restartTour: false,
  skipTour: false,
  currentStep: null,
};

const onboardingTourSlice = createSlice({
  initialState,
  name: ONBOARDING_TOUR_KEY,
  reducers: {
    updateOnboardingState(
      state,
      action: PayloadAction<Partial<OnboardingTourState>>,
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

const { actions, reducer } = onboardingTourSlice;

export const { updateOnboardingState } = actions;

export const onboardingTourReducer = reducer;
