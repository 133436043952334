import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  ActualDemandMetrics,
  fetchTrendingParts,
  TrendingPartsChartData,
  TrendingPartsData,
  TrendingPartsSummaryMetric,
} from '../../integration/trendingParts.api';

export const TRENDING_PARTS_KEY = 'trendingParts';

export interface TrendingPartsState {
  status: FetchingStatus;
  error: string | null;
  yearlySummary: TrendingPartsSummaryMetric[] | null;
  monthlyDetails: TrendingPartsChartData;
  actualDemandCurrentMonth: ActualDemandMetrics | null;
  selectedMonth: string | null;
  selectedPart: SelectedPart | null;
}

const initialState: TrendingPartsState = {
  status: FetchingStatus.IDLE,
  error: null,
  yearlySummary: null,
  monthlyDetails: null,
  actualDemandCurrentMonth: null,
  selectedMonth: null,
  selectedPart: null,
};

export interface SelectedPart {
  name: string;
  history: {
    last_year: number;
    this_year: number;
    last_year_same_month: number;
  };
  index: number;
}

export const getTrendingParts = createAsyncThunk<
  TrendingPartsData,
  void,
  AppThunkConfig
>('getTrendingParts', async (_, { getState, signal }) => {
  const { mockServer } = getState();

  return fetchTrendingParts({ signal, mock: mockServer.active });
});

const trendingPartsSlice = createSlice({
  name: TRENDING_PARTS_KEY,
  initialState,
  reducers: {
    selectTrendingPartsMonth(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        selectedMonth: action.payload,
        selectedPart: null,
      };
    },
    selectPart(state, action: PayloadAction<SelectedPart | null>) {
      return {
        ...state,
        selectedPart: action.payload,
      };
    },
    resetParts() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTrendingParts.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getTrendingParts.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.yearlySummary = action.payload.yearly_summary;
        state.monthlyDetails = action.payload.monthly_details;
        state.actualDemandCurrentMonth =
          action.payload.actual_demand_current_month;
      })
      .addCase(getTrendingParts.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.yearlySummary = null;
        state.monthlyDetails = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { actions, reducer } = trendingPartsSlice;

export const { selectTrendingPartsMonth, selectPart, resetParts } = actions;

export const trendingPartsReducer = reducer;
