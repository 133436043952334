import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchTrendingSymptoms,
  TrendingSymptomMetric,
} from '../../integration/trendingSymptoms.api';

export const TRENDING_SYMPTOMS_KEY = 'trendingSymptoms';

export interface TrendingSymptomsState {
  status: FetchingStatus;
  error: string | null;
  metrics: TrendingSymptomMetric[] | null;
}
const initialState: TrendingSymptomsState = {
  status: FetchingStatus.IDLE,
  error: null,
  metrics: null,
};

export const getTrendingSymptoms = createAsyncThunk<
  TrendingSymptomMetric[],
  void,
  AppThunkConfig
>('getTrendingSymptoms', async (_, { getState, signal }) => {
  const {
    filters: {
      dashboard: { country, customer, equipmentType, timeRange },
    },
    mockServer,
  } = getState();

  return fetchTrendingSymptoms({
    country,
    equipmentType,
    timeRange,
    signal,
    customerId: customer?.id,
    withTrend: false,
    mock: mockServer.active,
  });
});

const trendingSymptomsSlice = createSlice({
  name: TRENDING_SYMPTOMS_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTrendingSymptoms.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getTrendingSymptoms.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.metrics = action.payload;
      })
      .addCase(getTrendingSymptoms.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.metrics = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = trendingSymptomsSlice;

export const trendingSymptomsReducer = reducer;
