interface DurationData {
  date_interval: string;
  duration: number;
}

export const calculateAverageDuration = (
  durationArray: DurationData[],
): number => {
  const totalDuration = durationArray.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.duration;
  }, 0);
  return totalDuration / durationArray.length;
};
