import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchWorkflowBottleneck,
  WorkflowBottleneckData,
} from '../../integration/workflowBottleneck.api';

export const WORKFLOW_BOTTLENECK_KEY = 'workflowBottleneck';

export interface WorkflowBottleneckState {
  status: FetchingStatus;
  error: string | null;
  data: WorkflowBottleneckData | null;
}

const initialState: WorkflowBottleneckState = {
  status: FetchingStatus.IDLE,
  error: null,
  data: null,
};

export const getWorkflowBottleneck = createAsyncThunk<
  WorkflowBottleneckData,
  void,
  AppThunkConfig
>('getWorkflowBottleneck', async (_, { getState, signal }) => {
  const {
    filters: {
      dashboard: { timeRange },
    },
    mockServer,
  } = getState();

  return fetchWorkflowBottleneck({
    signal,
    timeRange,
    mock: mockServer.active,
  });
});

const workflowBottleneckSlice = createSlice({
  name: WORKFLOW_BOTTLENECK_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkflowBottleneck.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getWorkflowBottleneck.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getWorkflowBottleneck.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.data = null;
        state.error = action.error.message || 'Something went wrong.';
      });
  },
});

const { reducer } = workflowBottleneckSlice;

export const workflowBottleneckReducer = reducer;
