import React, { useEffect, useMemo } from 'react';

import CardContainer from '../cardContainer/CardContainer';
import { ReactComponent as Parts } from '../../assets/images/parts.svg';
import TrendingPartsSummaryChart from './charts/summary/TrendingPartsSummaryChart';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { useMockServerActive } from '../../store/slices/mockServer.slice';
import { getTrendingParts } from '../../store/slices/trendingParts.slice';
import styles from './TrendingParts.module.scss';
import { useTranslations } from '../../store/slices/translation.slice';
import TrendingPartsMonthlyChart from './charts/monthly/TrendingPartsMonthlyChart';
import { FetchingStatus, TimeRange } from '../../types/common';

const TrendingParts: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();

  useEffect(() => {
    const promise = dispatch(getTrendingParts());

    return () => {
      promise.abort();
    };
  }, [isMockServerActive, dispatch]);

  const {
    status,
    yearlySummary,
    monthlyDetails,
    selectedMonth,
    actualDemandCurrentMonth,
  } = useAppSelector((state) => state.trendingParts);

  const {
    timeRanges,
    chartLabels,
    trendingParts: translations,
    months,
    monthsMap,
  } = useTranslations();

  const details = useMemo(() => {
    if (monthlyDetails === null || actualDemandCurrentMonth === null) {
      return null;
    }

    const actualPartDetails = {
      month: `Actual-${
        months[new Date(actualDemandCurrentMonth.month).getMonth()]
      }`,
      demands: actualDemandCurrentMonth.details,
    };

    const monthlyDetailsData = monthlyDetails.map((dataPoint) => ({
      ...dataPoint,
      month: months[new Date(dataPoint.month).getMonth()],
    }));
    monthlyDetailsData.unshift(actualPartDetails);

    return monthlyDetailsData;
  }, [monthlyDetails, actualDemandCurrentMonth]);

  const summary = useMemo(() => {
    if (yearlySummary === null || actualDemandCurrentMonth === null) {
      return null;
    }

    const actualSummaryForCurrentMonth = {
      month: actualDemandCurrentMonth.month,
      demands: {
        maintenance: actualDemandCurrentMonth.summary.maintenance,
        failure: actualDemandCurrentMonth.summary.failure,
        other: actualDemandCurrentMonth.summary.other,
      },
    };

    const yearlySummaryData = [...yearlySummary];
    yearlySummaryData.unshift(actualSummaryForCurrentMonth);

    const yearlySummaryMonths = yearlySummaryData.map((dataPoint, index) => ({
      ...dataPoint,
      id: index,
    }));

    return yearlySummaryMonths.map((dataPoint) => ({
      ...dataPoint,
      name: months[new Date(dataPoint.month).getMonth()],
    }));
  }, [yearlySummary, actualDemandCurrentMonth]);

  const selectedMonthLabel = useMemo(() => {
    const currentMonth = months[new Date().getMonth()];

    if (selectedMonth === null || selectedMonth === currentMonth) {
      return timeRanges[TimeRange.ONE_MONTH];
    }

    return monthsMap[selectedMonth];
  }, [selectedMonth]);

  const noDataAvailable =
    (yearlySummary !== null && yearlySummary.length === 0) ||
    (monthlyDetails !== null && monthlyDetails.length === 0) ||
    status === FetchingStatus.ERROR;

  if (noDataAvailable) {
    return null;
  }

  return (
    <CardContainer
      logo={<Parts />}
      title={translations.mainTitle}
      tooltipContent={translations.tooltip}
      status={status}
      testId="trending-parts"
    >
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <TrendingPartsMonthlyChart
            data={details}
            title={translations.monthChartTitle({
              month: selectedMonthLabel,
            })}
            status={status}
            testId="trending-part-details-chart"
            chartLabel={chartLabels.numOfParts}
          />
        </div>
        <div className={styles.rightSection}>
          <TrendingPartsSummaryChart
            data={summary}
            status={status}
            testId="trending-part-summary-chart"
          />
        </div>
      </div>
    </CardContainer>
  );
};

export default TrendingParts;
