import React from 'react';

import { Translations } from './common';
import { TimeRange } from '../../types/common';

const englishTranslations: Translations = {
  loading: 'Loading...',
  noOptions: 'No options',
  noData: (
    <>
      We could not load any data. Try changing a filter.
      <br />
      If something is not working correctly you can send us a{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-frontend-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        message
      </a>
      .
    </>
  ),
  errorCardText: (
    <>
      Oops.
      <br />
      Looks like this section is not working. Try again later or send us a{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-frontend-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        message
      </a>
      .
    </>
  ),
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  monthsFull: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthsMap: {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  },
  timeUnitLabels: {
    hours: 'hours',
    day: 'day',
    days: 'days',
  },
  durationInDays: (days) => `${days} ${days >= 2 ? 'days' : 'day'}`,
  auth: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    sessionExpired:
      'Your session has expired. Please log in again to continue using InsightLoop.',
    or: 'or',
    loading: 'Authenticating...',
    marketingMessage: 'Ready for Take Off?',
    invalidCredentials: 'Invalid email or password',
    pilotDescription: (
      <>
        Partnering for Success: Our Collaborative Approach to Empower Your
        Journey. Let us help you to unlock the power of your data –{' '}
        <a href="mailto:sales@coresystems.ch" target="">
          request
        </a>{' '}
        your access now.
      </>
    ),
    unauthorized:
      'The Dashboard is currently only available for early-access clients.',
    azure: {
      loginButtonText: 'Login with Microsoft',
      tooltipLabel: 'Need help?',
      tooltipContent:
        'This is the same login you use to access Azure. If not sure, please contact IT support.',
    },
  },
  failureRate: {
    changesThisPeriod: ({ periodLabel }) => `Change ${periodLabel}`,
    failureRateText: 'Failure Rate',
    reliability: 'Reliability',
    inTheField: 'In the Field',
    locationsImpacted: 'Customers Impacted',
    mainTitle: ({ equipmentType }) => `Equipment details for ${equipmentType}`,
    withIssues: 'With Issues',
    tooltip:
      'Rate of failure of the given equipment and an overview of the current install based of this equipment type in the field. Trends are related to the change between the displayed period and the previous one. This can potentially lead to the trend being enormously high or low.',
  },
  documentUpload: {
    tooltip:
      "Expand InsightLoop's knowledge base by uploading documents per equipment type. This will enable InsightLoop to conduct deep, semantically-driven searches, enhancing the immersive assistant's ability to deliver precise and comprehensive answers.",
    documents: 'Documents',
    clickToUpload: 'Click to upload',
    seeAll: 'See all',
    noFilesMessage: 'Uploaded files are displayed here.',
    dragAndDrop: 'or drag and drop',
    uploadFailure: 'File already exists',
    dialog: {
      fileName: 'File name',
      fileSize: 'File size',
      date: 'Date',
      sortBy: 'Sort by',
    },
  },
  timeSpan: {
    [TimeRange.ONE_YEAR]: 'since last year',
    [TimeRange.SIX_MONTHS]: 'since the last 6 months',
    [TimeRange.ONE_MONTH]: 'since last month',
    [TimeRange.ONE_WEEK]: 'since last week',
  },
  filters: {
    countryFilter: 'All countries',
    customerFilter: 'All customers',
    equipmentTypeFilter: 'All equipment',
    time: {
      [TimeRange.ONE_YEAR]: 'Year',
      [TimeRange.SIX_MONTHS]: '6 Months',
      [TimeRange.ONE_MONTH]: 'Month',
      [TimeRange.ONE_WEEK]: 'Week',
    },
  },
  kpiCharts: {
    mainTitle: 'KPIs at a Glance',
    metrics: {
      tooltipContent: [
        {
          id: 'FTFR',
          definition:
            'Percentage of issues of type failure that were resolved within the first visit.',
          description: `Note: A return visits to the same piece of equipment in less than 30 days is considered not a first time fix.`,
        },
        {
          id: 'MTTR',
          definition: 'Average time a job remains open.',
          description: `Note: The average time between a failure job being created until it is closed in the system.`,
        },
        {
          id: 'MTBV',
          definition: 'Average time between visits per customer location.',
          description: `Note: Average time between visits per customer location. Includes all job types.`,
        },
        {
          id: 'Trends',
          definition: 'Trends',
          description:
            'are related to the change between the displayed period and the previous one. This can potentially lead to the trend being enormously high or low.',
        },
      ],
      firstTimeFixRate: 'First-Time Fix Rate',
      meanTimeToResolution: 'Mean Time to Resolution',
      meanTimeBetweenVisits: 'Mean Time Between Visits',
      ratings: {
        title: 'Ratings',
        tooltipSummary: '',
        tooltipDetails: '',
      },
    },
    tooltip:
      'Top Field Service Key Performance Indicators that provide quantifiable data about field service operations to measure operational efficiency. Trends are related to the change between the displayed period and the previous one. This can potentially lead to the trend being enormously high or low.',
  },
  chartLabels: {
    numOfJobs: 'Num. of jobs',
    numOfParts: 'Num. of parts',
    occurrences: 'Occurrences',
  },
  openJobs: {
    equipment: 'Equipment',
    issue: 'Reported Issue',
    jobNumber: 'Job Nr.',
    jobsPerPage: 'Jobs per page',
    location: 'Location',
    reportIssue: 'Report Issues',
    rootCauses: 'Predictions',
    serialNumber: 'Serial Number',
    noSerialNumber: 'No serial number available',
    noAddress: 'No address available',
    noPrediction: 'No prediction or prediction confidence is too low (<10%)',
    title: 'Open Jobs',
    tooltip:
      'Jobs currently open for this customer and the predicted root cause of the issue. Root cause confidence percentage will improve over time as the predictor is exposed to more data.',
    tooltipDetailsTitle: `How It's Working`,
    tooltipDetails:
      'Based on root cause diagnosis data from similar jobs of the same equipment type in the past, the predictor is trained to find correlations with customer-reported issues which can then be used for making root cause predictions. The reported issue of the customer acts as an input for the trained predictor. The confidence represents the likelihood that the output of the predictor is correct.',
  },
  openJobsDialog: {
    contactPerson: 'Contact person',
    email: 'Email',
    moreInfo: 'More info',
    moreInfoDescription: 'For additional information, visit the link.',
    number: 'Number',
    similarRootCauses: 'Similar root cause in the past',
  },
  timeRanges: {
    [TimeRange.ONE_YEAR]: 'This Year',
    [TimeRange.SIX_MONTHS]: 'Last Six Months',
    [TimeRange.ONE_MONTH]: 'This Month',
    [TimeRange.ONE_WEEK]: 'This Week',
  },
  trendingCustomers: {
    typeSummaryTitle: 'All Customers and Interventions',
    pieChartTitle: 'Total',
    pieChartTooltip: 'Customer in Focus',
    mainTitle: 'Trending Customers',
    tooltip:
      'Customers with the most jobs for the given time period. Includes all job types (routine maintenance and failures).',
  },
  trendingEquipment: {
    mainTitle: 'Trending Equipment',
    tooltip:
      'Equipment with the most reported issues for the given time period.',
  },
  trendingSymptoms: {
    mainTitle: 'Most Frequent Issues',
    tooltip:
      'Most reported issues by customers. For all equipment, for the given time period.',
    viewAllBtn: 'View all',
  },
  symptomsDialog: {
    searchPlaceholder: ({ equipmentType }) =>
      `Search symptoms for ${equipmentType}`,
    symptomsChartTitle: 'Common Reported Symptoms',
    numberOfSymptoms: (count) => (
      <>
        <b>{count}</b> {count === 1 ? 'symptom' : 'symptoms'}
      </>
    ),
    noResults: 'No results',
    predictionsTitle: 'Prediction Chart',
    selectSymptomPrompt: 'Select a symptom to see predictions',
    partName: 'Part name',
    probability: 'Probability',
    rootCause: 'Root cause',
  },
  trendingParts: {
    mainTitle: 'Trending Parts',
    tooltip:
      'Parts demand forecast with an annual outlook and detailed information on the top parts on a monthly basis. The forecast is divided by job types.',
    monthChartTitle: ({ month }) => `Parts Forecast for ${month}`,
    selectPartPrompt: 'Select parts to see more details on past usage.',
    maintenance: 'Maintenance',
    failure: 'Failure',
    other: 'Other',
    total: 'Total',
    partHistory: {
      lastYear: ({ year }) => `Year ${year}`,
      currentYear: ({ year }) => `Year ${year} to this date`,
      lastMonth: ({ month }) => `Last ${month}`,
    },
    chartLabels: {
      currentMonth: 'Current month:',
      usedVsPredicted: 'Used vs Predicted',
    },
  },
  workflowBottleneck: {
    mainTitle: 'Process Status',
    tooltipDescription: {
      introduction:
        'Insights into process status and identified bottlenecks that impact service efficiency. These insights help to take the right actions to improve KPIs.',
      kpiDetails: [
        {
          title: 'Job Generation',
          description: 'Time between job created and first task assigned.',
        },
        {
          title: 'Scheduling & Dispatching',
          description:
            ' Time between first task assigned and last task released.',
        },
        {
          title: 'Field Work',
          description: 'Time between first task started and last task closed.',
        },
        {
          title: 'Closure & Invoicing',
          description: 'Time between last task closed and job closed.',
        },
      ],
    },
    stepDuration: 'Step duration',
    overallScore: 'Overall score',
    kpis: [
      {
        title: 'Job backlog',
        id: 'Job backlog',
        tooltip:
          'The number of pending service requests, or jobs that need to be scheduled.',
      },
      {
        title: 'Job Description Quality',
        id: 'Job Description Quality',
        tooltip:
          'The accuracy and completeness of job descriptions provided to field technicians.',
      },
      {
        title: 'Task interruption',
        id: 'Task interruption',
        tooltip:
          'The frequency of interruptions experienced by field engineers while performing their tasks.',
      },
      {
        title: 'Time to schedule',
        id: 'Time to schedule',
        tooltip:
          'The time taken to assign and schedule all tasks to a technician.',
      },
      {
        title: 'Scheduled Travel Time',
        id: 'Scheduled Travel Time',
        tooltip:
          'The anticipated duration for technicians to travel to service locations.',
      },
      {
        title: 'Time to visit',
        id: 'Time to visit',
        tooltip:
          'The time that elapses between job creation and the first start of a task in the field.',
      },
      {
        title: 'Dispatcher/Tech Ratio',
        id: 'Dispatcher/Tech Ratio',
        tooltip:
          'The ratio of dispatchers to field technicians, indicating workload distribution.',
      },
      {
        title: 'Utilization rate',
        id: 'Utilization rate',
        tooltip:
          "The percentage of a technician's time spent on productive work.",
      },
      {
        title: 'Field Visits per Day',
        id: 'Field Visits per Day',
        tooltip:
          'The average number of customer visits made by field technicians in a day.',
      },
      {
        title: 'Field Visits on Time',
        id: 'Field Visits on Time',
        tooltip:
          'The percentage of field visits completed within the scheduled time.',
      },
      {
        title: 'Technician Idle Time',
        id: 'Technician Idle Time',
        tooltip:
          'The amount of time technicians spend without productive tasks.',
      },
      {
        title: 'Variance Task Duration',
        id: 'Variance Task Duration',
        tooltip:
          'The deviation between the estimated and actual task durations.',
      },
      {
        title: 'Ordered Parts Unused',
        id: 'Ordered Parts Unused',
        tooltip:
          'The quantity of parts ordered but not utilized during the service visit.',
      },
      {
        title: 'Job closed',
        id: 'Job closed',
        tooltip: 'The total number of service requests successfully completed.',
      },
      {
        title: 'Job closed - invoiced',
        id: 'Job closed - invoiced',
        tooltip:
          'The number of service requests that have been both completed and invoiced for payment.',
      },
    ],
    kpiTitle: {
      jobGeneration: 'Job Generation',
      scheduling: 'Scheduling & Dispatching',
      fieldWork: 'Field Work',
      closing: 'Closure & Invoicing',
    },
  },
  predictionQuality: {
    openDialog: 'Past prediction quality',
    allPredictions: 'predictions',
    highConfidence: 'High accuracy',
    mediumConfidence: 'Medium accuracy',
    lowConfidence: 'Low accuracy',
    jobDiagnosis: 'Job diagnosis',
    words: 'causes',
    rootCauses: {
      title: 'Root cause prediction quality',
      predicted: 'Predicted root cause(s)',
      used: 'Reported root cause(s)',
    },
    parts: {
      title: 'Parts prediction quality',
      predicted: 'Parts predicted',
      used: 'Parts used',
    },
  },
  contactSupport: {
    title: 'What would you like to talk about?',
    contact: 'Contact',
    feedbackTopics: ['KPIs', 'Equipment', 'Chatbot', 'Predictions', 'Other'],
    takeTour: 'Take a tour',
    mail: (subject) =>
      `mailto:contact-project+coresystemsag1-service-ai-frontend-analytics-ui-support@incoming.gitlab.com?subject=InsightLoop Feedback: ${subject}`,
  },
  dashboardTour: {
    welcome: (
      <>
        Welcome to <br />
        Dashboard
      </>
    ),
    description:
      'You have safely landed on the InsighLoop dashboard. Here you can explore everything related to ...',
    startOnboarding: 'Start onboarding',
    skipOnboarding: 'Skip these tips',
    next: 'Next',
    close: 'Close',
    steps: {
      step: (stepNo) => `Step ${stepNo} of`,
      yourFilters: 'Your filters',
      filtersDescription:
        'Explore the range of filters available to manage your dashboard effectively. Applying a filter will dynamically update the entire dashboard.',
      kpis: 'KPIs',
      kpisDescription:
        'Your key performance indicators (KPIs) will always be prioritized at the top of the screen. Applying filters may lead to changes or the removal of process steps below.',
      frequentEquipmentsAndIssues: 'Most Frequent Equipment and Issues',
      frequentEquipmentsAndIssuesDescription:
        'These two cards showcase the most frequently used equipment and the most common field issues. Clicking on any trending equipment will apply as a filter to the whole dashboard.',
      support: 'Getting support',
      supportDescription:
        'If you still have any questions, you can always contact support by sending an email addressing any doubts you might have in the future.',
    },
  },
};

export default englishTranslations;
