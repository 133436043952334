import { TrendingPartsData } from '../../integration/trendingParts.api';

const trendingPartsSeed: TrendingPartsData = {
  yearly_summary: [
    {
      month: '2023-05-01',
      demands: {
        maintenance: 150,
        failure: 150,
        other: 150,
      },
    },
    {
      month: '2023-06-01',
      demands: {
        maintenance: 120,
        failure: 120,
        other: 147,
      },
    },
    {
      month: '2023-07-01',
      demands: {
        maintenance: 180,
        failure: 60,
        other: 142,
      },
    },
    {
      month: '2023-08-01',
      demands: {
        maintenance: 22,
        failure: 180,
        other: 180,
      },
    },
    {
      month: '2023-09-01',
      demands: {
        maintenance: 130,
        failure: 130,
        other: 125,
      },
    },
    {
      month: '2023-10-01',
      demands: {
        maintenance: 80,
        failure: 80,
        other: 260,
      },
    },
    {
      month: '2023-11-01',
      demands: {
        maintenance: 150,
        failure: 150,
        other: 71,
      },
    },
    {
      month: '2023-12-01',
      demands: {
        maintenance: 140,
        failure: 140,
        other: 76,
      },
    },
    {
      month: '2024-01-01',
      demands: {
        maintenance: 140,
        failure: 140,
        other: 70,
      },
    },
    {
      month: '2024-02-01',
      demands: {
        maintenance: 160,
        failure: 160,
        other: 80,
      },
    },
    {
      month: '2024-03-01',
      demands: {
        maintenance: 165,
        failure: 165,
        other: 80,
      },
    },
    {
      month: '2024-04-01',
      demands: {
        maintenance: 165,
        failure: 165,
        other: 80,
      },
    },
  ],
  monthly_details: [
    {
      month: '2023-05-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Yaw Motor A0',
          demands: {
            failure: 80,
            maintenance: 10,
            other: 40,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 369,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Yaw Motor A1',
          demands: {
            failure: 56,
            maintenance: 78,
            other: 38,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 369,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Yaw Motor A2',
          demands: {
            failure: 34,
            maintenance: 20,
            other: 78,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 183,
          },
        },
        {
          part_id: '24322C4F3AB94CD7B2CA8FD09D147AC1',
          part_name: 'Yaw Motor A3',
          demands: {
            failure: 28,
            maintenance: 30,
            other: 76,
          },
          history: {
            last_year: 1599,
            this_year: 355,
            last_year_same_month: 146,
          },
        },
      ],
    },
    {
      month: '2023-06-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Gear Box A1',
          demands: {
            failure: 75,
            maintenance: 42,
            other: 78,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 356,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Gear Box A2',
          demands: {
            failure: 73,
            maintenance: 73,
            other: 79,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 356,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Gear Box A3',
          demands: {
            failure: 68,
            maintenance: 60,
            other: 47,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 135,
          },
        },
      ],
    },
    {
      month: '2023-07-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Lockout Disk A1',
          demands: {
            failure: 308,
            maintenance: 74,
            other: 41,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 469,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Lockout Disk A2',
          demands: {
            failure: 35,
            maintenance: 88,
            other: 45,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 183,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Lockout Disk A3',
          demands: {
            failure: 102,
            maintenance: 90,
            other: 49,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 183,
          },
        },
      ],
    },
    {
      month: '2023-08-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Break Assembly A0',
          demands: {
            failure: 79,
            maintenance: 58,
            other: 66,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 258,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Break Assembly A1',
          demands: {
            failure: 234,
            maintenance: 68,
            other: 62,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 258,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Break Assembly A2',
          demands: {
            failure: 70,
            maintenance: 66,
            other: 61,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 123,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Break Assembly A3',
          demands: {
            failure: 68,
            maintenance: 68,
            other: 64,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 123,
          },
        },
      ],
    },
    {
      month: '2023-09-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Yaw Drive A0',
          demands: {
            failure: 80,
            maintenance: 22,
            other: 63,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 377,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Yaw Drive A1',
          demands: {
            failure: 316,
            maintenance: 33,
            other: 68,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 377,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Yaw Drive A2',
          demands: {
            failure: 105,
            maintenance: 37,
            other: 63,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 132,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Yaw Drive A3',
          demands: {
            failure: 68,
            maintenance: 56,
            other: 74,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 132,
          },
        },
      ],
    },
    {
      month: '2023-10-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Generator A0',
          demands: {
            failure: 81,
            maintenance: 22,
            other: 7,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 370,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Generator A1',
          demands: {
            failure: 240,
            maintenance: 34,
            other: 72,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 370,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Generator A2',
          demands: {
            failure: 70,
            maintenance: 36,
            other: 71,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 141,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Generator A2',
          demands: {
            failure: 70,
            maintenance: 93,
            other: 51,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 141,
          },
        },
      ],
    },
    {
      month: '2023-11-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Sendor A0',
          demands: {
            failure: 162,
            maintenance: 25,
            other: 56,
          },
          history: {
            last_year: 4267,
            this_year: 881,
            last_year_same_month: 254,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 47,
            other: 57,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 38,
            other: 52,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 45,
            other: 58,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
      ],
    },
    {
      month: '2023-12-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Sendor A0',
          demands: {
            failure: 102,
            maintenance: 49,
            other: 58,
          },
          history: {
            last_year: 4217,
            this_year: 881,
            last_year_same_month: 254,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 42,
            other: 52,
          },
          history: {
            last_year: 1906,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 45,
            other: 55,
          },
          history: {
            last_year: 1906,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 65,
            other: 85,
          },
          history: {
            last_year: 1906,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
      ],
    },
    {
      month: '2024-01-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Sendor A0',
          demands: {
            failure: 162,
            maintenance: 66,
            other: 89,
          },
          history: {
            last_year: 667,
            this_year: 881,
            last_year_same_month: 254,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 19,
            other: 90,
          },
          history: {
            last_year: 806,
            this_year: 435,
            last_year_same_month: 178,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 28,
            other: 96,
          },
          history: {
            last_year: 806,
            this_year: 435,
            last_year_same_month: 178,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 38,
            other: 86,
          },
          history: {
            last_year: 806,
            this_year: 435,
            last_year_same_month: 178,
          },
        },
      ],
    },
    {
      month: '2024-02-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Sendor A0',
          demands: {
            failure: 162,
            maintenance: 36,
            other: 60,
          },
          history: {
            last_year: 467,
            this_year: 81,
            last_year_same_month: 254,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Sendor A0',
          demands: {
            failure: 162,
            maintenance: 39,
            other: 20,
          },
          history: {
            last_year: 467,
            this_year: 81,
            last_year_same_month: 254,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Sendor A0',
          demands: {
            failure: 162,
            maintenance: 58,
            other: 16,
          },
          history: {
            last_year: 467,
            this_year: 81,
            last_year_same_month: 254,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A1',
          demands: {
            failure: 35,
            maintenance: 58,
            other: 17,
          },
          history: {
            last_year: 186,
            this_year: 43,
            last_year_same_month: 25,
          },
        },
      ],
    },
    {
      month: '2024-03-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Gear A71',
          demands: {
            failure: 162,
            maintenance: 54,
            other: 19,
          },
          history: {
            last_year: 267,
            this_year: 881,
            last_year_same_month: 24,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Gear A71',
          demands: {
            failure: 162,
            maintenance: 52,
            other: 93,
          },
          history: {
            last_year: 267,
            this_year: 881,
            last_year_same_month: 24,
          },
        },
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Speed Gear A71',
          demands: {
            failure: 162,
            maintenance: 71,
            other: 95,
          },
          history: {
            last_year: 267,
            this_year: 881,
            last_year_same_month: 24,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Gear A71',
          demands: {
            failure: 35,
            maintenance: 72,
            other: 67,
          },
          history: {
            last_year: 1806,
            this_year: 435,
            last_year_same_month: 125,
          },
        },
      ],
    },
    {
      month: '2024-04-01',
      demands: [
        {
          part_id: 'A0C52DBF06B748939013D8FB4A76D8AD',
          part_name: 'Blade A0',
          demands: {
            failure: 152,
            maintenance: 76,
            other: 43,
          },
          history: {
            last_year: 427,
            this_year: 831,
            last_year_same_month: 154,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A31',
          demands: {
            failure: 35,
            maintenance: 78,
            other: 34,
          },
          history: {
            last_year: 1206,
            this_year: 485,
            last_year_same_month: 125,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A31',
          demands: {
            failure: 35,
            maintenance: 73,
            other: 32,
          },
          history: {
            last_year: 1206,
            this_year: 485,
            last_year_same_month: 125,
          },
        },
        {
          part_id: '8A3E93200EE6483AA8B9449F2B8EFA92',
          part_name: 'Speed Sendor A41',
          demands: {
            failure: 35,
            maintenance: 71,
            other: 37,
          },
          history: {
            last_year: 1206,
            this_year: 485,
            last_year_same_month: 125,
          },
        },
      ],
    },
  ],
  actual_demand_current_month: {
    month: '2023-05-01',
    summary: {
      failure: 490,
      maintenance: 120,
      other: 130,
    },
    details: [
      {
        part_id: 'E14BAAA3F6174D70A',
        part_name: 'Rotor Shaft A1',
        demands: { failure: 38, maintenance: 0, other: 0 },
        history: {
          last_year: 4350,
          this_year: 1292,
          last_year_same_month: 288,
        },
      },
      {
        part_id: 'E5BB3BFE6124324274',
        part_name: 'Rotor Shaft A2',
        demands: { failure: 30, maintenance: 0, other: 0 },
        history: { last_year: 1648, this_year: 475, last_year_same_month: 129 },
      },
      {
        part_id: '24322C4F3AB949D147AC1',
        part_name: 'Rotor Shaft A3',
        demands: { failure: 26, maintenance: 0, other: 0 },
        history: { last_year: 1599, this_year: 500, last_year_same_month: 121 },
      },
      {
        part_id: 'A0C52DBF06B74A76D8AD',
        part_name: 'Rotor Shaft A4',
        demands: { failure: 21, maintenance: 0, other: 0 },
        history: {
          last_year: 4267,
          this_year: 1096,
          last_year_same_month: 356,
        },
      },
      {
        part_id: '42561832594644CE27',
        part_name: 'Rotor Shaft A5',
        demands: { failure: 20, maintenance: 0, other: 0 },
        history: { last_year: 3142, this_year: 986, last_year_same_month: 208 },
      },
    ],
  },
};

const getTrendingParts = (): TrendingPartsData => {
  return trendingPartsSeed;
};

export default getTrendingParts;
