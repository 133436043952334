import { useCallback, useEffect } from 'react';
import { AnalyticsEventType, NULL_SELECTION } from '@insightloop/common-ui';

import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import useSelectChart, {
  SelectChartProps,
  SelectedChartData,
} from '../../hooks/UseSelectChart';
import {
  clearEquipmentTypeFilter,
  updateFilters,
} from '../../store/slices/filters.slice';
import { addEquipmentTypeToOptions } from '../../store/slices/search.slice';
import { BarChartData } from '../../types/charts';
import { sendAnalytics } from '../../utils/sendAnalytics';

const useEquipmentChartFilter = (
  data: BarChartData,
): SelectChartProps<number> => {
  const dispatch = useAppDispatch();
  const equipmentTypeFilter = useAppSelector(
    (state) => state.filters.dashboard.equipmentType,
  );

  const handleSelect = useCallback(
    (data: SelectedChartData<number>) => {
      if (data === null) {
        dispatch(clearEquipmentTypeFilter({ shouldClearInput: true }));
        sendAnalytics({
          name: AnalyticsEventType.CHART_FILTER_EQUIPMENT_TYPE,
          attributes: { equipmentType: NULL_SELECTION },
        });
      } else {
        // data.id is the same as the equipment name
        const equipmentType = data.id;
        sendAnalytics({
          name: AnalyticsEventType.CHART_FILTER_EQUIPMENT_TYPE,
          attributes: { equipmentType },
        });
        dispatch(updateFilters({ equipmentType }));
        dispatch(addEquipmentTypeToOptions(equipmentType));
      }
    },
    [dispatch],
  );
  const { activeChartIndex, handleChartClicked, setIndex } =
    useSelectChart<number>({
      handleSelect,
    });

  useEffect(() => {
    if (data === null) {
      return;
    }
    const barIsSelected = activeChartIndex !== null;
    // Removing bar selection when filter is cleared
    if (barIsSelected && equipmentTypeFilter === null) {
      setIndex(null);
      return;
    }
    const selectedEquipmentTypeIndex = data.findIndex(
      (e) => e.id === equipmentTypeFilter,
    );
    // Updating bar selection when filter changes, if the value is present on the chart
    if (
      equipmentTypeFilter !== null &&
      selectedEquipmentTypeIndex !== -1 &&
      selectedEquipmentTypeIndex !== activeChartIndex
    ) {
      setIndex(selectedEquipmentTypeIndex);
    }
    // Removing bar selection when the filter value is not present on the chart
    if (barIsSelected && selectedEquipmentTypeIndex === -1) {
      setIndex(null);
    }
  }, [activeChartIndex, equipmentTypeFilter, data]);

  return {
    activeChartIndex,
    handleChartClicked,
    setIndex,
  };
};

export default useEquipmentChartFilter;
