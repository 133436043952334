import assertNever from 'assert-never';

import { TimeRange } from '../../../types/common';
import { MeanTimeToResolutionMetrics } from '../../../integration/mttr.api';
import { FirstTimeFixedRateMetric } from '../../../integration/ftfr.api';
import { MeanTimeBetweenVisitsMetric } from '../../../integration/mtbv.api';

export const MINUTES_PER_DAY = 1440;

export interface KpiChartDataPoint {
  name: string;
  value: number;
}

/*
The backend sends the date back in the wrong format. Chrome and Edge parses
the date correctly when calling the Date constructor, but Firefox and Safari
does not, leaving the result undefined, which breaks the chart and/or the
tooltips.

This function is a temporary fix, it transforms the bad date format into ISO 8601.
 */
const parseDate = (date: string): Date => {
  return new Date(date.replace(/^(\S+)\s(\S+)\s([a-zA-Z]+.*)$/, '$1T$2Z'));
};

export const getMttrDataset = (
  dataPoints: MeanTimeToResolutionMetrics[],
  timeRange: TimeRange,
  months: string[],
  monthFirst: boolean,
): KpiChartDataPoint[] => {
  switch (timeRange) {
    case TimeRange.ONE_YEAR:
      return dataPoints.map(({ mean_time_to_res, date_interval }) => ({
        name: `${months[parseDate(date_interval).getUTCMonth()]} ${parseDate(
          date_interval,
        ).getUTCFullYear()}`,
        value: Number((Number(mean_time_to_res) / MINUTES_PER_DAY).toFixed(0)),
      }));
    case TimeRange.SIX_MONTHS:
      return dataPoints.map(({ mean_time_to_res, date_interval }) => ({
        name: months[parseDate(date_interval).getUTCMonth()],
        value: Number((Number(mean_time_to_res) / MINUTES_PER_DAY).toFixed(0)),
      }));
    case TimeRange.ONE_MONTH:
    case TimeRange.ONE_WEEK:
      return dataPoints.map(({ mean_time_to_res, date_interval }) => {
        const name = monthFirst
          ? `${months[parseDate(date_interval).getUTCMonth()]} ${parseDate(
              date_interval,
            ).getUTCDate()}`
          : `${parseDate(date_interval).getUTCDate()} ${
              months[parseDate(date_interval).getUTCMonth()]
            }`;

        return {
          name,
          value: Number(
            (Number(mean_time_to_res) / MINUTES_PER_DAY).toFixed(2),
          ),
        };
      });
    default:
      assertNever(timeRange);
  }
};

export const getFtfrDataset = (
  dataPoints: FirstTimeFixedRateMetric[],
  timeRange: TimeRange,
  months: string[],
  monthFirst: boolean,
): KpiChartDataPoint[] => {
  switch (timeRange) {
    case TimeRange.ONE_YEAR:
      return dataPoints.map(({ rate, date_interval }) => ({
        name: `${months[parseDate(date_interval).getMonth()]} ${parseDate(
          date_interval,
        ).getUTCFullYear()}`,
        value: Number((Number(rate) * 100).toFixed(1)),
      }));
    case TimeRange.SIX_MONTHS:
      return dataPoints.map(({ rate, date_interval }) => ({
        name: months[parseDate(date_interval).getMonth()],
        value: Number((Number(rate) * 100).toFixed(1)),
      }));
    case TimeRange.ONE_MONTH:
    case TimeRange.ONE_WEEK:
      return dataPoints.map(({ rate, date_interval }) => {
        const name = monthFirst
          ? `${months[parseDate(date_interval).getMonth()]} ${parseDate(
              date_interval,
            ).getDate()}`
          : `${parseDate(date_interval).getDate()} ${
              months[parseDate(date_interval).getMonth()]
            }`;

        return {
          name,
          value: Number((Number(rate) * 100).toFixed(1)),
        };
      });
    default:
      assertNever(timeRange);
  }
};

export const getMtbvDataset = (
  dataPoints: MeanTimeBetweenVisitsMetric[],
  timeRange: TimeRange,
  months: string[],
  monthFirst: boolean,
): KpiChartDataPoint[] => {
  switch (timeRange) {
    case TimeRange.ONE_YEAR:
      return dataPoints.map(({ mean_time_between_visits, date_interval }) => ({
        name: `${months[parseDate(date_interval).getUTCMonth()]} ${parseDate(
          date_interval,
        ).getUTCFullYear()}`,
        value: Number(Number(mean_time_between_visits).toFixed(0)),
      }));
    case TimeRange.SIX_MONTHS:
      return dataPoints.map(({ mean_time_between_visits, date_interval }) => ({
        name: months[parseDate(date_interval).getUTCMonth()],
        value: Number(Number(mean_time_between_visits).toFixed(0)),
      }));
    case TimeRange.ONE_MONTH:
    case TimeRange.ONE_WEEK:
      return dataPoints.map(({ mean_time_between_visits, date_interval }) => {
        const name = monthFirst
          ? `${months[parseDate(date_interval).getUTCMonth()]} ${parseDate(
              date_interval,
            ).getUTCDate()}`
          : `${parseDate(date_interval).getUTCDate()} ${
              months[parseDate(date_interval).getUTCMonth()]
            }`;

        return {
          name,
          value: Number(Number(mean_time_between_visits).toFixed(2)),
        };
      });
    default:
      assertNever(timeRange);
  }
};
