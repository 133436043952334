import { JobStatus } from '@insightloop/common-ui';

import api, { ApiFetchParams, ApiResponse } from './api';
import { TimeRange } from '../types/common';

export const ROOT_CAUSE_THRESHOLD = 0.1;

export interface Address {
  city: string | null;
  country: string | null;
  street: string | null;
  streetNumber: string | null;
  zip: string | null;
}

export interface OpenJob {
  id: string;
  customerId: string;
  equipment: string;
  address: Address | null;
  reportedSymptom: string;
  equipmentSerial: string | null;
  code: string;
}

export interface Prediction {
  jobId: string;
  prediction: string;
}

export interface OpenJobWithPrediction extends OpenJob {
  prediction: string | null;
}

export interface OpenJobsResponse {
  data: OpenJob[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface PredictionsResponseData {
  predictions: Prediction[];
}

export type AllPredictionsResponse = ApiResponse<PredictionsResponseData>;

interface FetchOpenJobsParams extends ApiFetchParams {
  page: number;
  size: number;
  sort: string;
  country: string | null;
  equipmentType: string | null;
  timeRange: TimeRange;
  customerId: string;
}

export interface OpenJobsData {
  jobs: OpenJobWithPrediction[];
  totalJobs: number;
}

export interface JobSymptoms {
  id_: string;
  job_id: string;
  symptoms: string[];
  language: string;
}

export interface SymptomsResponseData {
  symptoms: JobSymptoms[];
}

export type SymptomsResponse = ApiResponse<SymptomsResponseData>;

export const fetchOpenJobs = async ({
  page,
  size,
  sort,
  country,
  equipmentType,
  timeRange,
  customerId,
  signal,
  mock,
}: FetchOpenJobsParams): Promise<OpenJobsData> => {
  const openJobsResponse = await api.get<OpenJobsResponse>({
    mock,
    path: '/domain-model/api/v1/jobs',
    queryParams: {
      page,
      size,
      sort,
      country,
      equipmentType,
      timeRange,
      customerId,
      jobStatus: JobStatus.Open,
      threshold: ROOT_CAUSE_THRESHOLD,
    },
    config: { signal },
  });
  if (openJobsResponse.data.length === 0) {
    return {
      totalJobs: 0,
      jobs: [],
    };
  }
  const jobIds = openJobsResponse.data.map((job) => job.id);

  const [
    {
      data: { predictions },
    },
    {
      data: { symptoms: jobSymptoms },
    },
  ] = await Promise.all([
    api.get<AllPredictionsResponse>({
      mock,
      path: '/fault/api/v2/predictions',
      queryParams: { jobIds },
      config: { signal },
    }),
    api.get<SymptomsResponse>({
      mock,
      path: '/fault/api/v1/symptoms',
      queryParams: { jobIds },
      config: { signal },
    }),
  ]);
  const predictionsPerJobs = new Map(
    predictions.map((p) => [p.jobId, p.prediction]),
  );
  const symptomsPerJobs = new Map(
    jobSymptoms.map((js) => [
      js.job_id,
      js.symptoms.length === 0 ? null : js.symptoms.join(', '),
    ]),
  );

  return {
    totalJobs: openJobsResponse.totalElements,
    jobs: openJobsResponse.data.map((job) => ({
      ...job,
      prediction: predictionsPerJobs.get(job.id) || null,
      reportedSymptom: symptomsPerJobs.get(job.id) || job.reportedSymptom,
    })),
  };
};
