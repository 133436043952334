import { useDarkModeSelected } from '../store/slices/theme.slice';

export const getMaintenanceCellColor = (isActive: boolean) => {
  const isDarkMode = useDarkModeSelected();
  if (isActive) {
    return isDarkMode ? '#ffffff' : '#000000';
  } else {
    return isDarkMode ? 'rgb(255, 255, 255, 0.1)' : 'rgb(0, 0, 0, 0.1)';
  }
};

export const getFailureCellColor = (isActive: boolean) => {
  if (isActive) {
    return '#00BDC7';
  } else {
    return 'rgb(0, 189, 199,0.1)';
  }
};

export const getOtherCellColor = (isActive: boolean) => {
  if (isActive) {
    return ' #949494 ';
  } else {
    return 'rgb(148, 148, 148, 0.1)';
  }
};

export const getChartOpacity = (selectedMonth: string | null) => {
  if (selectedMonth === null) {
    return 1;
  } else {
    return 0.1;
  }
};
