import api, { ApiFetchParams, ApiResponse } from './api';

type EquipmentTypesResponse = ApiResponse<{ equipment_types: string[] }>;

interface FetchEquipmentTypesParams extends ApiFetchParams {
  searchTerm: string;
  country: string | null;
  customerId: string | null;
}

export const fetchEquipmentTypes = async ({
  searchTerm,
  country,
  customerId,
  signal,
  mock,
}: FetchEquipmentTypesParams): Promise<string[]> => {
  const { data } = await api.get<EquipmentTypesResponse>({
    mock,
    path: '/domain-model/api/v1/equipments/actions/search',
    queryParams: {
      searchTerm,
      customerId,
      countryCode: country,
    },
    config: { signal },
  });

  return data.equipment_types;
};
