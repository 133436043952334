import { SelectChartProps } from '../../../hooks/UseSelectChart';
import { isDefined } from '../../../utils/isDefined';
import { CustomAxisTickProps } from '../customAxisTick/CustomAxisTick';

export const getAxisTickOpacity = (
  selectChartProps: SelectChartProps<number> | undefined,
  tickProps: CustomAxisTickProps,
) => {
  if (isDefined(selectChartProps)) {
    return selectChartProps.activeChartIndex === null ||
      tickProps.index === selectChartProps.activeChartIndex
      ? 1
      : 0.1;
  } else {
    return 1;
  }
};
