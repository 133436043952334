import {
  WorkflowBottleneckData,
  WorkflowBottleneckStep,
} from '../../../../integration/workflowBottleneck.api';

export const getStepByName = (
  name: string,
  data: WorkflowBottleneckData | null,
): WorkflowBottleneckStep | null => {
  if (data) {
    const matchingObject = data.steps.find((obj) => obj.name === name);
    return matchingObject ? matchingObject : null;
  }
  return null;
};
