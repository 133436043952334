import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { FetchingStatus } from '../../../types/common';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import AppChartContainer from '../../charts/chartContainer/AppChartContainer';
import JobTypesChartTooltip from '../../charts/jobTypesChartTooltip/JobTypesChartTooltip';
import styles from './TrendingCustomersChart.module.scss';
import useCustomerChartFilter from '../UseCustomerChartFilter';
import {
  getMaintenanceCellColor,
  getFailureCellColor,
  getOtherCellColor,
} from '../../../utils/getChartColor';
import { TrendingCustomersMetric } from '../../../integration/trendingCustomers.api';
import { getLongestLabelWidthPx } from '../../../utils/measurements';
import AxisArrow from '../../charts/axisArrow/AxisArrow';
import CustomBarChartLabel from '../../charts/customBarChartLabel/CustomBarChartLabel';
import CustomAxisTick from '../../charts/customAxisTick/CustomAxisTick';
import { isDefined } from '../../../utils/isDefined';

const BAR_HEIGHT_PX = 18;

export interface TrendingCustomerDataPoint {
  id: string;
  name: string;
  totalCount: number;
  Maintenance?: number;
  Failure?: number;
  Other?: number;
}

interface Props {
  data: TrendingCustomersMetric[] | null;
  status: FetchingStatus;
  testId: string;
  chartLabel: string;
}

const TrendingCustomersChart: React.FC<Props> = ({
  data,
  status,
  testId,
  chartLabel,
}) => {
  const theme = useTheme();
  const textColor = theme.palette.primary.light;

  const dataPoints: TrendingCustomerDataPoint[] | null = useMemo(() => {
    if (data === null) {
      return null;
    }

    return data.map((customer) => ({
      id: customer.customerId,
      name: customer.name,
      totalCount: customer.totalCount,
      maintenance: isDefined(customer.Maintenance) ? customer.Maintenance : 0,
      failure: isDefined(customer.Failure) ? customer.Failure : 0,
      other: isDefined(customer.Other) ? customer.Other : 0,
    }));
  }, [data]);

  const longestLabelLength = useMemo(() => {
    if (data === null) {
      return 0;
    }
    return getLongestLabelWidthPx(data.map((d) => d.name));
  }, [data]);

  const selectChartProps = useCustomerChartFilter(dataPoints);
  if (status === FetchingStatus.PENDING) {
    return <LoadingOverlay />;
  }

  return (
    dataPoints && (
      <div className={styles.container} data-testid={testId}>
        <AppChartContainer>
          {({ width, height }) => (
            <BarChart
              data={dataPoints}
              layout="vertical"
              width={width}
              height={height}
              barSize={
                dataPoints.length > 3 ? BAR_HEIGHT_PX : BAR_HEIGHT_PX * 2
              }
            >
              <defs>
                <AxisArrow color={textColor} />
              </defs>
              {dataPoints.slice(0, -1).map((dataPoint, index) => (
                <ReferenceLine
                  key={index}
                  position="end"
                  y={dataPoint.name}
                  stroke={textColor}
                  strokeDasharray="3 3"
                />
              ))}
              <XAxis
                type="number"
                tick={false}
                axisLine={{
                  stroke: textColor,
                  strokeWidth: 1,
                  markerEnd: 'url(#arrow)',
                }}
                label={{
                  value: chartLabel,
                  position: 'insideBottomRight',
                  offset: 0,
                }}
              />
              <YAxis
                type="category"
                dataKey="name"
                width={longestLabelLength}
                tick={(tickProps) => {
                  return (
                    <CustomAxisTick
                      {...tickProps}
                      tickProps={tickProps}
                      selectChartProps={selectChartProps}
                      textColor={textColor}
                    />
                  );
                }}
                axisLine={{
                  stroke: textColor,
                  strokeWidth: 1,
                }}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                content={<JobTypesChartTooltip />}
              />
              <Bar
                dataKey="maintenance"
                stackId="a"
                cursor="pointer"
                onClick={selectChartProps.handleChartClicked}
              >
                {dataPoints.map((dataPoint, index) => (
                  <Cell
                    key={dataPoint.id}
                    fill={getMaintenanceCellColor(
                      selectChartProps.activeChartIndex === null ||
                        index === selectChartProps.activeChartIndex,
                    )}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="failure"
                stackId="a"
                cursor="pointer"
                onClick={selectChartProps.handleChartClicked}
              >
                {dataPoints.map((dataPoint, index) => (
                  <Cell
                    key={dataPoint.id}
                    fill={getFailureCellColor(
                      selectChartProps.activeChartIndex === null ||
                        index === selectChartProps.activeChartIndex,
                    )}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="other"
                stackId="a"
                label={({ value, ...customLabelProps }) => (
                  <CustomBarChartLabel
                    {...customLabelProps}
                    fillColor={textColor}
                    value={value}
                    isActive={
                      selectChartProps.activeChartIndex === null ||
                      customLabelProps.index ===
                        selectChartProps.activeChartIndex
                    }
                  />
                )}
                cursor="pointer"
                onClick={selectChartProps.handleChartClicked}
              >
                {dataPoints.map((dataPoint, index) => (
                  <Cell
                    key={dataPoint.id}
                    fill={getOtherCellColor(
                      selectChartProps.activeChartIndex === null ||
                        index === selectChartProps.activeChartIndex,
                    )}
                  />
                ))}
              </Bar>
            </BarChart>
          )}
        </AppChartContainer>
      </div>
    )
  );
};

export default TrendingCustomersChart;
