const SECONDS_IN_A_DAY = 86400;

export const convertSecondsToDays = (
  seconds: number,
  digits: number,
): number => {
  const days = seconds / SECONDS_IN_A_DAY;

  return Number(days.toFixed(digits));
};
