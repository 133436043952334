import { TimeRange } from '../../types/common';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { GetSeedParams } from '../test-utils';
import { TrendingEquipmentFailureMetric } from '../../integration/trendingEquipment.api';

const topEquipFailureSeed: TrendingEquipmentFailureMetric[] = [
  {
    name: 'WT 8.0-167 DD',
    failures_per_day: '250',
  },
  {
    name: 'WT 14-236 DD',
    failures_per_day: '180',
  },
  {
    name: 'WT 3.x-103',
    failures_per_day: '150',
  },
  {
    name: 'WT 3.x-137',
    failures_per_day: '95',
  },
  {
    name: 'WT 2-B Energy 2B6',
    failures_per_day: '25',
  },
];

const topEquipFailureSeedForCountry: TrendingEquipmentFailureMetric[] = [
  {
    name: 'WT 8.0-167 DD',
    failures_per_day: '82',
  },
  {
    name: 'WT 14-236 DD',
    failures_per_day: '60',
  },
  {
    name: 'WT 3.x-103',
    failures_per_day: '50',
  },
  {
    name: 'WT 3.x-137',
    failures_per_day: '30',
  },
  {
    name: 'WT 2-B Energy 2B6',
    failures_per_day: '11',
  },
];

const topEquipFailureSeedForCustomer: TrendingEquipmentFailureMetric[] = [
  {
    name: 'WT 8.0-167 DD',
    failures_per_day: '75',
  },
  {
    name: 'WT 14-236 DD',
    failures_per_day: '45',
  },
  {
    name: 'WT 3.x-103',
    failures_per_day: '40',
  },
  {
    name: 'WT 3.x-137',
    failures_per_day: '30',
  },
  {
    name: 'WT 2-B Energy 2B6',
    failures_per_day: '20',
  },
];

const topEquipFailureSeedForSixMonth: TrendingEquipmentFailureMetric[] = [
  {
    name: 'WT 8.0-167 DD',
    failures_per_day: '125',
  },
  {
    name: 'WT 14-236 DD',
    failures_per_day: '90',
  },
  {
    name: 'WT 3.x-103',
    failures_per_day: '75',
  },
  {
    name: 'WT 3.x-137',
    failures_per_day: '45',
  },
  {
    name: 'WT 2-B Energy 2B6',
    failures_per_day: '10',
  },
];

const getTrendingEquipment = ({
  timeRange,
  country,
  customer,
}: GetSeedParams): TrendingEquipmentFailureMetric[] | null => {
  if (country === countriesSeed.countries[1].code) {
    return topEquipFailureSeedForCountry;
  }

  if (customer === searchSeed.customers[0].id) {
    return topEquipFailureSeedForCustomer;
  }

  if (timeRange === TimeRange.SIX_MONTHS) {
    return topEquipFailureSeedForSixMonth;
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return [];
  }

  return topEquipFailureSeed;
};

export default getTrendingEquipment;
