import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslations } from '../../store/slices/translation.slice';

const LoadingState: React.FC = () => {
  const {
    auth: { loading },
  } = useTranslations();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', m: '20% auto' }}>
      <CircularProgress sx={{ color: '#00BDC7' }} />
      <Typography
        fontSize="16px"
        fontWeight="400"
        sx={{ m: '0.5rem!important' }}
      >
        {loading}
      </Typography>
    </Box>
  );
};
export default LoadingState;
