import React, { useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { ShellSdk } from 'fsm-shell';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import Home from './pages/home/Home';
import { useAnalytics } from './utils/aws';
import useAppTheme from './hooks/UseAppTheme';
import Feature from './components/feature/Feature';
import WithAuthentication from './components/withAuthentication/WithAuthentication';
import { azureConfig } from './components/login/azureConfig';
import './App.scss';

// This function exports the material theme color variables to our global css space as css variables.
// It can be extended if more colors are needed.
const setGlobalStyles = (theme: Theme) => (
  <GlobalStyles
    styles={{
      ':root': {
        '--color-primary': theme.palette.primary.main,
        '--color-primary-light': theme.palette.primary.light,
        '--color-primary-dark': theme.palette.primary.dark,
        '--color-secondary': theme.palette.secondary.main,
        '--color-secondary-dark': theme.palette.secondary.dark,
        '--color-secondary-light': theme.palette.secondary.light,
        '--color-success': theme.palette.success.main,
        '--color-success-light': theme.palette.success.light,
        '--color-error': theme.palette.error.main,
        '--color-error-light': theme.palette.error.light,
        '--color-warning': theme.palette.warning.main,
        '--color-info': theme.palette.info.main,
        '--color-info-dark': theme.palette.info.dark,
        '--color-background': theme.palette.background.default,
        '--color-background-paper': theme.palette.background.paper,
        '--color-light-grey': theme.palette.grey.A100,
        '--color-dark-grey': theme.palette.grey.A700,
      },
    }}
  />
);

const App: React.FC = () => {
  const theme = useAppTheme();
  const isInsideFSMShell = ShellSdk.isInsideShell();
  const msalInstance = useMemo(() => {
    if (isInsideFSMShell) {
      return null;
    }

    return new PublicClientApplication(azureConfig);
  }, [isInsideFSMShell]);
  useAnalytics();

  if (isInsideFSMShell) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {setGlobalStyles(theme)}
          <div id="smart-loop-ruler" />
          <Feature>
            <WithAuthentication>
              <Router>
                <Routes>
                  <Route path="/" element={<Home />} />
                </Routes>
              </Router>
            </WithAuthentication>
          </Feature>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {setGlobalStyles(theme)}
        <div id="smart-loop-ruler" />
        <Feature>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <MsalProvider instance={msalInstance!}>
            <WithAuthentication>
              <Router>
                <Routes>
                  <Route path="/" element={<Home />} />
                </Routes>
              </Router>
            </WithAuthentication>
          </MsalProvider>
        </Feature>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
