import { PredictionQualitySummary } from '../../integration/predictionQuality.api';

export const rcPredictionQualitySeed: PredictionQualitySummary = {
  accuracy: 65,
  trend: 0.042,
  highConfidence: 111,
  mediumConfidence: 55,
  lowConfidence: 6,
  total: 172,
  bestPredictions: [
    {
      id: 'rcpq1',
      jobDiagnosis:
        'Fernbedienung fehlerhaft. Mann muss 3-4 die große Taste drücken damit er reagiert',
      predictedLabels: ['Fernbedienung'],
      trueLabels: ['Fernbedienung'],
      performance: 1,
    },
  ],
  worstPredictions: [
    {
      id: 'rcpq2',
      jobDiagnosis:
        'Undichtigkeit zwischen Keramik und Kunststoffstutzen an der Klebenaht, Boiler verkalkt und undicht, Behälter Düsenreiniger ausgetrocknet',
      predictedLabels: ['Keramik', 'boiler', 'klebestelle'],
      trueLabels: ['Keramik', 'kunststoffstutzen', 'boiler', 'behälter'],
      performance: 0.5,
    },
  ],
};

export const partPredictionQualitySeed: PredictionQualitySummary = {
  accuracy: 73,
  trend: -0.051,
  highConfidence: 103,
  mediumConfidence: 30,
  lowConfidence: 8,
  total: 141,
  bestPredictions: [
    {
      id: 'ppq1',
      jobDiagnosis: '',
      predictedLabels: [
        'Einsatzpauschale "Bad-Keramik & Möbel" - Erstprodukt',
        'iCon WC-Sitz mit Absenkautomatik, Quick Release, weiß',
        'Set Puffer für WC-Sitz Service Kit, +/- 2mm, je 2 St.',
      ],
      trueLabels: [
        'Einsatzpauschale "Bad-Keramik & Möbel" - Erstprodukt',
        'iCon WC-Sitz mit Absenkautomatik, Quick Release, weiß',
      ],
      performance: 1,
    },
  ],
  worstPredictions: [
    {
      id: 'ppq2',
      jobDiagnosis: '',
      predictedLabels: [
        'Einsatzpauschale AquaClean',
        'Dienstleistung ohne Materialaufwand',
        'Benutzererkennung zu Geberit AquaClean Mera',
      ],
      trueLabels: [
        'Fernbedienung zu Geberit AquaClean Mera Comfort',
        'Einsatzpauschale AquaClean',
      ],
      performance: 0.5,
    },
  ],
};
