import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx';
import { AnalyticsEventType, NULL_SELECTION } from '@insightloop/common-ui';

import { getCountries } from '../../../../store/slices/countries.slice';
import {
  clearCountryFilter,
  updateFilters,
} from '../../../../store/slices/filters.slice';
import { FetchingStatus } from '../../../../types/common';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { useTranslations } from '../../../../store/slices/translation.slice';
import { useMockServerActive } from '../../../../store/slices/mockServer.slice';
import GlobeSvg from '../icons/GlobeSvg';
import { Country } from '../../../../integration/countries.api';
import { getCountryName } from '../utils/getCountryName';
import { sendAnalytics } from '../../../../utils/sendAnalytics';
import styles from './Filters.module.scss';

const isTestEnv = process.env.NODE_ENV === 'test';

const CountriesFilter: React.FC = () => {
  const dispatch = useAppDispatch();

  const { countries, status } = useAppSelector((state) => state.countries);
  const {
    filters: { countryFilter: placeholder },
    loading: loadingText,
    noOptions: noOptionsText,
  } = useTranslations();
  const isMockServerActive = useMockServerActive();
  const loading = status === FetchingStatus.PENDING;
  const { country, customer, equipmentType, shouldClearCountryInput } =
    useAppSelector((state) => state.filters.dashboard);
  const countryFilter = useAppSelector(
    (state) => state.filters.dashboard.country,
  );
  const countrySelected = countryFilter !== null;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const promise = dispatch(getCountries());

    return () => {
      promise.abort();
    };
  }, [dispatch, customer, equipmentType, isMockServerActive]);

  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(value);
      dispatch(clearCountryFilter({ shouldClearInput: false }));
    },
    [dispatch],
  );

  const handleCountryChange = (
    event: React.SyntheticEvent,
    country: Country | null,
  ) => {
    const countryCode = country !== null ? country.code : null;
    dispatch(updateFilters({ country: countryCode }));
    sendAnalytics({
      name: AnalyticsEventType.FILTER_COUNTRY,
      attributes: { country: countryCode || NULL_SELECTION },
    });
  };

  useEffect(() => {
    if (country === null && shouldClearCountryInput) {
      setInputValue('');
    } else if (country !== null) {
      setInputValue(getCountryName(country, countries));
    }
  }, [country, shouldClearCountryInput]);

  return (
    <Autocomplete
      data-testid="country-filter"
      autoHighlight={!isTestEnv}
      popupIcon={<KeyboardArrowDownIcon />}
      onChange={handleCountryChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      className={clsx(
        styles.appAutocomplete,
        countrySelected && styles.optionSelected,
      )}
      size="small"
      options={countries}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => option.name}
      renderOption={(props, country) => {
        return (
          <Box
            component="li"
            {...props}
            sx={{ color: (theme) => theme.palette.secondary.light }}
            key={country.code}
          >
            <Typography className={styles.list}>{country.name}</Typography>
          </Box>
        );
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <GlobeSvg isSelected={countrySelected} />
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};
export default CountriesFilter;
