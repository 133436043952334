import { TimeRange } from '../../types/common';
import english from './english';
import german from './german';
import { ReactNode } from 'react';

export enum SupportedLanguage {
  ENGLISH = 'en',
  GERMAN = 'de',
}

export const supportedLanguages: Record<SupportedLanguage, Translations> = {
  [SupportedLanguage.ENGLISH]: english,
  [SupportedLanguage.GERMAN]: german,
};

export type TimeLabels = Record<TimeRange, string>;

type LabelCreator<T> = (params: T) => string;
type LabelWithTimeRange = LabelCreator<{ periodLabel: string }>;
type EquipmentLabelCreator = LabelCreator<{ equipmentType: string | null }>;

interface MetricBoxTranslation {
  title: string;
  tooltipSummary: string;
  tooltipDetails: string;
}

interface KpisGlanceTooltip {
  id: string;
  definition: string;
  description: string;
}

interface WorkflowKpiDetails {
  title: string;
  description: string;
}
export interface WorkflowKpi {
  id: string;
  title: string;
  tooltip: string;
}

export interface Translations {
  loading: string;
  noOptions: string;
  timeRanges: TimeLabels;
  durationInDays: LabelCreator<number>;
  noData: JSX.Element;
  errorCardText: JSX.Element;
  months: string[];
  monthsFull: string[];
  monthsMap: { [key: string]: string };
  timeUnitLabels: {
    hours: string;
    day: string;
    days: string;
  };
  auth: {
    login: string;
    email: string;
    password: string;
    sessionExpired: string;
    or: string;
    loading: string;
    marketingMessage: string;
    pilotDescription: JSX.Element;
    unauthorized: string;
    invalidCredentials: string;
    azure: {
      loginButtonText: string;
      tooltipLabel: string;
      tooltipContent: string;
    };
  };
  timeSpan: TimeLabels;
  filters: {
    countryFilter: string;
    customerFilter: string;
    equipmentTypeFilter: string;
    time: TimeLabels;
  };
  kpiCharts: {
    mainTitle: string;
    tooltip: string;
    metrics: {
      tooltipContent: KpisGlanceTooltip[];
      firstTimeFixRate: string;
      meanTimeToResolution: string;
      meanTimeBetweenVisits: string;
      ratings: MetricBoxTranslation;
    };
  };
  chartLabels: {
    numOfJobs: string;
    numOfParts: string;
    occurrences: string;
  };
  trendingEquipment: {
    mainTitle: string;
    tooltip: string;
  };
  trendingSymptoms: {
    mainTitle: string;
    tooltip: string;
    viewAllBtn: string;
  };
  symptomsDialog: {
    searchPlaceholder: EquipmentLabelCreator;
    symptomsChartTitle: string;
    numberOfSymptoms: (count: number) => ReactNode;
    noResults: string;
    predictionsTitle: string;
    selectSymptomPrompt: string;
    partName: string;
    probability: string;
    rootCause: string;
  };
  trendingParts: {
    mainTitle: string;
    tooltip: string;
    monthChartTitle: LabelCreator<{ month: string }>;
    selectPartPrompt: string;
    maintenance: string;
    failure: string;
    other: string;
    total: string;
    partHistory: {
      lastYear: LabelCreator<{ year: number }>;
      currentYear: LabelCreator<{ year: number }>;
      lastMonth: LabelCreator<{ month: string }>;
    };
    chartLabels: {
      currentMonth: string;
      usedVsPredicted: string;
    };
  };
  trendingCustomers: {
    mainTitle: string;
    typeSummaryTitle: string;
    pieChartTitle: string;
    pieChartTooltip: string;
    tooltip: string;
  };
  openJobs: {
    title: string;
    tooltip: string;
    tooltipDetailsTitle: string;
    tooltipDetails: string;
    equipment: string;
    location: string;
    jobNumber: string;
    serialNumber: string;
    issue: string;
    rootCauses: string;
    reportIssue: string;
    jobsPerPage: string;
    noSerialNumber: string;
    noAddress: string;
    noPrediction: string;
  };
  failureRate: {
    mainTitle: EquipmentLabelCreator;
    failureRateText: string;
    reliability: string;
    inTheField: string;
    locationsImpacted: string;
    withIssues: string;
    changesThisPeriod: LabelWithTimeRange;
    tooltip: string;
  };
  documentUpload: {
    tooltip: string;
    documents: string;
    clickToUpload: string;
    dragAndDrop: string;
    seeAll: string;
    noFilesMessage: string;
    uploadFailure: string;
    dialog: {
      fileName: string;
      fileSize: string;
      date: string;
      sortBy: string;
    };
  };
  openJobsDialog: {
    similarRootCauses: string;
    contactPerson: string;
    moreInfo: string;
    moreInfoDescription: string;
    email: string;
    number: string;
  };
  workflowBottleneck: {
    mainTitle: string;
    tooltipDescription: {
      introduction: string;
      kpiDetails: WorkflowKpiDetails[];
    };
    stepDuration: string;
    overallScore: string;
    kpiTitle: {
      jobGeneration: string;
      scheduling: string;
      fieldWork: string;
      closing: string;
    };
    kpis: WorkflowKpi[];
  };
  predictionQuality: {
    openDialog: string;
    allPredictions: string;
    highConfidence: string;
    mediumConfidence: string;
    lowConfidence: string;
    jobDiagnosis: string;
    words: string;
    rootCauses: {
      title: string;
      predicted: string;
      used: string;
    };
    parts: {
      title: string;
      predicted: string;
      used: string;
    };
  };
  contactSupport: {
    mail: LabelCreator<string>;
    contact: string;
    title: string;
    feedbackTopics: string[];
    takeTour: string;
  };
  dashboardTour: {
    welcome: JSX.Element;
    description: string;
    startOnboarding: string;
    skipOnboarding: string;
    next: string;
    close: string;
    steps: {
      step: LabelCreator<number>;
      yourFilters: string;
      filtersDescription: string;
      kpis: string;
      kpisDescription: string;
      frequentEquipmentsAndIssues: string;
      frequentEquipmentsAndIssuesDescription: string;
      support: string;
      supportDescription: string;
    };
  };
}
