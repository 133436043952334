import React from 'react';

const AstronautSvg: React.FC = () => {
  return (
    <svg
      width="94"
      height="163"
      viewBox="0 0 94 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M77.6445 47.2803C77.6445 47.2803 81.6217 56.2768 87.547 47.5234C87.547 47.5234 88.2775 51.8191 85.8425 53.4401C83.4074 55.0611 80.3231 54.0074 80.3231 54.0074L77.888 51.657L77.6445 47.2803Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.5"
        d="M6.86719 47.2803C6.86719 47.2803 10.8444 56.2768 16.7696 47.5234C16.7696 47.5234 17.5001 51.8191 15.0651 53.4401C12.6301 55.0611 9.54572 54.0074 9.54572 54.0074L7.11069 51.657L6.86719 47.2803Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.5"
        d="M3.8624 35.2822C3.8624 35.2822 2.64489 43.3062 6.8656 46.0619L6.70326 50.0334V50.6818L6.37859 50.9249C6.37859 50.9249 2.88839 48.8986 2.07671 46.3861C1.26504 43.8735 0.615697 42.1715 1.26504 40.0642C2.07671 37.9569 3.8624 35.2822 3.8624 35.2822Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.5"
        d="M11.8161 54.6533C11.8161 54.6533 14.6569 70.9444 41.3611 65.2709L41.8481 66.5677L36.0852 67.6213C36.0852 67.6213 27.3191 68.5939 22.8549 66.9729C18.3906 65.3519 14.6569 62.6773 12.9524 60.4079C11.2479 58.1385 9.94922 54.5723 9.94922 54.5723L10.5986 54.4912L11.8161 54.6533Z"
        fill="#00BDC7"
      />
      <path
        d="M79.2653 30.1784C75.207 16.3998 62.4637 6.34961 47.3665 6.34961C32.3505 6.34961 19.526 16.4809 15.5488 30.2594"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.4375 28.8812C14.9829 12.8333 29.8365 1 47.3687 1C64.9821 1 79.8357 12.8333 84.3811 28.8812"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.8175 28.7197C9.05785 28.7197 6.78516 30.9891 6.78516 33.7448V49.6307C6.78516 52.3864 9.05785 54.6558 11.8175 54.6558C14.5772 54.6558 16.8499 52.3864 16.8499 49.6307V33.7448C16.8499 30.9891 14.6584 28.7197 11.8175 28.7197Z"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M82.6769 28.7197C79.9172 28.7197 77.6445 30.9891 77.6445 33.7448V49.6307C77.6445 52.3864 79.9172 54.6558 82.6769 54.6558C85.4366 54.6558 87.7093 52.3864 87.7093 49.6307V33.7448C87.7093 30.9891 85.4366 28.7197 82.6769 28.7197Z"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M87.5469 32.6914C90.9559 34.3124 93.2286 37.7165 93.2286 41.769C93.2286 45.7405 90.8747 49.2256 87.5469 50.8466"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M42.334 66.4886C38.1133 67.5422 34.2984 68.0285 30.8082 68.0285C25.5323 68.0285 21.0681 66.8938 17.4967 64.5434C12.1397 61.0582 10.4351 56.0331 10.0293 54.3311"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.0898 54.0879C14.658 55.7089 16.119 58.8698 19.6904 61.1392C24.6416 64.3002 31.7844 64.7865 40.8751 62.5982"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M52.5643 65.6766L45.9086 67.2976C43.717 67.8649 41.5255 66.4871 41.0385 64.3798C40.4703 62.1914 41.8502 60.003 43.9605 59.5167L50.6163 57.8957C52.8078 57.3284 54.9993 58.7062 55.4863 60.8135C56.0545 62.9208 54.7558 65.1092 52.5643 65.6766Z"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.5"
        d="M55.5652 60.8144C55.4841 60.4091 55.2406 60.0039 55.0782 59.5986C55.0782 61.4628 53.8607 63.1648 51.9939 63.5701L45.3381 65.1911C43.6336 65.5963 41.8479 64.8669 40.9551 63.408C40.9551 63.7322 40.9551 63.9753 41.0362 64.2995C41.6044 66.4879 43.7959 67.7847 45.9063 67.2173L52.562 65.5963C54.7536 65.11 56.0522 62.9217 55.5652 60.8144Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.5"
        d="M79.2674 30.0161C75.3714 16.1565 62.6281 6.34948 47.3686 6.34948C32.2714 6.34948 19.4469 16.2376 15.5509 30.0972L13.3594 28.9625C17.7424 14.3734 31.3786 3.99902 47.4497 3.99902C63.6021 3.99902 77.1571 14.2924 81.5401 28.8814L79.2674 30.0161Z"
        fill="#00BDC7"
      />
      <path
        d="M24.2369 63.1643C20.8279 59.6792 18.2305 55.3835 16.7695 50.6016"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M36.9018 71.1893C34.548 70.3788 32.2753 69.3251 30.2461 67.9473"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M77.73 50.4404C74.7268 60.1664 67.2594 67.8662 57.6816 71.1893"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.6094 32.1227C20.5054 18.8305 32.7617 9.18555 47.2095 9.18555C61.7385 9.18555 74.076 18.9116 77.8909 32.2038"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.9648 26.2044C26.3511 19.2341 36.2535 14.9385 47.2112 14.9385C58.1688 14.9385 68.0712 19.2341 75.4575 26.2044"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.3787 63.8933C25.7001 62.5154 23.1028 60.8134 20.8301 58.9492"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M73.8311 58.625C69.3669 62.4344 64.091 65.2711 58.3281 66.8921"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7441 28.8803C25.8609 22.2341 35.9257 18.1816 47.208 18.1816C58.3279 18.1816 68.3927 21.9099 76.4283 28.394"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M16.7695 32.852L16.8507 31.0689L18.5552 28.2322L23.0194 25.0712L33.5712 20.2082L43.8795 18.3441C43.8795 18.3441 54.1066 17.7767 63.522 21.1808C72.9375 24.5849 75.6972 27.908 75.6972 27.908L76.9959 29.8532L77.6452 32.9331L77.564 49.7105L76.8335 52.7093L74.3173 57.7344L71.3953 60.4901C71.3953 60.4901 62.7915 66.4878 58.652 66.812C58.652 66.812 58.5708 64.0563 57.678 62.8406C57.191 62.1922 56.3793 62.6785 55.4865 62.8406C55.0806 64.7047 53.3761 65.7584 50.7787 66.1636C48.4249 68.433 44.123 67.7846 44.123 67.4604L48.6684 66.731L53.9443 65.11C53.9443 65.11 55.6488 63.489 55.5676 61.7869C55.4865 60.0849 54.9183 59.1123 53.8631 58.626C52.8079 58.1397 51.3469 57.8965 51.3469 57.8965L45.9087 59.0312L42.3373 60.328L41.1198 62.1922L40.4705 62.8406L36.9803 63.57C34.9511 62.0301 33.49 61.949 32.9219 63.7321L29.0258 63.8942L27.0778 63.1648L21.6396 59.5986L19.2046 56.2755L17.2565 51.8988L16.9319 49.3052L16.7695 32.852Z"
        fill="#00BDC7"
      />
      <path
        d="M68.718 47.4423C71.3966 48.0096 74.2374 45.335 75.1303 41.4446C76.0231 37.5542 74.4809 33.9069 71.8836 33.3396C69.205 32.7722 66.3642 35.4469 65.4713 39.3373C64.5785 43.2277 66.0395 46.8749 68.718 47.4423Z"
        fill="white"
      />
      <path
        d="M29.2662 57.3286C31.3766 59.6791 33.5681 61.3001 32.7564 62.0295C31.9448 62.759 29.8344 61.138 27.724 58.7875C25.6137 56.4371 24.5585 54.1677 25.2079 53.6003C25.776 53.033 27.1559 54.9782 29.2662 57.3286Z"
        fill="white"
      />
      <path
        d="M66.0348 55.6265C67.0899 56.2749 68.6321 55.5454 69.525 54.0865C70.4178 52.6276 70.2555 50.9255 69.2003 50.2771C68.1451 49.6287 66.6029 50.3582 65.7101 51.8171C64.8984 53.276 64.9796 54.9781 66.0348 55.6265Z"
        fill="white"
      />
      <path
        d="M47.2053 24.3423C47.2053 24.3423 28.3744 23.6128 24.2349 33.0146C20.0953 42.4164 23.2608 50.9267 23.2608 50.9267C23.2608 50.9267 23.5855 41.687 31.4588 34.0683C39.332 26.4496 47.2053 24.3423 47.2053 24.3423Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M90.5501 34.8789C90.5501 34.8789 91.7676 42.9029 87.5469 45.6586L87.7092 49.63V50.2784L88.0339 50.5216C88.0339 50.5216 91.5241 48.4953 92.3358 45.9828C93.1474 43.4702 93.7968 41.7682 93.1474 39.6609C92.3358 37.5536 90.5501 34.8789 90.5501 34.8789Z"
        fill="#00BDC7"
      />
      <path
        d="M7.02766 51.0879C3.61862 49.4668 1.18359 46.0627 1.18359 42.0102C1.18359 38.0388 3.53745 34.5536 6.86532 32.9326"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.7129 15.6696C40.7129 15.6696 41.6057 11.2118 44.1219 9.50977"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M53.536 15.5875C53.536 15.5875 52.6431 11.1298 50.127 9.42773"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.5662 67.8662C27.5662 67.8662 18.8813 71.3514 15.0664 80.51C15.0664 80.51 7.43668 96.1527 22.128 97.6927C36.8193 99.2326 49.8873 77.4301 49.8873 77.4301"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M69.3652 63.9746C69.3652 63.9746 80.9722 71.9986 82.6767 79.9415C84.3001 87.5602 83.5696 96.6378 77.7255 103.77"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M77.7257 103.688C77.8069 105.391 77.8069 107.093 77.6445 108.795"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M77.3184 98.665C77.4807 100.367 77.643 102.069 77.643 103.771"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M71.3125 73.5371C71.3125 73.5371 76.0202 85.2894 77.4001 98.6627"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.6659 97.7734C23.5035 108.796 24.0717 122.413 26.9126 134.489C29.4288 145.107 30.1593 150.861 30.1593 150.861"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M25.0456 76.2139C25.0456 76.2139 24.8021 77.8349 24.5586 80.7527"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M35.1953 117.874C35.1953 117.874 47.1269 124.277 63.1981 118.603"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.8406 150.862C32.8406 150.862 29.5128 150.294 25.6167 152.564C21.7207 154.833 24.2369 162.128 24.2369 162.128H47.5319C47.5319 162.128 51.8338 162.371 51.5903 157.913C51.3468 153.455 49.2365 120.873 49.2365 120.873"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.0488 141.542C28.0488 141.542 40.7921 149.404 50.5322 141.542"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M49.2345 107.256C55.1069 107.256 59.8675 102.502 59.8675 96.6381C59.8675 90.7742 55.1069 86.0205 49.2345 86.0205C43.3621 86.0205 38.6016 90.7742 38.6016 96.6381C38.6016 102.502 43.3621 107.256 49.2345 107.256Z"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M76.1828 91.1279L59.2188 93.0731"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M77.5626 100.609L59.2188 99.9609"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.5759 101.015L23.5859 103.122"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M37.0566 92.1816H39.5728"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M49.2333 104.257C53.4471 104.257 56.863 100.846 56.863 96.6382C56.863 92.4305 53.4471 89.0195 49.2333 89.0195C45.0195 89.0195 41.6035 92.4305 41.6035 96.6382C41.6035 100.846 45.0195 104.257 49.2333 104.257Z"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.5"
        d="M53.2108 90.0732C53.9414 91.2079 54.3472 92.5858 54.3472 94.0447C54.3472 98.2593 50.9382 101.663 46.7174 101.663C45.2564 101.663 43.8766 101.258 42.7402 100.529C44.1201 102.717 46.4739 104.176 49.2336 104.176C53.4544 104.176 56.8634 100.772 56.8634 96.5573C56.8634 93.8826 55.4024 91.4511 53.2108 90.0732Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M36.8979 71.0269C34.2194 70.2164 31.9467 69.2438 30.4857 68.4333C29.2682 67.7038 28.4565 67.8659 28.4565 67.8659L26.0215 68.5954C28.9435 71.0269 31.7844 72.9721 34.4629 74.3499C35.8428 72.891 35.5993 72.5668 36.8979 71.0269Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M70.499 64.705L69.3626 64.0566C69.3626 64.0566 68.2263 66.8123 57.5933 71.1891C56.2135 74.4311 52.4798 76.7815 49.4766 78.0783C61.9764 76.2952 70.499 64.705 70.499 64.705Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M41.6057 119.98C41.6057 119.98 47.8556 152.157 45.3394 156.129C42.4174 160.749 27.239 159.614 24.2358 154.508C24.2358 154.508 22.2067 158.479 24.2358 162.127H47.5309C47.5309 162.127 49.966 162.289 50.6965 160.911C51.427 159.614 51.5893 157.507 51.5893 157.507L50.5341 141.54L49.2354 120.953L41.6057 119.98Z"
        fill="#00BDC7"
      />
      <path
        d="M77.6441 108.797C77.6441 108.797 77.8065 123.386 76.2643 134.166C74.7221 144.945 73.0176 150.538 73.0176 150.538"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M70.3378 150.536C70.3378 150.536 73.6657 149.969 77.5617 152.238C81.4577 154.427 78.9415 161.802 78.9415 161.802H55.6465C55.6465 161.802 51.3446 162.046 51.5881 157.588C51.8316 153.13 53.9419 120.548 53.9419 120.548"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M75.208 141.217C75.208 141.217 62.4647 149.079 52.7246 141.217"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M61.6566 119.737C61.6566 119.737 55.4067 151.914 57.9229 155.886C60.8449 160.506 76.0232 159.371 79.0264 154.265C79.0264 154.265 81.0556 158.236 79.0264 161.883H55.6502C55.6502 161.883 53.2151 162.045 52.4846 160.668C51.7541 159.371 51.5918 157.264 51.5918 157.264L52.647 141.297L53.9457 120.71L61.6566 119.737Z"
        fill="#00BDC7"
      />
      <path
        d="M24.5586 80.7524C27.7241 80.104 32.3507 77.9156 36.6526 71.1074"
        stroke="#00BDC7"
        strokeWidth="0.8219"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8242 80.915C20.8242 80.915 22.2852 81.2392 24.5579 80.7529"
        stroke="#00BDC7"
        strokeWidth="0.8219"
        strokeMiterlimit="10"
      />
      <path
        d="M39.4958 90.0746C39.4958 90.0746 30.8109 89.9125 28.457 79.2949"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M33.8945 67.8662C34.8685 69.163 36.5731 70.5409 39.2516 71.6756"
        stroke="#00BDC7"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M36.9794 63.4894C35.6807 62.5168 34.7067 61.9495 33.8139 62.5168C33.3268 62.841 33.0022 63.3273 32.8398 63.8947"
        stroke="#00BDC7"
        strokeWidth="0.8219"
        strokeMiterlimit="10"
      />
      <path
        d="M50.8581 66.002C49.3971 67.2988 47.8549 68.2714 44.2835 67.3798C43.3907 67.1367 42.6602 66.8935 41.9297 66.5693"
        stroke="#00BDC7"
        strokeWidth="0.8219"
        strokeMiterlimit="10"
      />
      <path
        d="M45.9082 77.7551C45.9082 77.7551 59.7067 79.214 58.3268 63.4902C58.3268 63.4902 58.3268 61.7882 55.7295 62.5987"
        stroke="#00BDC7"
        strokeWidth="0.8219"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M15.3055 79.8619C15.3055 79.8619 14.4127 92.992 22.1236 94.2077C32.8377 95.9098 46.1492 76.2146 46.1492 76.2146C53.4543 73.7831 54.5094 69.9737 52.4803 65.6781C52.4803 65.6781 53.8601 65.3539 54.6718 64.3002C55.4835 63.2466 55.8893 62.5171 55.8893 62.5171C55.8893 62.5171 58.162 61.3014 58.162 64.0571C58.162 66.8128 58.0808 71.2705 56.2951 73.2158C54.5095 75.161 52.3179 77.1062 49.6394 77.4304L43.7142 85.3733C40.9545 88.6153 37.7889 91.5331 34.2176 94.0456C34.2176 94.0456 27.3995 98.4223 21.8801 97.6929C16.3607 96.9634 13.2763 93.8025 12.8705 91.0468C12.7082 88.3721 14.088 81.4018 15.3055 79.8619Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M71.5547 74.2686C71.5547 74.2686 79.6714 84.2377 81.2948 97.8541C81.2948 97.8541 79.7526 101.096 78.6974 102.393C77.6423 103.69 77.6423 104.662 77.6423 104.662C77.6423 104.662 77.6423 89.4249 71.5547 74.2686Z"
        fill="#00BDC7"
      />
    </svg>
  );
};

export default AstronautSvg;
