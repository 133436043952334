import { TimeRange } from '../../types/common';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { GetSeedParams } from '../test-utils';
import { OpenJobsResponse } from '../../integration/openJobs.api';

const openJobsBaseSeed: OpenJobsResponse = {
  data: [
    {
      id: 'open_job_1',
      customerId: 'customer1',
      equipment: 'WT 8.0-167 DD',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Gearbox failure',
      equipmentSerial: '122VSE223111101',
      code: '1243901',
    },
    {
      id: 'open_job_2',
      customerId: 'customer1',
      equipment: 'WT 14-236 DD',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Generator failure',
      equipmentSerial: '122VSE223111102',
      code: '1243902',
    },
    {
      id: 'open_job_3',
      customerId: 'customer1',
      equipment: 'WT 3.x-103',
      address: null,
      reportedSymptom: 'Blade failure',
      equipmentSerial: '122VSE223111103',
      code: '1243903',
    },
    {
      id: 'open_job_4',
      customerId: 'customer1',
      equipment: 'WT 3.x-137',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Control system failure',
      equipmentSerial: '122VSE223111104',
      code: '1243904',
    },
    {
      id: 'open_job_5',
      customerId: 'customer1',
      equipment: 'WT 2-B Energy 2B6',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Electrical component failure',
      equipmentSerial: null,
      code: '1243905',
    },
    {
      id: 'open_job_6',
      customerId: 'customer1',
      equipment: 'WT 8.0-167 DD',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Gearbox failure',
      equipmentSerial: '122VSE223111106',
      code: '1243906',
    },
    {
      id: 'open_job_7',
      customerId: 'customer1',
      equipment: 'WT 14-236 DD',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Generator failure',
      equipmentSerial: '122VSE223111107',
      code: '1243907',
    },
    {
      id: 'open_job_8',
      customerId: 'customer1',
      equipment: 'WT 3.x-103',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Blade failure',
      equipmentSerial: '122VSE223111108',
      code: '1243908',
    },
    {
      id: 'open_job_9',
      customerId: 'customer1',
      equipment: 'WT 3.x-137',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Control system failure',
      equipmentSerial: '122VSE223111109',
      code: '1243909',
    },
    {
      id: 'open_job_10',
      customerId: 'customer1',
      equipment: 'WT 2-B Energy 2B6',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Electrical component failure',
      equipmentSerial: '122VSE223111110',
      code: '1243910',
    },
    {
      id: 'open_job_11',
      customerId: 'customer1',
      equipment: 'WT 8.0-167 DD',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Gearbox failure',
      equipmentSerial: '122VSE223111111',
      code: '1243911',
    },
    {
      id: 'open_job_12',
      customerId: 'customer1',
      equipment: 'WT 14-236 DD',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Generator failure',
      equipmentSerial: '122VSE223111112',
      code: '1243912',
    },
    {
      id: 'open_job_13',
      customerId: 'customer1',
      equipment: 'WT 3.x-103',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Blade failure',
      equipmentSerial: '122VSE223111113',
      code: '1243913',
    },
    {
      id: 'open_job_14',
      customerId: 'customer1',
      equipment: 'WT 3.x-137',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Control system failure',
      equipmentSerial: '122VSE223111114',
      code: '1243914',
    },
    {
      id: 'open_job_15',
      customerId: 'customer1',
      equipment: 'WT 2-B Energy 2B6',
      address: {
        city: 'London',
        country: 'UK',
        street: 'Queens Road',
        streetNumber: null,
        zip: '9509',
      },
      reportedSymptom: 'Electrical component failure',
      equipmentSerial: '122VSE223111115',
      code: '1243915',
    },
  ],
  first: true,
  last: false,
  number: 0,
  numberOfElements: 10,
  size: 10,
  totalElements: 15,
  totalPages: 2,
};

interface OpenJobsMockRequestParams extends GetSeedParams {
  page: number;
  size: number;
  sort: string | null;
}

const getOpenJobsSeed = ({
  customer,
  timeRange,
  country,
  equipmentType,
  page,
  size,
}: OpenJobsMockRequestParams): OpenJobsResponse => {
  if (customer === null) {
    throw new Error('Customer must be selected for the Open Jobs API call!');
  }

  if (country === countriesSeed.countries[1].code) {
    return {
      ...openJobsBaseSeed,
      size: 5,
      totalElements: 5,
      totalPages: 1,
      last: true,
      data: openJobsBaseSeed.data.slice(0, 5),
    };
  }

  if (equipmentType === searchSeed.equipment_types[1]) {
    return {
      ...openJobsBaseSeed,
      size: 3,
      totalElements: 3,
      totalPages: 1,
      last: true,
      data: openJobsBaseSeed.data.slice(0, 3),
    };
  }

  if (timeRange === TimeRange.ONE_MONTH) {
    return {
      ...openJobsBaseSeed,
      size: 4,
      totalElements: 4,
      totalPages: 1,
      last: true,
      data: openJobsBaseSeed.data.slice(0, 4),
    };
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return {
      ...openJobsBaseSeed,
      size: 0,
      totalElements: 0,
      totalPages: 0,
      last: true,
      data: [],
    };
  }

  return size > 10
    ? openJobsBaseSeed
    : {
        ...openJobsBaseSeed,
        data: openJobsBaseSeed.data.slice((page - 1) * 10, page * 10),
      };
};

export default getOpenJobsSeed;
