import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';

import CardContainer from '../cardContainer/CardContainer';
import { ReactComponent as Alert } from '../../assets/images/issues.svg';
import { ReactComponent as Equipment } from '../../assets/images/equipment.svg';
import BarChartHorizontal from '../charts/barChartHorizontal/BarChartHorizontal';
import { getTrendingSymptoms } from '../../store/slices/trendingSymptoms.slice';
import { getTrendingEquipmentFailure } from '../../store/slices/trendingEquipmentFailure.slice';
import NoDataAvailableCard from '../cardContainer/NoDataAvailableCard/NoDataAvailableCard';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { useTranslations } from '../../store/slices/translation.slice';
import useEquipmentChartFilter from './UseEquipmentChartFilter';
import { useMockServerActive } from '../../store/slices/mockServer.slice';
import { FetchingStatus } from '../../types/common';
import AppDialog from '../dialog/Dialog';
import PredictionQuality from '../predictionQuality/PredictionQuality';
import { isDemoEnvironment } from '../../utils/isDemoEnvironment';
import styles from './TrendingEquipmentAndSymptoms.module.scss';
import clsx from 'clsx';

const TrendingEquipmentAndSymptoms: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();
  const isDemoEnv = isDemoEnvironment();
  const { country, customer, equipmentType, timeRange } = useAppSelector(
    (state) => state.filters.dashboard,
  );

  const { status: trendingSymptomsStatus, metrics: trendingSymptoms } =
    useAppSelector((state) => state.trendingSymptoms);
  const {
    status: trendingEquipmentFailureStatus,
    metrics: trendingEquipmentFailure,
  } = useAppSelector((state) => state.trendingEquipmentFailure);

  const {
    chartLabels,
    trendingEquipment: equipmentTranslations,
    trendingSymptoms: symptomsTranslations,
    predictionQuality: predictionQualityTranslations,
  } = useTranslations();

  useEffect(() => {
    const promise = dispatch(getTrendingEquipmentFailure({ withTrend: false }));

    return () => {
      promise.abort();
    };
  }, [country, customer, timeRange, isMockServerActive, dispatch]);

  useEffect(() => {
    const promise = dispatch(getTrendingSymptoms());

    return () => {
      promise.abort();
    };
  }, [
    country,
    customer,
    equipmentType,
    timeRange,
    isMockServerActive,
    dispatch,
  ]);

  const trendingSymptomsData = useMemo(() => {
    if (trendingSymptoms !== null) {
      return trendingSymptoms.map(({ symptom, jobscount }) => ({
        id: symptom,
        name: symptom,
        count: jobscount,
      }));
    }
    return null;
  }, [trendingSymptoms]);

  const trendingEquipmentFailureData = useMemo(() => {
    if (trendingEquipmentFailure !== null) {
      return trendingEquipmentFailure
        .slice(0, 5)
        .map(({ name, failures_per_day }) => ({
          name,
          count: Number(failures_per_day),
          id: name,
        }));
    }
    return null;
  }, [trendingEquipmentFailure]);

  const selectChartProps = useEquipmentChartFilter(
    trendingEquipmentFailureData,
  );

  const [predictionQualityOpen, setPredictionQualityOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <CardContainer
          logo={<Equipment />}
          title={equipmentTranslations.mainTitle}
          tooltipContent={equipmentTranslations.tooltip}
          status={trendingEquipmentFailureStatus}
          testId="trending-equipment"
          height="100%"
        >
          {isDemoEnv && <div className={styles.openDialogSection} />}
          {(trendingEquipmentFailureData !== null &&
            trendingEquipmentFailureData.length === 0) ||
          trendingEquipmentFailureStatus === FetchingStatus.ERROR ? (
            <NoDataAvailableCard />
          ) : (
            <div className={clsx(styles.chart)}>
              <BarChartHorizontal
                testId="trending-equipment-chart"
                chartLabel={chartLabels.numOfJobs}
                data={trendingEquipmentFailureData}
                status={trendingEquipmentFailureStatus}
                selectChartProps={selectChartProps}
              />
            </div>
          )}
        </CardContainer>
      </div>
      <div className={styles.section}>
        <CardContainer
          title={symptomsTranslations.mainTitle}
          tooltipContent={symptomsTranslations.tooltip}
          logo={<Alert />}
          status={trendingSymptomsStatus}
          testId="symptoms"
          height="100%"
        >
          {isDemoEnv && (
            <div className={styles.openDialogSection}>
              <Button
                variant="text"
                className={styles.openDialogBtn}
                onClick={() => setPredictionQualityOpen(true)}
              >
                {predictionQualityTranslations.openDialog}
              </Button>
            </div>
          )}
          {(trendingSymptomsData !== null &&
            trendingSymptomsData.length === 0) ||
          trendingSymptomsStatus === FetchingStatus.ERROR ? (
            <NoDataAvailableCard />
          ) : (
            <div className={clsx(styles.chart)}>
              <BarChartHorizontal
                chartLabel={chartLabels.occurrences}
                data={trendingSymptomsData}
                status={trendingSymptomsStatus}
                testId="symptoms-chart"
              />
            </div>
          )}
        </CardContainer>
      </div>
      {isDemoEnv && (
        <AppDialog
          open={predictionQualityOpen}
          handleClose={() => setPredictionQualityOpen(false)}
          testId="prediction-quality"
          keepMounted
          showCloseIcon={false}
          className={styles.dialog}
        >
          <PredictionQuality />
        </AppDialog>
      )}
    </div>
  );
};

export default TrendingEquipmentAndSymptoms;
