import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TimeRange } from '../../types/common';
import { Customer } from '../../integration/customer.api';

export const FILTERS_KEY = 'filters';

export interface DashboardFilters {
  country: string | null;
  customer: Customer | null;
  shouldClearCustomerInput: boolean;
  shouldClearCountryInput: boolean;
  equipmentType: string | null;
  shouldClearEquipmentTypeInput: boolean;
  timeRange: TimeRange;
}

export interface FiltersState {
  dashboard: DashboardFilters;
}

export const initialFiltersState: FiltersState = {
  dashboard: {
    country: null,
    customer: null,
    shouldClearCustomerInput: true,
    shouldClearCountryInput: true,
    equipmentType: null,
    shouldClearEquipmentTypeInput: true,
    timeRange: TimeRange.ONE_YEAR,
  },
};

interface ClearFilterParams {
  shouldClearInput: boolean;
}

interface UpdateFiltersParams {
  country?: string | null;
  timeRange?: TimeRange;
  customer?: Customer;
  equipmentType?: string;
}

const filtersSlice = createSlice({
  initialState: initialFiltersState,
  name: FILTERS_KEY,
  reducers: {
    updateFilters(state, action: PayloadAction<UpdateFiltersParams>) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...action.payload,
        },
      };
    },
    clearCustomerFilter(state, action: PayloadAction<ClearFilterParams>) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          customer: null,
          shouldClearCustomerInput: action.payload.shouldClearInput,
        },
      };
    },
    clearEquipmentTypeFilter(state, action: PayloadAction<ClearFilterParams>) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          equipmentType: null,
          shouldClearEquipmentTypeInput: action.payload.shouldClearInput,
        },
      };
    },
    clearCountryFilter(state, action: PayloadAction<ClearFilterParams>) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          country: null,
          shouldClearCountryInput: action.payload.shouldClearInput,
        },
      };
    },
    resetFilters() {
      return initialFiltersState;
    },
  },
});

const { actions, reducer } = filtersSlice;

export const {
  updateFilters,
  clearCustomerFilter,
  clearEquipmentTypeFilter,
  clearCountryFilter,
  resetFilters,
} = actions;

export const filtersReducer = reducer;
