import { useCallback, useEffect, useState } from 'react';
import { Amplify, Analytics, AWSCloudWatchProvider, Logger } from 'aws-amplify';
import { format } from 'date-fns';

import { useAuthData } from './auth';
import { isDefined } from './isDefined';
import { isDemoEnvironment } from './isDemoEnvironment';

const today = format(new Date(), 'yyyy/MM/dd');

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
  },
  Logging: {
    logGroupName: 'dashboard-log-group',
    logStreamName: `dashboard-log-stream-${today}`,
    region: process.env.REACT_APP_AWS_REGION,
  },
});

const logger = new Logger('dashboard-logger', 'DEBUG');
Amplify.register(logger);
logger.addPluggable(new AWSCloudWatchProvider());

export const useLogger = () => {
  const authData = useAuthData();
  const tenantId = authData?.tenantId;
  const shouldLogToConsole = isDemoEnvironment();

  const logError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...msg: any[]): void => {
      if (shouldLogToConsole) {
        console.error(...msg);
      }
      if (!isDefined(tenantId)) {
        logger.error(...msg);
      } else {
        logger.error(`[tenant:${tenantId}]`, ...msg);
      }
    },
    [tenantId],
  );

  return { logError };
};

export const useAnalytics = () => {
  const authData = useAuthData();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    try {
      Analytics.configure({
        AWSPinpoint: {
          appId: process.env.REACT_APP_AWS_PINPOINT_APP_ID,
          region: process.env.REACT_APP_AWS_REGION,
          mandatorySignIn: false,
        },
      });
      Analytics.autoTrack('session', {
        enable: true,
      });
      setInitialized(true);
    } catch (error) {
      console.error('Could not boot up analytics', error);
    }
  }, [initialized]);

  useEffect(() => {
    if (!initialized || authData === null) {
      return;
    }
    const { accountId, companyId, identityId, tenantId } = authData;
    const bootAnalytics = async () => {
      try {
        await Analytics.updateEndpoint({
          userId: identityId,
          userAttributes: {
            // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
            // MUST be an array, even single value
            accountId: [accountId],
            companyId: [companyId],
            tenantId: [tenantId],
          },
        });
      } catch (error) {
        console.error('Could not register user data on analytics', error);
      }
    };
    bootAnalytics();
  }, [initialized, authData]);
};
