import { useMemo } from 'react';
import { useAuthData } from '../utils/auth';
import { isDefined } from '../utils/isDefined';

const GEA_ACCOUNT_ID = '91878';
const ALLOWED_USER_IDS = new Set(['305255', '307142']);

const useGeaAuthorization = (): boolean => {
  const authData = useAuthData();

  return useMemo(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT_NAME !== 'production' ||
      authData === null
    ) {
      return true;
    }

    const { accountId, userId } = authData;
    if (accountId !== GEA_ACCOUNT_ID || !isDefined(userId)) {
      return true;
    }

    return ALLOWED_USER_IDS.has(userId);
  }, [authData]);
};

export default useGeaAuthorization;
