import React, { useMemo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';

import { useTranslations } from '../../store/slices/translation.slice';
import { shortenText } from '../../utils/shortenText';

const PREDICTION_MAX_WORDS = 50;

interface Props {
  prediction: string | null;
}

const PredictionCell: React.FC<Props> = ({ prediction }) => {
  const { openJobs: translations } = useTranslations();
  const shortenedPrediction = useMemo(() => {
    if (prediction === null) {
      return null;
    }
    return shortenText(PREDICTION_MAX_WORDS)(prediction);
  }, [prediction]);
  const isPredictionShortened = prediction !== shortenedPrediction;

  if (prediction === null) {
    return (
      <TableCell>
        <Typography variant="body1">{translations.noPrediction}</Typography>
      </TableCell>
    );
  }

  return (
    <TableCell>
      <Tooltip disableHoverListener={!isPredictionShortened} title={prediction}>
        <Typography variant="body1">{shortenedPrediction}</Typography>
      </Tooltip>
    </TableCell>
  );
};

export default PredictionCell;
