import api, { ApiFetchParams, ApiResponse } from './api';
import { TimeRange } from '../types/common';

export interface PredictionQuality {
  id: string;
  jobDiagnosis: string;
  predictedLabels: string[];
  trueLabels: string[];
  performance: number;
}

export interface PredictionQualitySummary {
  accuracy: number | null;
  trend: number | null;
  lowConfidence: number;
  mediumConfidence: number;
  highConfidence: number;
  total: number;
  bestPredictions: PredictionQuality[];
  worstPredictions: PredictionQuality[];
}

export enum SummaryType {
  PARTS = 'parts',
  DIAGNOSIS = 'diagnosis',
}

export type PredictionResponse = ApiResponse<PredictionQualitySummary>;

interface FetchPredictionQualityParams extends ApiFetchParams {
  timeRange: TimeRange;
  summaryType: SummaryType;
}

export const fetchRootCausePredictionQuality = async ({
  timeRange,
  summaryType,
  signal,
  mock,
}: FetchPredictionQualityParams): Promise<PredictionQualitySummary> => {
  const { data } = await api.get<PredictionResponse>({
    mock,
    path: '/accuracy-check/api/v1/prediction-quality-summary',
    queryParams: {
      timeRange,
      summaryType,
    },
    config: { signal },
    retry: 5,
  });

  return data;
};

export const fetchPartPredictionQuality = async ({
  timeRange,
  summaryType,
  signal,
  mock,
}: FetchPredictionQualityParams): Promise<PredictionQualitySummary> => {
  const { data } = await api.get<PredictionResponse>({
    mock,
    path: '/accuracy-check/api/v1/prediction-quality-summary',
    queryParams: {
      timeRange,
      summaryType,
    },
    config: { signal },
    retry: 5,
  });

  return data;
};
