import api, { ApiFetchParams, ApiResponse } from './api';
import { TimeRange } from '../types/common';

export interface JobTypeAndCount {
  count: number;
  type: string;
}

export interface AllCustomersAndInterventionsData {
  job_types_in_focus: JobTypeAndCount[];
}

type AllCustomersAndInterventionsResponse =
  ApiResponse<AllCustomersAndInterventionsData>;

interface FetchAllCustomersAndInterventionsParams extends ApiFetchParams {
  country: string | null;
  timeRange: TimeRange;
  equipmentType: string | null;
}

export const fetchAllCustomersAndInterventions = async ({
  country,
  timeRange,
  equipmentType,
  signal,
  mock,
}: FetchAllCustomersAndInterventionsParams): Promise<JobTypeAndCount[]> => {
  const { data } = await api.get<AllCustomersAndInterventionsResponse>({
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      timeRange,
      equipmentType,
      country,
      type: 'JobTypesInFocus',
    },
    config: { signal },
  });
  return data.job_types_in_focus;
};
