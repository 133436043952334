import React from 'react';
import styles from './KpisGlanceTooltip.module.scss';

export interface Props {
  title?: string;
  definition: string;
  description?: string;
}
const KpisGlanceTooltip: React.FC<Props> = ({
  title,
  definition,
  description,
}) => {
  return (
    <p className={styles.tooltip}>
      {title && <b>{title}</b>} {definition}
      <br />
      <i>{description}</i>{' '}
    </p>
  );
};

export default KpisGlanceTooltip;
