import React from 'react';

import { Translations } from './common';
import { TimeRange } from '../../types/common';

const germanTranslations: Translations = {
  loading: 'Laden...',
  noOptions: 'Keine Optionen',
  noData: (
    <>
      Wir konnten keine Daten laden. Versuchen Sie den Filter zu ändern.
      <br />
      Wenn etwas nicht richtig funktioniert, können Sie uns eine{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-frontend-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        Nachricht
      </a>{' '}
      senden.
    </>
  ),
  errorCardText: (
    <>
      Ups.
      <br />
      Es sieht so aus, als ob dieser Bereich nicht funktioniert. Versuchen Sie
      es später noch einmal oder senden Sie uns eine{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-frontend-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        Nachricht
      </a>
      .
    </>
  ),
  months: [
    'Jan',
    'Feb',
    'März',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dez',
  ],
  monthsFull: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthsMap: {
    Jan: 'Januar',
    Feb: 'Februar',
    März: 'März',
    Apr: 'April',
    Mai: 'Mai',
    Jun: 'Juni',
    Jul: 'Juli',
    Aug: 'August',
    Sept: 'September',
    Okt: 'Oktober',
    Nov: 'November',
    Dez: 'Dezember',
  },
  timeUnitLabels: {
    hours: 'Stunden',
    day: 'Tag',
    days: 'Tage',
  },
  durationInDays: (days) => `${days} ${days >= 2 ? 'Tage' : 'Tag'}`,
  auth: {
    login: 'Login',
    email: 'Email',
    password: 'Passwort',
    sessionExpired:
      'Ihre Session ist abgelaufen. Bitte melden Sie sich erneut an, um InsightLoop weiter zu nutzen.',
    or: 'oder',
    loading: 'Authentifizierung...',
    marketingMessage: 'Bereit zum Abheben?',
    invalidCredentials: 'Ungültige E-Mail oder Passwort',
    pilotDescription: (
      <>
        Partnerschaft für den Erfolg: Unser kollaborativer Ansatz, um Ihre Reise
        zu unterstützen. Lassen Sie uns Ihnen helfen, das Potenzial Ihrer Daten
        zu nutzen - fordern Sie jetzt Ihren{' '}
        <a href="mailto:sales@coresystems.ch" target="">
          Zugang
        </a>{' '}
        an.
      </>
    ),
    unauthorized: 'Das Dashboard ist derzeit nur für Pilot-Kunden verfügbar.',
    azure: {
      loginButtonText: 'Login mit Microsoft',
      tooltipLabel: 'Benötigen Sie Hilfe?',
      tooltipContent:
        'Dies ist das gleiche Login, welches Sie für den Zugang zu Azure verwenden. Wenn Sie nicht sicher sind, wenden Sie sich bitte an den IT-Support.',
    },
  },
  failureRate: {
    changesThisPeriod: ({ periodLabel }) => `Ändern ${periodLabel}`,
    failureRateText: 'Ausfallquote',
    reliability: 'Zuverlässigkeit',
    inTheField: 'Installiert & im Einsatz',
    locationsImpacted: 'Betroffene Kunden',
    mainTitle: ({ equipmentType }) => `Equipment details für ${equipmentType}`,
    withIssues: 'Probleme',
    tooltip:
      'Ausfallquote des jeweiligen Equipments und eine Übersicht über die aktuellen Installationen dieses Equipment Typs. Trends beziehen sich auf die Veränderung zwischen dem angezeigten Zeitraum und dem vorangegangenen Zeitraum. Dies kann dazu führen, dass der Trend enorm hoch oder niedrig ist.',
  },
  documentUpload: {
    tooltip:
      'Erweitern Sie die Wissensdatenbank von InsightLoop durch Hochladen von Dokumenten pro Equipment Typ. Dadurch kann InsightLoop tiefgreifende, semantisch gestützte Suchanfragen durchführen und die Fähigkeit des immersiven Assistenten verbessern, präzise und umfassende Antworten zu liefern.',
    documents: 'Dokumente',
    clickToUpload: 'Zum Hochladen klicken',
    dragAndDrop: 'oder per Drag & Drop',
    seeAll: 'Alle anzeigen',
    noFilesMessage: 'Hochgeladene Dateien werden hier angezeigt.',
    uploadFailure: 'Datei bereits vorhanden',
    dialog: {
      fileName: 'Datei Name',
      fileSize: 'Datei Grösse',
      date: 'Datum',
      sortBy: 'Sortieren nach',
    },
  },
  timeSpan: {
    [TimeRange.ONE_WEEK]: 'seit letzter Woche',
    [TimeRange.ONE_MONTH]: 'seit letztem Monat',
    [TimeRange.SIX_MONTHS]: 'seit den letzten sechs Monaten',
    [TimeRange.ONE_YEAR]: 'seit letztem Jahr',
  },
  filters: {
    countryFilter: 'Alle Länder',
    customerFilter: 'Alle Kunden',
    equipmentTypeFilter: 'Alle Equipments',
    time: {
      [TimeRange.ONE_YEAR]: 'Jahr',
      [TimeRange.SIX_MONTHS]: '6 Monate',
      [TimeRange.ONE_MONTH]: 'Monat',
      [TimeRange.ONE_WEEK]: 'Woche',
    },
  },
  kpiCharts: {
    mainTitle: 'KPIs im Überblick',
    metrics: {
      tooltipContent: [
        {
          id: 'FTFR',
          definition:
            'Prozentualer Anteil der Probleme, die beim ersten Servicebesuch gelöst werden konnten.',
          description:
            'Wie es berechnet wird: Ein erneuter Servicebesuch desselben Equipments innerhalb von weniger als 30 Tagen gilt nicht als first time fix.',
        },
        {
          id: 'MTTR',
          definition: 'Durchschnittliche Dauer eines offenen Jobs',
          description:
            'Wie es berechnet wird: Durchschnittliche Zeit zwischen der Erstellung eines Jobs (nur Reparaturen) und dessen Abschluss im System.',
        },
        {
          id: 'MTBV',
          definition:
            'Durchschnittliche Zeit zwischen Servicebesuchen pro Kundenstandort.',
          description:
            'Wie es berechnet wird: Durchschnittliche Zeit zwischen Servicebesuchen pro Kundenstandort. Beinhaltet alle Job Arten.',
        },
        {
          id: 'Trends',
          definition: 'Trends',
          description:
            'beziehen sich auf die Veränderung zwischen dem angezeigten Zeitraum und dem vorangegangenen Zeitraum. Dies kann dazu führen, dass der Trend enorm hoch oder niedrig ist.',
        },
      ],
      firstTimeFixRate: 'First-Time Fix Rate',
      meanTimeToResolution: 'Mean Time To Resolution',
      meanTimeBetweenVisits: 'Mean Time Between Visits',
      ratings: {
        title: 'Bewertungen',
        tooltipSummary: '',
        tooltipDetails: '',
      },
    },
    tooltip:
      'Die wichtigsten Leistungsindikatoren für den Aussendienst, welche quantifizierbare Daten über den Betrieb zur Messung der operativen Effizienz liefern. Trends beziehen sich auf die Veränderung zwischen dem angezeigten Zeitraum und dem vorangegangenen Zeitraum. Dies kann dazu führen, dass der Trend enorm hoch oder niedrig ist.',
  },
  chartLabels: {
    numOfJobs: 'Anzahl Jobs',
    numOfParts: 'Anzahl Ersatzteile',
    occurrences: 'Ereignisse',
  },
  openJobs: {
    equipment: 'Equipment',
    issue: 'Berichtetes Problem',
    jobNumber: 'Job Nr.',
    jobsPerPage: 'Jobs pro Seite',
    location: 'Standort',
    reportIssue: 'Problem melden',
    rootCauses: 'Vorhersagen',
    serialNumber: 'Serien-Nr.',
    noSerialNumber: 'Keine Serien-Nr. verfügbar',
    noAddress: 'Keine Adresse verfügbar',
    noPrediction:
      'Keine Vorhersage oder Vorhersagezuverlässigkeit ist zu gering (<10%)',
    title: 'Offene Jobs',
    tooltip:
      'Derzeit offene Jobs für diesen Kunden und die voraussichtliche Ursache des Problems. Die prozentuale Zuverlässigkeit der Vorhersage verbessert sich mit der Zeit, wenn der Predictor mit mehr Daten arbeiten kann.',
    tooltipDetailsTitle: 'Wie es funktioniert',
    tooltipDetails:
      'Auf der Grundlage von Ursache-Diagnose Daten aus ähnlichen Jobs desselben Equipment Typs in der Vergangenheit, wird der Predictor trainiert, um Korrelationen mit den vom Kunden gemeldeten Problemen zu finden, die dann zur Vorhersage der Ursache verwendet werden können. Das vom Kunden berichtete Problem dient als Eingabe für den trainierten Predictor. Die Konfidenz gibt die Wahrscheinlichkeit an, dass die Ausgabe des Predictors korrekt ist.',
  },
  openJobsDialog: {
    contactPerson: 'Ansprechperson',
    email: 'Email',
    moreInfo: 'Mehr Informationen',
    moreInfoDescription: 'Weitere Informationen finden Sie unter diesem Link.',
    number: 'Nummer',
    similarRootCauses: 'Ähnliche Ursachen in der Vergangenheit',
  },
  timeRanges: {
    [TimeRange.ONE_YEAR]: 'dieses Jahr',
    [TimeRange.SIX_MONTHS]: 'die letzten \n sechs Monate',
    [TimeRange.ONE_MONTH]: 'diesen Monat',
    [TimeRange.ONE_WEEK]: 'diese Woche',
  },
  trendingCustomers: {
    typeSummaryTitle: 'Alle Kunden und Interventionsarten',
    pieChartTitle: 'Total',
    pieChartTooltip: 'Kunden im Fokus',
    mainTitle: 'Kunden im Trend',
    tooltip:
      'Kunden mit den meisten Jobs in einem bestimmten Zeitraum. Beinhaltet alle Job Arten (Wartung & Reparaturen).',
  },
  trendingEquipment: {
    mainTitle: 'Equipments im Trend',
    tooltip:
      'Equipments mit den meisten gemeldeten Problemen in einem bestimmten Zeitraum.',
  },
  trendingSymptoms: {
    mainTitle: 'Häufigste Probleme',
    tooltip:
      'Von Kunden am häufigsten gemeldete Probleme. Für alle Equipments, in einem bestimmten Zeitraum.',
    viewAllBtn: 'Alle anzeigen',
  },
  symptomsDialog: {
    searchPlaceholder: ({ equipmentType }) =>
      `Suche nach Symptomen für ${equipmentType}`,
    symptomsChartTitle: 'Häufig berichtete Symptome',
    numberOfSymptoms: (count) => (
      <>
        <b>{count}</b> {count === 1 ? 'Symptom' : 'Symptome'}
      </>
    ),
    noResults: 'Keine Resultate',
    predictionsTitle: 'Vorhersage',
    selectSymptomPrompt: 'Wähle ein Symptom, um Vorhersagen zu sehen',
    partName: 'Ersatzteil',
    probability: 'Wahrscheinlichkeit',
    rootCause: 'Ursache',
  },
  trendingParts: {
    mainTitle: 'Ersatzteile im Trend',
    tooltip:
      'Prognose des Ersatzteilebedarfs mit einem Jahresausblick und detaillierten Informationen zu den wichtigsten Ersatzteilen auf monatlicher Basis. Die Prognose ist nach Job Art unterteilt.',
    monthChartTitle: ({ month }) => `Ersatzteile Prognose für ${month}`,
    selectPartPrompt:
      'Wählen Sie Ersatzteile aus, um weitere Details zur bisherigen Nutzung zu sehen.',
    maintenance: 'Wartung',
    failure: 'Reparatur',
    other: 'Sonstige',
    total: 'Total',
    partHistory: {
      lastYear: ({ year }) => `Jahr ${year}`,
      currentYear: ({ year }) => `Jahr ${year} bis zu diesem Datum`,
      lastMonth: ({ month }) => `Letzten ${month}`,
    },
    chartLabels: {
      currentMonth: 'Aktueller Monat:',
      usedVsPredicted: 'Verwendet vs. Vorausgesagt',
    },
  },
  workflowBottleneck: {
    mainTitle: 'Prozess Status',
    tooltipDescription: {
      introduction:
        'Einblicke in den Prozess Status und ermittelte Engpässe, die sich auf die Service-Effizienz auswirken. Diese Erkenntnisse helfen dabei, die richtigen Massnahmen zur Verbesserung der KPIs zu ergreifen.',
      kpiDetails: [
        {
          title: 'Erstellung von Jobs',
          description:
            'Zeit zwischen Job Erstellung und erster Task Zuweisung.',
        },
        {
          title: 'Planung & Disposition',
          description:
            ' Zeit zwischen erster Task Zuweisung und letzter Task Freigabe.',
        },
        {
          title: 'Arbeit vor Ort',
          description:
            'Zeit zwischen Task Start und Abschluss des letzten Tasks.',
        },
        {
          title: 'Abschluss & Rechnung',
          description:
            'Zeit zwischen Abschluss des letzten Tasks und dem Job Abschluss.',
        },
      ],
    },
    stepDuration: 'Schrittdauer',
    overallScore: 'Gesamtbewertung',
    kpis: [
      {
        title: 'Job backlog',
        id: 'Job backlog',
        tooltip:
          'Die Anzahl der ausstehenden Serviceanfragen, die noch geplant werden müssen.',
      },
      {
        title: 'Qualität der Job Beschreibung',
        id: 'Job Description Quality',
        tooltip:
          'Die Genauigkeit und Vollständigkeit der Job Beschreibungen, die den Technikern zur Verfügung gestellt werden.',
      },
      {
        title: 'Unterbrechung einer Aufgabe',
        id: 'Task interruption',
        tooltip:
          'Die Häufigkeit der Unterbrechungen, die die Aussendiensttechniker bei der Ausführung ihrer Aufgaben erleben.',
      },
      {
        title: 'Zeit für die Terminplanung',
        id: 'Time to schedule',
        tooltip:
          'Die Zeit, die benötigt wird, um einem Techniker alle Aufgaben zuzuweisen und zu planen.',
      },
      {
        title: 'Geplante Reisezeit',
        id: 'Scheduled Travel Time',
        tooltip:
          'Die voraussichtliche Dauer der Reise der Techniker zu den Service-Standorten.',
      },
      {
        title: 'Zeit zum ersten Kontakt',
        id: 'Time to visit',
        tooltip:
          'Die Zeit, die zwischen der Job Erstellung und dem ersten Start einer Aufgabe vor Ort vergeht.',
      },
      {
        title: 'Verhältnis Disponent/Techniker',
        id: 'Dispatcher/Tech Ratio',
        tooltip:
          'Das Verhältnis von Disponenten zu Technikern, das die Verteilung der Arbeitslast angibt.',
      },
      {
        title: 'Auslastungsrate',
        id: 'Utilization rate',
        tooltip:
          'Der prozentuale Anteil der Zeit, den ein Techniker mit produktiver Arbeit verbringt.',
      },
      {
        title: 'Aussendienstbesuche pro Tag',
        id: 'Field Visits per Day',
        tooltip:
          'Die durchschnittliche Anzahl der Kundenbesuche, die die Aussendiensttechniker an einem Tag durchführen.',
      },
      {
        title: 'Pünktliche Besuche vor Ort',
        id: 'Field Visits on Time',
        tooltip:
          'Der Prozentsatz der Besuche vor Ort, die innerhalb der geplanten Zeit abgeschlossen werden.',
      },
      {
        title: 'Leerlaufzeit der Techniker',
        id: 'Technician Idle Time',
        tooltip: 'Die Zeit, die Techniker ohne produktive Aufgaben verbringen.',
      },
      {
        title: 'Abweichung Aufgabendauer',
        id: 'Variance Task Duration',
        tooltip:
          'Die Abweichung zwischen der geschätzten und der tatsächlichen Aufgabendauer.',
      },
      {
        title: 'Bestellte Ersatzteile nicht verwendet',
        id: 'Ordered Parts Unused',
        tooltip:
          'Die Menge der bestellten Ersatzteile, die während des Serviceeinsatzes nicht verwendet wurden.',
      },
      {
        title: 'Abgeschlossene Jobs',
        id: 'Job closed',
        tooltip:
          'Die Gesamtzahl der erfolgreich abgeschlossenen Serviceanfragen.',
      },
      {
        title: 'Abgeschlossene Jobs - in Rechnung gestellt',
        id: 'Job closed - invoiced',
        tooltip:
          'Die Anzahl der Serviceanfragen, die sowohl abgeschlossen als auch zur Zahlung in Rechnung gestellt wurden.',
      },
    ],
    kpiTitle: {
      jobGeneration: 'Erstellung von Jobs',
      scheduling: 'Planung & Disposition',
      fieldWork: 'Arbeit vor Ort',
      closing: 'Abschluss & Rechnung',
    },
  },
  predictionQuality: {
    openDialog: 'Qualität früherer Vorhersagen',
    allPredictions: 'Vorhersagen',
    highConfidence: 'Hohe Genauigkeit',
    mediumConfidence: 'Mittlere Genauigkeit',
    lowConfidence: 'Geringe Genauigkeit',
    jobDiagnosis: 'Job Diagnose',
    words: 'Wörter',
    rootCauses: {
      title: 'Qualität der Ursachenvorhersage',
      predicted: 'Vorausgesagte Ursache(n)',
      used: 'Wirkliche Ursache(n)',
    },
    parts: {
      title: 'Qualität der Ersatzteilvorhersage',
      predicted: 'Vorausgesagte Ersatzteile',
      used: 'Verwendete Ersatzteile',
    },
  },
  contactSupport: {
    title: 'Worüber wollen Sie sprechen?',
    contact: 'Kontakt',
    feedbackTopics: ['KPIs', 'Equipment', 'Chatbot', 'Vorhersagen', 'Andere'],
    takeTour: 'Rundgang',
    mail: (subject) =>
      `mailto:contact-project+coresystemsag1-service-ai-frontend-analytics-ui-support@incoming.gitlab.com?subject=InsightLoop Feedback: ${subject}`,
  },
  dashboardTour: {
    welcome: (
      <>
        Willkommen auf <br /> dem Dashboard
      </>
    ),
    description:
      'Sie sind sicher auf dem Dashboard von InsighLoop gelandet. Hier können Sie alles erkunden, was mit .... zu tun hat.',
    startOnboarding: 'Onboarding starten',
    skipOnboarding: 'Überspringen Sie diese Tipps',
    next: 'Weiter',
    close: 'Schliessen',
    steps: {
      step: (stepNo) => `Schritt ${stepNo} von`,
      yourFilters: 'Ihre Filter',
      filtersDescription:
        'Entdecken Sie die verschiedenen Filter, die Ihnen zur Verfügung stehen, um Ihr Dashboard effektiv zu verwalten. Wenn Sie einen Filter anwenden, wird das gesamte Dashboard dynamisch aktualisiert.',
      kpis: 'KPIs',
      kpisDescription:
        'Ihre wichtigsten KPIs (Key Performance Indicators) werden immer oben auf dem Bildschirm priorisiert. Die Anwendung von Filtern kann zu Änderungen oder zum Entfernen von Prozessschritten führen.',
      frequentEquipmentsAndIssues: 'Häufigste Equipments und Probleme',
      frequentEquipmentsAndIssuesDescription:
        'Diese beiden Diagramme zeigen die am häufigsten verwendeten Equipments und die häufigsten Probleme im Feld an. Wenn Sie auf ein Equipment klicken, wird es als Filter auf das gesamte Dashboard angewendet.',
      support: 'Support erhalten',
      supportDescription:
        'Wenn Sie noch Fragen haben, können Sie sich jederzeit an den Support wenden, indem Sie eine E-Mail senden.',
    },
  },
};

export default germanTranslations;
