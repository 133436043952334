import React from 'react';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  content: JSX.Element | JSX.Element[] | string;
  testId: string;
  close?: boolean; // TODO: we need a much better approach than this
}

const AppTooltip: React.FC<Props> = ({ content, close, testId }) => {
  return (
    <Tooltip title={content}>
      <IconButton
        data-testid={`${testId}-tooltip`}
        sx={{
          width: 'auto',
          height: 'auto',
          color: 'info.main',
          position: 'absolute',
          top: close ? '-4px' : 0,
          right: close ? '-4px ' : 0,
        }}
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AppTooltip;
