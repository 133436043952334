import { getTokenFromStorage } from '../utils/auth';
import { isDefined } from '../utils/isDefined';
import { TimeRange } from '../types/common';
import { handleMockRequest } from '../tests/mock-server/server-handlers';

export const BASE_URL = process.env.REACT_APP_API_URL;
const DEFAULT_RETRY_COUNT = 10;

export interface ApiFetchParams {
  mock: boolean;
  signal: AbortSignal;
}

export interface FetchMetricsParams extends ApiFetchParams {
  country: string | null;
  equipmentType: string | null;
  timeRange: TimeRange;
  customerId?: string;
  withTrend: boolean;
}

export interface ApiResponse<T> {
  data: T;
}

type QueryParams = Record<
  string,
  string | number | boolean | string[] | null | undefined
>;

export const buildUrl = (
  path: string,
  params?: QueryParams,
  domain = BASE_URL,
): string => {
  const baseUrl = `${domain}${path}`;
  if (!isDefined(params)) {
    return baseUrl;
  }
  const queryString = Object.entries(params)
    .filter(([, value]) => isDefined(value))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        const encodedArray = value
          .map((item) => encodeURIComponent(String(item)))
          .join(',');

        return `${key}=${encodedArray}`;
      } else {
        return `${key}=${encodeURIComponent(String(value))}`;
      }
    })
    .join('&');

  if (queryString === '') {
    return baseUrl;
  } else {
    return `${baseUrl}?${queryString}`;
  }
};

export interface RequestParams {
  mock: boolean;
  path: string;
  queryParams?: QueryParams;
  config?: RequestInit;
  retry?: number;
  domain?: string;
  method?: 'GET' | 'POST' | 'PATCH';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: Record<string, any>;
}

/**
 * A wrapper around fetch API.
 */
class Api {
  static getDefaultHeaders(): HeadersInit {
    const token = getTokenFromStorage();
    if (token === null) {
      throw new Error('Not authenticated!');
    }

    return {
      Authorization: token,
    };
  }

  async get<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      config,
      domain,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'GET' });
    }

    const urlWithQuery = buildUrl(path, queryParams, domain);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          headers: {
            'Content-Type': 'application/json',
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }

  async post<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      body,
      config,
      domain,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'POST' });
    }

    const urlWithQuery = buildUrl(path, queryParams, domain);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          ...(isDefined(body) ? { body: JSON.stringify(body) } : {}),
          method: 'POST',
          headers: {
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
          // TODO: remove this
          body: body instanceof FormData ? body : JSON.stringify(body),
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }

  async patch<T>(params: RequestParams): Promise<T> {
    const isTestEnv = process.env.NODE_ENV === 'test';
    const {
      mock,
      path,
      queryParams,
      config,
      domain,
      retry = DEFAULT_RETRY_COUNT,
    } = params;

    if (mock || isTestEnv) {
      return handleMockRequest({ ...params, method: 'PATCH' });
    }

    const urlWithQuery = buildUrl(path, queryParams, domain);
    let retryCount = 0;
    while (retryCount < retry) {
      try {
        return await fetch(urlWithQuery, {
          ...config,
          method: 'PATCH',
          headers: {
            ...Api.getDefaultHeaders(),
            ...config?.headers,
          },
        }).then((res) => res.json());
      } catch (e) {
        retryCount++;
      }
    }
    throw new Error('Request timed out!');
  }
}

export default new Api();
