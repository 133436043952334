import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import CardContainer from '../cardContainer/CardContainer';
import { ReactComponent as Business } from '../../assets/images/business.svg';
import { getTrendingCustomers } from '../../store/slices/trendingCustomers.slice';
import NoDataAvailableCard from '../cardContainer/NoDataAvailableCard/NoDataAvailableCard';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { useTranslations } from '../../store/slices/translation.slice';
import { useMockServerActive } from '../../store/slices/mockServer.slice';
import TrendingCustomersChart from './customersChart/TrendingCustomersChart';
import styles from './TrendingCustomers.module.scss';
import { FetchingStatus } from '../../types/common';
import { getAllCustomersAndInterventions } from '../../store/slices/allCustomersAndInterventions.slice';
import TrendingCustomersSummaryChart from './summaryChart/TrendingCustomersSummaryChart';
import { JobTypeCounts } from '../../types/charts';
import { JobType } from '../../integration/trendingCustomers.api';

const TrendingCustomers: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();
  const { country, equipmentType, timeRange } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const { status: trendingCustomersStatus, metrics: trendingCustomersData } =
    useAppSelector((state) => state.customersInFocus);
  const {
    status: allCoustomersAndInterventionsStatus,
    metrics: allCoustomersAndInterventionsData,
  } = useAppSelector((state) => state.allCustomersAndInterventions);
  const { chartLabels, trendingCustomers: translations } = useTranslations();
  useEffect(() => {
    const promise = dispatch(getTrendingCustomers());

    return () => {
      promise.abort();
    };
  }, [country, equipmentType, timeRange, isMockServerActive, dispatch]);

  useEffect(() => {
    const promise = dispatch(getAllCustomersAndInterventions());

    return () => {
      promise.abort();
    };
  }, [country, equipmentType, timeRange, isMockServerActive, dispatch]);

  const totalCountByType = useMemo(() => {
    if (allCoustomersAndInterventionsData === null) {
      return null;
    }
    const summary = allCoustomersAndInterventionsData.reduce(
      (acc: JobTypeCounts, metric) => {
        if (metric.type === JobType.MAINTENANCE) {
          return {
            ...acc,
            maintenance: acc.maintenance + metric.count,
          };
        }
        if (metric.type === JobType.FAILURE) {
          return {
            ...acc,
            failure: acc.failure + metric.count,
          };
        }

        return {
          ...acc,
          other: acc.other + metric.count,
        };
      },
      {
        maintenance: 0,
        failure: 0,
        other: 0,
      },
    );

    return [
      { id: 'maintenance', name: 'Maintenance', count: summary.maintenance },
      { id: 'failure', name: 'Failure', count: summary.failure },
      { id: 'other', name: 'Other', count: summary.other },
    ];
  }, [allCoustomersAndInterventionsData]);

  const totalCount = useMemo(() => {
    if (totalCountByType !== null) {
      return totalCountByType.reduce((acc, { count }) => acc + count, 0);
    }
    return null;
  }, [totalCountByType]);

  const noDataAvailable =
    (trendingCustomersData !== null && trendingCustomersData.length === 0) ||
    trendingCustomersStatus === FetchingStatus.ERROR;

  return (
    <CardContainer
      logo={<Business />}
      title={translations.mainTitle}
      tooltipContent={translations.tooltip}
      status={trendingCustomersStatus}
      testId="trending-customers"
    >
      {noDataAvailable ? (
        <NoDataAvailableCard />
      ) : (
        <div className={styles.container}>
          <div className={clsx(styles.leftSection)}>
            <TrendingCustomersChart
              data={trendingCustomersData}
              status={trendingCustomersStatus}
              testId="trending-customers-chart"
              chartLabel={chartLabels.numOfJobs}
            />
          </div>
          <div
            className={clsx(styles.rightSection, 'with-right-aligned-legend')}
          >
            <TrendingCustomersSummaryChart
              chartName={translations.pieChartTooltip}
              data={totalCountByType}
              status={allCoustomersAndInterventionsStatus}
              title={translations.typeSummaryTitle}
              totalLabel={translations.pieChartTitle}
              totalValue={totalCount}
              testId="intervention-type-chart"
            />
          </div>
        </div>
      )}
    </CardContainer>
  );
};

export default TrendingCustomers;
