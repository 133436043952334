import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';
import { AppThunkConfig } from '../store';
import {
  fetchOpenJobs,
  OpenJobsData,
  OpenJobWithPrediction,
} from '../../integration/openJobs.api';

export const OPEN_JOBS_KEY = 'openJobs';

export interface OpenJobsState {
  status: FetchingStatus;
  error: string | null;
  data: OpenJobWithPrediction[] | null;
  totalNumberOfJobs: number | null;
}

const initialState: OpenJobsState = {
  status: FetchingStatus.IDLE,
  error: null,
  data: null,
  totalNumberOfJobs: null,
};

interface GetOpenJobsParams {
  page: number;
  size: number;
  sort: string;
}

export const getOpenJobs = createAsyncThunk<
  OpenJobsData,
  GetOpenJobsParams,
  AppThunkConfig
>('getOpenJobs', async ({ page, size, sort }, { getState, signal }) => {
  const {
    filters: {
      dashboard: { country, customer, equipmentType, timeRange },
    },
    mockServer,
  } = getState();
  if (customer === null) {
    throw new Error(
      'Customer must be defined before querying the open jobs endpoint!',
    );
  }

  return fetchOpenJobs({
    page,
    size,
    sort,
    country,
    equipmentType,
    timeRange,
    signal,
    customerId: customer.id,
    mock: mockServer.active,
  });
});

const openJobsSlice = createSlice({
  name: OPEN_JOBS_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOpenJobs.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getOpenJobs.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.data = action.payload.jobs;
        state.totalNumberOfJobs = action.payload.totalJobs;
      })
      .addCase(getOpenJobs.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.error = action.error.message || 'Something went wrong.';
        state.data = null;
        state.totalNumberOfJobs = null;
      });
  },
});

const { reducer } = openJobsSlice;

export const openJobsReducer = reducer;
