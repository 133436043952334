import { TimeRange } from '../types/common';
import api, { ApiFetchParams, ApiResponse } from './api';

export interface Trend {
  value: number | null;
  direction: string;
  positive_impact: boolean;
}
export interface Score {
  name: string;
  value: number | string | null;
  trend: Trend;
  score_type: string;
}

export interface DurationValue {
  date_interval: string;
  duration: number;
}

export interface Durations {
  values: DurationValue[];
  target: number;
  current: number;
  trend: number | null;
}
export interface WorkflowBottleneckStep {
  name: string;
  durations: Durations;
  scores: Score[];
}

export interface WorkflowBottleneckData {
  steps: WorkflowBottleneckStep[];
}

interface WorkFlowBottleneckParams extends ApiFetchParams {
  timeRange: TimeRange;
}

type WorkflowBottleneckResponse = ApiResponse<WorkflowBottleneckData>;

export const fetchWorkflowBottleneck = async ({
  timeRange,
  signal,
  mock,
}: WorkFlowBottleneckParams): Promise<WorkflowBottleneckData> => {
  const { data } = await api.get<WorkflowBottleneckResponse>({
    mock,
    path: '/workflow/api/v1/workflows',
    queryParams: {
      timeRange,
    },
    config: { signal },
  });

  return data;
};
