import React from 'react';

import styles from './FailureRatesAndDocumentManagement.module.scss';
import DocumentManagement from './DocumentManagement/DocumentManagement';
import FailureRates from './failureRates/FailureRates';

const FailureRatesAndDocumentManagement: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <FailureRates />
      </div>
      <div className={styles.section}>
        <DocumentManagement />
      </div>
    </div>
  );
};

export default FailureRatesAndDocumentManagement;
