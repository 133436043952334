import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaletteMode } from '@mui/material';
import { useAppSelector } from '../utils/hooks';

export const THEME_KEY = 'theme';

export interface ThemeState {
  mode: PaletteMode;
}

const initialState: ThemeState = {
  mode: 'dark',
};

const themeSlice = createSlice({
  initialState,
  name: THEME_KEY,
  reducers: {
    updateThemeMode(state, action: PayloadAction<PaletteMode>) {
      return {
        ...state,
        mode: action.payload,
      };
    },
  },
});

export const useDarkModeSelected = (): boolean => {
  const mode = useAppSelector((state) => state.theme.mode);

  return mode === 'dark';
};

const { actions, reducer } = themeSlice;

export const { updateThemeMode } = actions;

export const themeReducer = reducer;
