import React from 'react';
import { Card, Skeleton, Typography } from '@mui/material';

import styles from './InfoCard.module.scss';
import { FetchingStatus } from '../../../../types/common';
// import { FetchingStatus } from '../../../types/common';

interface Props {
  color: string;
  icon: JSX.Element;
  title: string;
  status: FetchingStatus;
  testId: string;
  value: string | null;
  valueColor?: string;
}

const InfoCard: React.FC<Props> = ({
  color,
  icon,
  title,
  value,
  status,
  testId,
  valueColor,
}) => {
  return (
    <Card
      elevation={4}
      className={styles.container}
      sx={{ borderLeft: `8px solid ${color}` }}
      data-testid={testId}
    >
      {icon}
      <div className={styles.textBlock}>
        <Typography variant="h3">{title}</Typography>
        {status === FetchingStatus.PENDING ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.valueSkeleton}
          />
        ) : (
          <Typography variant="h4" sx={{ color: valueColor || 'inherit' }}>
            {value || 'N/A'}
          </Typography>
        )}
      </div>
    </Card>
  );
};

export default InfoCard;
