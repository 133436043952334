import React, { ReactNode } from 'react';
import { CardHeader, Divider, Stack, Typography } from '@mui/material';
import { FetchingStatus } from '@insightloop/common-ui';
import clsx from 'clsx';

import AppTooltip from './AppTooltip';
import { useDarkModeSelected } from '../../store/slices/theme.slice';
import { isDefined } from '../../utils/isDefined';
import styles from './CardContainer.module.scss';

interface Props {
  logo: ReactNode;
  title: string;
  children: ReactNode;
  testId: string;
  status?: FetchingStatus;
  tooltipContent?: JSX.Element | string | JSX.Element[];
  height?: string;
}

const CardContainer: React.FC<Props> = ({
  logo,
  title,
  children,
  status,
  testId = 'app',
  height = 'auto',
  tooltipContent,
}) => {
  const isDarkMode = useDarkModeSelected();

  return (
    <div
      className={styles.container}
      style={{ height: height }}
      data-testid={
        status === FetchingStatus.SUCCESS || status === undefined
          ? testId
          : undefined
      }
    >
      <CardHeader
        className={clsx(styles.transitionEffect)}
        action={
          isDefined(tooltipContent) ? (
            <AppTooltip content={tooltipContent} testId={testId} />
          ) : null
        }
        title={
          <Stack direction="row" spacing={2} className={styles.headerContent}>
            {logo}
            <Typography
              variant="h1"
              sx={{ alignItems: 'center' }}
              data-testid={`${testId}-title`}
            >
              {title}
            </Typography>
          </Stack>
        }
      ></CardHeader>
      <Divider
        sx={{
          borderBottomWidth: '2px',
          backgroundColor: (theme) =>
            isDarkMode ? theme.palette.secondary.dark : '#F3F3F3',
        }}
      />
      {children}
    </div>
  );
};

export default CardContainer;
