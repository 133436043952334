import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CardContainer from '../cardContainer/CardContainer';
import { Box, Divider } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';

import { getOpenJobs } from '../../store/slices/openJobs.slice';
import { ReactComponent as OpenJobsIcon } from './assets/open-jobs.svg';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { FetchingStatus } from '../../types/common';
import { isDefined } from '../../utils/isDefined';
import { useTranslations } from '../../store/slices/translation.slice';
import NoDataAvailableCard from '../cardContainer/NoDataAvailableCard/NoDataAvailableCard';
import { useMockServerActive } from '../../store/slices/mockServer.slice';
import PredictionCell from './PredictionCell';
import { useDarkModeSelected } from '../../store/slices/theme.slice';
import { useAuthData } from '../../utils/auth';
import { Address } from '../../integration/openJobs.api';
import styles from './OpenJobsTable.module.scss';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[0];

const createAddressLabel = ({
  street,
  streetNumber,
  city,
  zip,
  country,
}: Address): string => {
  const address = [street, streetNumber, city, zip].filter(isDefined).join(' ');
  if (isDefined(country)) {
    return `${address}, ${country}`;
  } else {
    return address;
  }
};

const OpenJobsTable: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();
  const { country, customer, equipmentType, timeRange } = useAppSelector(
    (state) => state.filters.dashboard,
  );
  const {
    totalNumberOfJobs,
    status: openJobsStatus,
    data: openJobsData,
  } = useAppSelector((state) => state.openJobs);
  const { loading: loadingText, openJobs: translations } = useTranslations();
  const cluster = useAuthData()?.cluster;
  const isStandalone = isDefined(cluster);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  useEffect(() => {
    const promise = dispatch(
      getOpenJobs({
        page: page + 1, // Backend pagination indexing starts from 1
        size: rowsPerPage,
        sort: 'created,desc',
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    isMockServerActive,
    country,
    customer,
    equipmentType,
    timeRange,
    page,
    rowsPerPage,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const showNoDataAvailableCard =
    openJobsData?.length === 0 || openJobsStatus === FetchingStatus.ERROR;

  return (
    <CardContainer
      logo={<OpenJobsIcon />}
      title={translations.title}
      status={openJobsStatus}
      testId="open-jobs"
      tooltipContent={
        <>
          {translations.tooltip}
          <Divider color="white" sx={{ marginTop: '8px' }} />
          <span style={{ fontWeight: 700 }}>
            {translations.tooltipDetailsTitle}
          </span>
          : {translations.tooltipDetails}
        </>
      }
    >
      {openJobsStatus === FetchingStatus.PENDING && (
        <>
          <LinearProgress color="primary" />
          <div className={styles.overlay}>
            <div className={styles.text}>{loadingText}</div>
          </div>
        </>
      )}
      {showNoDataAvailableCard ? (
        <NoDataAvailableCard />
      ) : (
        <Box className={clsx(styles.openJobsContent)}>
          <TableContainer className={styles.tableContainer}>
            <Table aria-label="open jobs table">
              <TableHead>
                <TableRow>
                  <TableCell>{translations.equipment}</TableCell>
                  <TableCell>{translations.serialNumber}</TableCell>
                  <TableCell>{translations.location}</TableCell>
                  <TableCell>{translations.jobNumber}</TableCell>
                  <TableCell>{translations.issue}</TableCell>
                  <TableCell>{translations.rootCauses}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {openJobsData?.map((job) => (
                  <TableRow
                    key={job.id}
                    data-testid={
                      openJobsStatus === FetchingStatus.SUCCESS
                        ? job.id
                        : undefined
                    }
                    className={clsx({ [styles['dark-mode']]: isDarkMode })}
                  >
                    <TableCell>{job.equipment}</TableCell>
                    <TableCell>
                      {isDefined(job.equipmentSerial)
                        ? job.equipmentSerial
                        : translations.noSerialNumber}
                    </TableCell>
                    <TableCell>
                      {isDefined(job.address)
                        ? createAddressLabel(job.address)
                        : translations.noAddress}
                    </TableCell>
                    {isStandalone ? (
                      <TableCell>
                        <a
                          target="_blank"
                          href={`https://${cluster}.coresystems.net/shell/#/planning-dispatching/service-calls/detail?~id=${job.id}&~code=${job.code}`}
                          rel="noreferrer"
                        >
                          {job.code}
                        </a>
                      </TableCell>
                    ) : (
                      <TableCell>{job.code}</TableCell>
                    )}
                    <TableCell>{job.reportedSymptom}</TableCell>
                    <PredictionCell prediction={job.prediction} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            labelRowsPerPage={translations.jobsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            count={totalNumberOfJobs || 0}
          />
        </Box>
      )}
    </CardContainer>
  );
};

export default OpenJobsTable;
