import assertNever from 'assert-never';
import { Translations } from '../../../../assets/translations/common';
import { formatNumberByLocale } from '../../../../utils/formatNumberByLocale';
import { ScoreTypes } from '../types/types';
import { isValidNumber } from '../../../../utils/isValidNumber';

const extractDividendAndDivisor = (
  locale: string,
  divisionExpression: string,
) => {
  const [dividendStr, divisorStr] = divisionExpression.split('/');

  const dividend = formatNumberByLocale(locale, Number(dividendStr));
  const divisor = formatNumberByLocale(locale, Number(divisorStr));

  return {
    dividend,
    divisor,
  };
};

const convertToDaysOrHours = (
  value: number,
  translations: Translations,
): string => {
  const { timeUnitLabels } = translations;
  const secondsPerHour = 60 * 60;
  const secondsPerDay = 24 * secondsPerHour;

  const hours = Math.floor(value / secondsPerHour);
  const days = Math.floor(value / secondsPerDay);

  if (hours < 24) {
    return `${hours} ${timeUnitLabels.hours}`;
  } else if (hours < 48) {
    return `1 ${timeUnitLabels.day}`;
  } else {
    return `${days} ${timeUnitLabels.days}`;
  }
};

export const formatByScoreTypes = (
  locale: string,
  data: number | string,
  scoreType: ScoreTypes,
  translations: Translations,
): number | string | null => {
  if (scoreType === ScoreTypes.RATIO) {
    const { dividend, divisor } = extractDividendAndDivisor(
      locale,
      String(data),
    );
    return `${dividend} / ${divisor}`;
  } else if (!isValidNumber(data)) {
    return 'N/A';
  } else {
    switch (scoreType) {
      case ScoreTypes.COUNT:
        return formatNumberByLocale(locale, Number(data));
      case ScoreTypes.PERCENTAGE:
        return `${formatNumberByLocale(
          locale,
          Number((Number(data) * 100).toFixed(1)),
        )}%`;
      case ScoreTypes.DURATION:
        return convertToDaysOrHours(Number(data), translations);
      default:
        return assertNever(scoreType);
    }
  }
};
