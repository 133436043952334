import React from 'react';

// import { isDefined } from '../../../utils/isDefined';
// import { useDarkModeSelected } from '../../../store/slices/theme.slice';
import { useTheme } from '@mui/material';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';
import { isDefined } from '../../../../utils/isDefined';

interface Props {
  viewBox?: {
    cx: number;
    cy: number;
  };
  timeRangeText: string;
  value: number | null;
}

const FailureRatesChartLabel: React.FC<Props> = ({
  viewBox,
  timeRangeText,
  value,
}) => {
  const isDarkMode = useDarkModeSelected();
  const theme = useTheme();
  if (!isDefined(viewBox)) {
    return null;
  }
  const { cx, cy } = viewBox;

  return (
    <text textAnchor="middle">
      <tspan
        x={cx}
        y={cy}
        style={{
          fontSize: '36px',
          fontWeight: 700,
          fill: theme.palette.error.main,
        }}
      >
        {value === null ? 'N/A' : `${value}%`}
      </tspan>
      <tspan
        x={cx}
        y={cy + 10}
        style={{
          fontSize: '16px',
          fontWeight: 400,
          fill: isDarkMode ? 'white' : 'black',
        }}
      >
        {timeRangeText.split('\n').map((line, i) => (
          <tspan key={i} x={cx} dy="16px">
            {line}
          </tspan>
        ))}
      </tspan>
    </text>
  );
};

export default FailureRatesChartLabel;
