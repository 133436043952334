import React from 'react';
import { IconButton } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeIcon from '@mui/icons-material/WbSunnyOutlined';

import {
  updateThemeMode,
  useDarkModeSelected,
} from '../../../store/slices/theme.slice';
import { useAppDispatch } from '../../../store/utils/hooks';
import styles from './DarkModeToggle.module.scss';

const DarkModeToggle: React.FC = () => {
  const isDarkMode = useDarkModeSelected();
  const dispatch = useAppDispatch();

  return (
    <IconButton
      data-testid="toggle-dark-mode"
      className={styles.toggle}
      disableRipple
      onClick={() => dispatch(updateThemeMode(isDarkMode ? 'light' : 'dark'))}
    >
      {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};

export default DarkModeToggle;
