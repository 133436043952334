import React, { useCallback, useMemo } from 'react';
import { Typography, useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AnalyticsEventType, NULL_SELECTION } from '@insightloop/common-ui';

import { FetchingStatus } from '../../../../types/common';
import AppChartContainer from '../../../charts/chartContainer/AppChartContainer';
import { SelectChartProps } from '../../../../hooks/UseSelectChart';
import LoadingOverlay from '../../../loadingOverlay/LoadingOverlay';
import styles from './TrendingPartsMonthlyChart.module.scss';
import JobTypesChartTooltip from '../../../charts/jobTypesChartTooltip/JobTypesChartTooltip';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import {
  SelectedPart,
  selectPart,
} from '../../../../store/slices/trendingParts.slice';
import PartDetails from './PartDetails';
import { getLongestLabelWidthPx } from '../../../../utils/measurements';
import {
  getMaintenanceCellColor,
  getFailureCellColor,
  getOtherCellColor,
} from '../../../../utils/getChartColor';
import { isDefined } from '../../../../utils/isDefined';
import { sortByDesc } from '../utils/sortByDesc';
import { TrendingPartsChartData } from '../../../../integration/trendingParts.api';
import { useTranslations } from '../../../../store/slices/translation.slice';
import AxisArrow from '../../../charts/axisArrow/AxisArrow';
import CustomBarChartLabel from '../../../charts/customBarChartLabel/CustomBarChartLabel';
import CustomYAxisTickTrendingParts from '../customAxisTickTrendingParts/CustomYAxisTickTrendingParts';
import { sendAnalytics } from '../../../../utils/sendAnalytics';

const BAR_HEIGHT_PX = 12;

interface Props {
  data: TrendingPartsChartData;
  title: string;
  status: FetchingStatus;
  selectChartProps?: SelectChartProps<number>;
  testId: string;
  chartLabel: string;
}

const TrendingPartsMonthlyChart: React.FC<Props> = ({
  data,
  title,
  status,
  testId,
  chartLabel,
}) => {
  const dispatch = useAppDispatch();
  const { months } = useTranslations();

  const { selectedPart, selectedMonth } = useAppSelector(
    (state) => state.trendingParts,
  );

  const theme = useTheme();
  const textColor = theme.palette.primary.light;
  // 22px: title height, 16px: title bottom margin, 72px: select chart area
  const chartHeight = 'calc(100% - 110px)';

  const handleChartClicked = useCallback(
    (data: SelectedPart | null, index: number) => {
      if (data === null || data.name === selectedPart?.name) {
        dispatch(selectPart(null));
        sendAnalytics({
          name: AnalyticsEventType.FILTER_TRENDING_PARTS_PART,
          attributes: { part: NULL_SELECTION },
        });
      } else {
        dispatch(
          selectPart({
            name: data.name,
            history: data.history,
            index,
          }),
        );
        sendAnalytics({
          name: AnalyticsEventType.FILTER_TRENDING_PARTS_PART,
          attributes: { part: data.name },
        });
      }
    },
    [dispatch, selectedPart],
  );

  const dataSet = useMemo(() => {
    if (!isDefined(data)) {
      return null;
    }

    const targetMonth =
      selectedMonth === null ? months[new Date().getMonth()] : selectedMonth;

    const dataSetForSelectedMonth = data.find(
      ({ month }) => month === targetMonth,
    );

    if (!isDefined(dataSetForSelectedMonth)) {
      return null;
    }
    return dataSetForSelectedMonth.demands.map(
      ({ part_id, part_name, demands, history }) => {
        const { failure, maintenance, other } = demands;
        return {
          part_id,
          name: part_name,
          failure,
          maintenance,
          other,
          history,
        };
      },
    );
  }, [data, selectedMonth]);

  const updatedDataSet = useMemo(() => {
    if (dataSet === null) {
      return null;
    }

    return sortByDesc(
      dataSet,
      (jobType) => jobType.maintenance + jobType.other + jobType.failure,
    );
  }, [dataSet, selectedMonth]);

  const longestLabelLength = useMemo(() => {
    if (updatedDataSet === null) {
      return 0;
    }
    return getLongestLabelWidthPx(updatedDataSet.map((d) => d.name));
  }, [updatedDataSet]);

  if (status === FetchingStatus.PENDING) {
    return <LoadingOverlay />;
  }

  return (
    updatedDataSet && (
      <div className={styles.container} data-testid={testId}>
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
        <AppChartContainer
          chartHeight={chartHeight}
          key={updatedDataSet.length}
        >
          {({ width, height }) => (
            <BarChart
              data={updatedDataSet}
              layout="vertical"
              width={width}
              height={height}
              barSize={
                updatedDataSet.length > 3 ? BAR_HEIGHT_PX : BAR_HEIGHT_PX * 2
              }
            >
              <defs>
                <AxisArrow color={textColor} />
              </defs>
              {updatedDataSet.slice(0, -1).map((dataPoint, index) => (
                <ReferenceLine
                  key={index}
                  position="end"
                  y={dataPoint.name}
                  stroke={textColor}
                  strokeDasharray="3 3"
                />
              ))}
              <XAxis
                axisLine={{
                  stroke: textColor,
                  strokeWidth: 1,
                  markerEnd: 'url(#arrow)',
                }}
                type="number"
                tick={false}
                label={{
                  value: chartLabel,
                  position: 'insideBottomRight',
                  offset: 0,
                }}
              />
              <YAxis
                axisLine={{
                  stroke: textColor,
                  strokeWidth: 1,
                }}
                type="category"
                dataKey="name"
                width={longestLabelLength}
                tick={(tickProps) => {
                  return (
                    <CustomYAxisTickTrendingParts
                      {...tickProps}
                      tickProps={tickProps}
                      isActive={
                        selectedPart === null ||
                        tickProps.payload.index === selectedPart.index
                      }
                      textColor={textColor}
                    />
                  );
                }}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                content={<JobTypesChartTooltip />}
              />
              <Bar
                dataKey="maintenance"
                stackId="a"
                cursor="pointer"
                onClick={handleChartClicked}
              >
                {updatedDataSet.map((dataPoint) => (
                  <Cell
                    key={dataPoint.name}
                    fill={getMaintenanceCellColor(
                      selectedPart === null ||
                        dataPoint.name === selectedPart.name,
                    )}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="failure"
                stackId="a"
                cursor="pointer"
                onClick={handleChartClicked}
              >
                {updatedDataSet.map((dataPoint) => (
                  <Cell
                    key={dataPoint.name}
                    fill={getFailureCellColor(
                      selectedPart === null ||
                        dataPoint.name === selectedPart.name,
                    )}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="other"
                stackId="a"
                label={({ value, ...customLabelProps }) => (
                  <CustomBarChartLabel
                    {...customLabelProps}
                    fillColor={textColor}
                    value={value}
                    isActive={
                      selectedPart === null ||
                      customLabelProps.index === selectedPart.index
                    }
                  />
                )}
                cursor="pointer"
                onClick={handleChartClicked}
              >
                {updatedDataSet.map((dataPoint) => (
                  <Cell
                    key={dataPoint.name}
                    fill={getOtherCellColor(
                      selectedPart === null ||
                        dataPoint.name === selectedPart.name,
                    )}
                  />
                ))}
              </Bar>
            </BarChart>
          )}
        </AppChartContainer>
        <PartDetails />
      </div>
    )
  );
};

export default TrendingPartsMonthlyChart;
