import { Configuration } from '@azure/msal-browser';

export const azureConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const azureLoginRequest = {
  scopes: ['User.Read'],
};
