import { WorkflowKpi } from '../../../assets/translations/common';
import { isDefined } from '../../../utils/isDefined';

export const getWorkflowKpiByTitle = (id: string, data: WorkflowKpi[]) => {
  const matchingObject = data.find((data) => data.id === id);
  if (isDefined(matchingObject)) {
    return matchingObject;
  } else {
    return { title: 'N/A', tooltip: 'N/A' };
  }
};
