import React, { useEffect, useRef, useState } from 'react';
import { isDefined } from '@insightloop/common-ui';
import {
  Button,
  CircularProgress,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import CardContainer from '../../cardContainer/CardContainer';
import { ReactComponent as PdfIcon } from '../assets/pdfIcon.svg';
import { ReactComponent as ReloadIcon } from '../assets/reload.svg';
import { ReactComponent as DocumentIcon } from '../assets/documentIcon.svg';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { ReactComponent as PdfUploadFailure } from '../assets/pdfUploadfailure.svg';
import { postDocumentManagementData } from '../../../store/slices/documentManagement.slice';
import { FetchingStatus } from '../../../types/common';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { useTranslations } from '../../../store/slices/translation.slice';
import { truncateFileName } from '../utils/truncateFileName';
import DocumentManagementDialog from './DocumentManagementDialog';
import { DocumentSortType } from '../../../integration/documentManagement.api';
import styles from './DocumentManagement.module.scss';

export interface FileProps {
  fileName: string;
  fileSize: string;
}

export const DOCUMENTS_DEFAULT_SORT_TYPE = DocumentSortType.Date;
export const DOCUMENTS_DEFAULT_PAGE_SIZE = 10;

const DocumentManagement: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { documentUpload: translations } = useTranslations();

  const inputRef = useRef<HTMLInputElement>(null);
  const { equipmentType } = useAppSelector((state) => state.filters.dashboard);
  const { data, status } = useAppSelector(
    (state) => state.documentManagement.documentData,
  );

  const uploadStatus = useAppSelector(
    (state) => state.documentManagement.documentUpload.status,
  );

  const documentUploadData = useAppSelector(
    (state) => state.documentManagement.documentUpload.data,
  );

  const failedToUploadDocuments = documentUploadData.failures;

  const dispatch = useAppDispatch();

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const [files, setFiles] = useState<File[]>([]);
  const [filesDataArray, setFilesDataArray] = useState<FileProps[]>([]);

  useEffect(() => {
    if (files.length === 0) {
      return;
    }
    dispatch(postDocumentManagementData(files));
  }, [files, dispatch]);

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = event.dataTransfer.files;
    const filesArray = Array.from(droppedFiles);
    const fileDataArray = filesArray.map((file) => ({
      fileName: file.name,
      fileSize: `${(file.size / 1024).toFixed(2)} KB`,
    }));

    setFilesDataArray(fileDataArray);
    setFiles(filesArray);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const filesArray = Array.from(selectedFiles);
      const fileDataArray = filesArray.map((file) => ({
        fileName: file.name,
        fileSize: `${(file.size / 1024).toFixed(2)} KB`,
      }));

      setFilesDataArray(fileDataArray);
      setFiles(selectedFiles);
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const triggerFileInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const [sortOption, setSortOption] = useState<DocumentSortType>(
    DOCUMENTS_DEFAULT_SORT_TYPE,
  );

  const handleChange = (event?: SelectChangeEvent) => {
    if (!isDefined(event)) {
      setSortOption(DOCUMENTS_DEFAULT_SORT_TYPE);
    } else {
      const sortType = event.target.value as DocumentSortType;
      setSortOption(sortType);
    }
  };
  const handleDialog = () => {
    handleChange();
    setOpenDialog(true);
  };

  const isLoading =
    status === FetchingStatus.PENDING ||
    uploadStatus === FetchingStatus.PENDING;

  const hasNoFiles = data.items.length === 0 && filesDataArray.length === 0;

  const renderFailedUploads = () => {
    if (failedToUploadDocuments.length > 0) {
      return failedToUploadDocuments
        .slice(0, filesDataArray.length > 0 ? 0 : 3)
        .map((fileName, index) => (
          <div key={index} className={styles.failurePdfDocument}>
            <PdfUploadFailure className={styles.failurePdfIcon} />
            <Typography className={styles.failureTitle}>
              {truncateFileName(fileName, 15)}
              <Typography className={styles.failureSubtitle}>
                {translations.uploadFailure}
              </Typography>
            </Typography>
            <div className={styles.reload}>
              <ReloadIcon />
            </div>
          </div>
        ));
    }
  };

  const renderUploadedFiles = () => {
    if (filesDataArray.length > 0) {
      return filesDataArray.slice(0, 3).map((data, index) => (
        <div key={index} className={styles.pdfDocument}>
          <PdfIcon className={styles.pdfIcon} />
          <Typography className={styles.title}>
            {truncateFileName(data.fileName, 15)}
          </Typography>
          <Typography className={styles.fileSize}>
            {isLoading ? (
              <div className={styles.progress}>
                <CircularProgress size={28} sx={{ color: '#00BDC7' }} />
              </div>
            ) : (
              data.fileSize.toUpperCase()
            )}
          </Typography>
        </div>
      ));
    }
  };

  useEffect(() => {
    if (uploadStatus === FetchingStatus.SUCCESS) {
      setFilesDataArray([]);
    }
  }, [status, uploadStatus]);

  const calculateDisplayedItemCount = () => {
    if (filesDataArray.length > 3) {
      return 3;
    } else if (failedToUploadDocuments.length > 3) {
      return 3;
    } else if (filesDataArray.length > 0) {
      return filesDataArray.length;
    } else if (failedToUploadDocuments.length > 0) {
      return failedToUploadDocuments.length;
    }
    return 0;
  };

  return (
    <>
      <CardContainer
        logo={<DocumentIcon />}
        title={`${equipmentType} ${translations.documents}`}
        tooltipContent={translations.tooltip}
        status={status}
        testId="document-management"
        height="326px"
      >
        <div className={styles.openDialogSection}>
          <Button
            variant="text"
            className={styles.openDialogBtn}
            onClick={handleDialog}
          >
            {translations.seeAll}
          </Button>
        </div>
        <div className={styles.container}>
          <div
            className={clsx(
              styles.documentUpload,
              isDragging && styles.dragOver,
            )}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className={styles.uploadText}>
              <span
                onClick={triggerFileInputClick}
                className={styles.highlightedText}
              >
                {translations.clickToUpload}
              </span>{' '}
              <input
                type="file"
                multiple
                onChange={handleFileInputChange}
                hidden
                accept=".pdf"
                ref={inputRef}
              />
              {translations.dragAndDrop}
            </div>
          </div>
          <div className={styles.documentList}>
            {isLoading ? (
              <LoadingOverlay className={styles.loadingOverlay} />
            ) : hasNoFiles ? (
              <Typography className={styles.noDocument}>
                {translations.noFilesMessage}
              </Typography>
            ) : (
              <>
                {renderFailedUploads()}
                {renderUploadedFiles()}
                {data.items
                  .slice(0, 3 - calculateDisplayedItemCount())
                  .map((data, index) => (
                    <div key={index} className={styles.pdfDocument}>
                      <PdfIcon className={styles.pdfIcon} />
                      <Typography className={styles.title}>
                        {truncateFileName(data.filename, 15)}
                      </Typography>
                      <Typography className={styles.fileSize}>
                        {data.size.toUpperCase()}
                      </Typography>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </CardContainer>
      <DocumentManagementDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        equipmentType={equipmentType}
        isDragging={isDragging}
        handleDragEnter={handleDragEnter}
        handleDragLeave={handleDragLeave}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        triggerFileInputClick={triggerFileInputClick}
        handleFileInputChange={handleFileInputChange}
        sortOption={sortOption}
        handleChange={handleChange}
        sortedDocuments={data.items}
        filesDataArray={filesDataArray}
        failedToUploadDocuments={failedToUploadDocuments}
        hasNoFiles={hasNoFiles}
      />
    </>
  );
};

export default DocumentManagement;
