import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Typography } from '@mui/material';

import styles from './Tour.module.scss';
import { useTranslations } from '../../../store/slices/translation.slice';
import clsx from 'clsx';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import {
  HighlightedComponent,
  updateOnboardingState,
} from '../../../store/slices/onboardingTour.slice';

interface Step {
  identifier: HighlightedComponent;
  id: number;
  title: string;
  content: string;
}

interface Props {
  shouldStartTour: boolean;
  nextStep: () => void;
  style: React.CSSProperties;
}

const Tour: React.FC<Props> = ({ shouldStartTour = true, nextStep, style }) => {
  const dispatch = useAppDispatch();
  const { dashboardTour: translation } = useTranslations();
  const isDarkMode = useDarkModeSelected();
  const { currentStep } = useAppSelector((state) => state.onboardingTour);

  const dialogRef = useRef<HTMLDivElement>(null);

  const tourSteps: Step[] = useMemo(
    () => [
      {
        identifier: HighlightedComponent.HeaderFilters,
        id: 1,
        title: translation.steps.yourFilters,
        content: translation.steps.filtersDescription,
      },
      {
        identifier: HighlightedComponent.KpisGlanceAndProcessStatus,
        id: 2,
        title: translation.steps.kpis,
        content: translation.steps.kpisDescription,
      },
      {
        identifier: HighlightedComponent.TrendingEquipmentAndSymptoms,
        id: 3,
        title: translation.steps.frequentEquipmentsAndIssues,
        content: translation.steps.frequentEquipmentsAndIssuesDescription,
      },
      {
        identifier: HighlightedComponent.ContactSupport,
        id: 4,
        title: translation.steps.support,
        content: translation.steps.supportDescription,
      },
    ],
    [translation],
  );

  const skipTour = useCallback(() => {
    dispatch(updateOnboardingState({ currentStep: HighlightedComponent.None }));
  }, [dispatch]);

  const currentTourStep = useMemo(
    () => tourSteps.find((step) => step.identifier === currentStep),
    [currentStep],
  );

  const shouldRenderSkipText =
    currentStep !== HighlightedComponent.ContactSupport;

  if (currentStep === HighlightedComponent.None || shouldStartTour) {
    return null;
  }

  return (
    <div
      className={clsx(
        styles.dialogContainer,
        isDarkMode && styles.dialogContainerDarkMode,
      )}
      style={style}
    >
      {currentTourStep && (
        <div ref={dialogRef} className={styles.containerTour}>
          <Typography className={styles.title}>
            {translation.steps.step(currentTourStep.id)} {tourSteps.length}
          </Typography>
          <Typography className={styles.subtitle}>
            {currentTourStep.title}
          </Typography>
          <Typography className={styles.description}>
            {currentTourStep.content}
          </Typography>
          <div className={styles.actions}>
            <Typography onClick={skipTour} className={styles.skip}>
              {shouldRenderSkipText && 'Skip these tips'}
            </Typography>
            <Button onClick={nextStep} className={styles.button}>
              {currentStep === HighlightedComponent.ContactSupport
                ? 'Close'
                : 'Next'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tour;
