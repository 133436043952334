import { useEffect, useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import { createAppTheme } from '../theme/createAppTheme';
import { useAppDispatch, useAppSelector } from '../store/utils/hooks';
import { updateThemeMode } from '../store/slices/theme.slice';

const useAppTheme = (): Theme => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const mode = useAppSelector((state) => state.theme.mode);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateThemeMode(prefersDarkMode ? 'dark' : 'light'));
  }, [dispatch]);

  return useMemo(() => {
    return createAppTheme(mode);
  }, [mode]);
};

export default useAppTheme;
