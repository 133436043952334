import React from 'react';
import { WorkflowBottleneckCardFillColor } from './types';

const JobGenerationBackground: React.FC<WorkflowBottleneckCardFillColor> = ({
  fillColor,
}) => {
  return (
    <svg
      width="328"
      height="121"
      viewBox="0 0 328 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8286_12630)">
        <path
          opacity="0.2"
          d="M286.717 103.603C285.809 105.092 284.191 106 282.448 106H25C19.4772 106 15 101.523 15 96V25C15 19.4772 19.4772 15 25 15H282.448C284.191 15 285.809 15.9083 286.717 17.3969L311.413 57.8969C312.387 59.4954 312.387 61.5046 311.413 63.1031L286.717 103.603Z"
          fill={fillColor}
        />
        <path
          d="M15 20C15 17.2386 17.2386 15 20 15H23V106H20C17.2386 106 15 103.761 15 101V20Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8286_12630"
          x="0"
          y="0"
          width="327.145"
          height="121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8286_12630"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8286_12630"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_8286_12630">
          <rect
            width="26"
            height="22"
            fill="white"
            transform="translate(35 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JobGenerationBackground;
