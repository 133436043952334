export const truncateFileName = (
  fileName: string,
  maxLength: number,
): string => {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const extensionStart = fileName.lastIndexOf('.');
  const extension = fileName.slice(extensionStart);
  const availableChars = maxLength - extension.length;

  if (availableChars <= 0) {
    return '';
  }

  const startChars = fileName.slice(0, availableChars - 2);

  const truncatedFileName = `${startChars}..${extension}`;

  return truncatedFileName;
};
