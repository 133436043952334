import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { useTranslations } from '../../../../store/slices/translation.slice';
import { useAppSelector } from '../../../../store/utils/hooks';
import styles from './TrendLabel.module.scss';

interface Props {
  trend: number | null;
  isTrendPositive: boolean | null;
}

const TrendLabel: React.FC<Props> = ({ trend, isTrendPositive }) => {
  const theme = useTheme();
  const { timeRange } = useAppSelector((state) => state.filters.dashboard);
  const { timeSpan } = useTranslations();

  if (trend === null) {
    return <span>N/A</span>;
  } else {
    const trendIcon = trend > 0 ? '▲' : '▼';

    return (
      <div className={styles.container}>
        <Typography className={styles.label}>
          {isTrendPositive ? (
            <span style={{ color: theme.palette.success.main }}>
              {trendIcon}
              {` ${trend.toFixed(0)}%`}
            </span>
          ) : (
            <span style={{ color: theme.palette.error.main }}>
              {trendIcon}
              {` ${trend.toFixed(0)}%`}
            </span>
          )}
        </Typography>
        <Typography>{timeSpan[timeRange]}</Typography>
      </div>
    );
  }
};

export default TrendLabel;
