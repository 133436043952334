import { ReactNode, ForwardRefRenderFunction, forwardRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { isDemoEnvironment } from '../../utils/isDemoEnvironment';
import ErrorCard from '../errorCard/ErrorCard';

interface Props {
  children: ReactNode;
  demoOnly?: boolean;
  className?: string;
}

const Feature: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, demoOnly = false, className },
  ref,
) => {
  const isDemoEnv = isDemoEnvironment();

  if (demoOnly && !isDemoEnv) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorCard}>
      <div ref={ref} className={className}>
        {children}
      </div>
    </ErrorBoundary>
  );
};

export default forwardRef(Feature);
