import { Prediction } from '../../integration/openJobs.api';

const allPredictionsSeed: Prediction[] = [
  {
    jobId: 'open_job_1',
    prediction: 'Gear - Contact Fatigue, bending fatigue',
  },
  {
    jobId: 'open_job_2',
    prediction: 'Bearing - Loss of bearing capacity',
  },
  {
    jobId: 'open_job_3',
    prediction: 'Level Sensor - Humidity',
  },
  {
    jobId: 'open_job_4',
    prediction: 'Level Sensor - Humidity',
  },
  {
    jobId: 'open_job_5',
    prediction: 'Filter Failure - Salt in filter',
  },
  {
    jobId: 'open_job_6',
    prediction: 'Inverter failure',
  },
  {
    jobId: 'open_job_7',
    prediction: 'Transformer failure',
  },
  {
    jobId: 'open_job_8',
    prediction: 'Filter Failure - Salt in filter',
  },
  {
    jobId: 'open_job_9',
    prediction: 'Gear - Contact Fatigue, bending fatigue',
  },
  {
    jobId: 'open_job_10',
    prediction: 'Bearing - Rolling contact fatigue, white etching track',
  },
];

export default allPredictionsSeed;
