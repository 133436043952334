import React from 'react';
import { Score } from '../../../../integration/workflowBottleneck.api';
import { isDefined } from '../../../../utils/isDefined';
import { Trend, getTrend } from '../utils/getTrend';
import { toPercentage } from '../utils/toPercentage';

interface TrendValueProps {
  data: Score;
  styles: Record<string, string>;
}

const TrendValue: React.FC<TrendValueProps> = ({ data, styles }) => {
  if (getTrend(data.trend.value) === Trend.Neutral) {
    return <span className={styles.defaultIcon}>--</span>;
  }

  const direction = getTrend(data.trend.value) === Trend.Negative ? '▼' : '▲';
  const impactClass = data.trend.positive_impact ? 'Up' : 'Down';

  return (
    <>
      <span className={styles[`arrowIcon${impactClass}`]}>{direction}</span>
      <span className={styles[`kpiPercentage${impactClass}`]}>
        {isDefined(data.trend) ? (
          toPercentage(data.trend.value, 1)
        ) : (
          <span className={styles.nullValue}>--</span>
        )}
      </span>
    </>
  );
};

export default TrendValue;
