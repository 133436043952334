import React from 'react';

const BulbSvg: React.FC = () => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0545 16.859V16.0739C11.0545 15.1252 12.0863 14.2278 12.7556 13.5876C13.6991 12.6857 14.195 11.474 14.195 10.0545C14.195 7.43744 12.1102 5.34377 9.48423 5.34377C8.86512 5.34204 8.25177 5.46271 7.67945 5.69884C7.10714 5.93496 6.58714 6.28189 6.14936 6.71967C5.71158 7.15745 5.36465 7.67745 5.12852 8.24976C4.8924 8.82208 4.77173 9.43543 4.77346 10.0545C4.77346 11.4233 5.29066 12.7171 6.21286 13.5876C6.87858 14.216 7.91397 15.1153 7.91397 16.0739V16.859M8.43739 19.9995H10.5311M7.91397 18.4292H11.0545M9.48423 16.859V12.6716"
        stroke="#00BDC7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7284 12.1484C10.7284 12.1484 10.0248 12.6719 9.48531 12.6719C8.94586 12.6719 8.24219 12.1484 8.24219 12.1484"
        stroke="#00BDC7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.0007 6.58616L2.71228 7.47687"
        stroke="#00BDC7"
        strokeLinecap="round"
      />
      <path
        d="M16.3835 7.47634L18.0951 6.58563"
        stroke="#00BDC7"
        strokeLinecap="round"
      />
      <path d="M9.48438 2.99398V1" stroke="#00BDC7" strokeLinecap="round" />
      <path
        d="M13.6719 4.23164L14.869 2.85319"
        stroke="#00BDC7"
        strokeLinecap="round"
      />
      <path
        d="M3.71926 2.92776L5.0688 4.30676"
        stroke="#00BDC7"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BulbSvg;
