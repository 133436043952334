import React, { useMemo } from 'react';
import { Text } from 'recharts';
import { truncateChartLabel } from '../../../../utils/measurements';
import { CustomAxisTickProps } from '../../../charts/customAxisTick/CustomAxisTick';

export interface TickLabelProps {
  tickProps: CustomAxisTickProps;
  isActive: boolean;
  textColor: string;
}

const CustomYAxisTickTrendingParts: React.FC<TickLabelProps> = ({
  tickProps,
  isActive,
  textColor,
}) => {
  const opacity = isActive ? 1 : 0.1;
  const truncatedLabel = useMemo(
    () => truncateChartLabel(tickProps.payload.value),
    [tickProps.payload.value],
  );

  return (
    <Text {...tickProps} style={{ fill: textColor, opacity }}>
      {truncatedLabel}
    </Text>
  );
};

export default CustomYAxisTickTrendingParts;
