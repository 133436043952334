import { TimeRange } from '../../types/common';
import countriesSeed from './countries.seed';
import searchSeed from './search.seed';
import { FirstTimeFixedRateData } from '../../integration/ftfr.api';

const ftfrBaseSeed: FirstTimeFixedRateData = {
  trend: 0.02,
  first_time_fix_rate: [
    { rate: '0.95', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.85', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.75', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.85', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.95', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.70', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.90', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.95', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.85', date_interval: '2022-11-23T12:11:19.379Z' },
    { rate: '0.95', date_interval: '2022-11-23T12:11:19.379Z' },
  ],
};

interface GetSeedParams {
  timeRange: TimeRange;
  country: string | null;
  customer: string | null;
  equipmentType: string | null;
}

const getFtfrSeed = ({
  timeRange,
  country,
  customer,
  equipmentType,
}: GetSeedParams): FirstTimeFixedRateData => {
  if (country === countriesSeed.countries[1].code) {
    return {
      trend: null,
      first_time_fix_rate: [],
    };
  }

  if (customer === searchSeed.customers[0].id) {
    return {
      trend: 0.02,
      first_time_fix_rate: ftfrBaseSeed.first_time_fix_rate.slice(0, 5),
    };
  }

  if (equipmentType === searchSeed.equipment_types[1]) {
    return {
      trend: -0.02,
      first_time_fix_rate: ftfrBaseSeed.first_time_fix_rate.slice(3, 6),
    };
  }

  if (timeRange === TimeRange.ONE_MONTH) {
    return {
      trend: 0.02,
      first_time_fix_rate: ftfrBaseSeed.first_time_fix_rate.map(
        (dataPoint) => ({
          ...dataPoint,
          rate: String(Number(dataPoint.rate) + 0.05),
        }),
      ),
    };
  }

  if (timeRange === TimeRange.ONE_WEEK) {
    return {
      trend: 0.02,
      first_time_fix_rate: ftfrBaseSeed.first_time_fix_rate.map(
        (dataPoint) => ({
          ...dataPoint,
          rate: String(Number(dataPoint.rate) + 0.05),
        }),
      ),
    };
  }

  return ftfrBaseSeed;
};

export default getFtfrSeed;
