import { configureStore } from '@reduxjs/toolkit';

import { FILTERS_KEY, filtersReducer } from './slices/filters.slice';
import { SEARCH_KEY, searchReducer } from './slices/search.slice';
import { COUNTRIES_KEY, countriesReducer } from './slices/countries.slice';
import {
  TRENDING_CUSTOMERS_KEY,
  trendingCustomersReducer,
} from './slices/trendingCustomers.slice';
import {
  FAILURE_RATES_KEY,
  failureRatesReducer,
} from './slices/failureRates.slice';
import {
  FIRST_TIME_FIXED_RATE_KEY,
  firstTimeFixedRateReducer,
} from './slices/firstTimeFixedRate.slice';
import {
  MEAN_TIME_BETWEEN_VISITS_KEY,
  meanTimeBetweenVisitsReducer,
} from './slices/meanTimeBetweenVisits.slice';
import {
  MEAN_TIME_TO_RESOLUTION_KEY,
  meanTimeToResolutionReducer,
} from './slices/meanTimeToResolution.slice';
import { OPEN_JOBS_KEY, openJobsReducer } from './slices/openJobs.slice';
import {
  TRENDING_SYMPTOMS_KEY,
  trendingSymptomsReducer,
} from './slices/trendingSymptoms.slice';
import {
  TRENDING_PARTS_KEY,
  trendingPartsReducer,
} from './slices/trendingParts.slice';
import {
  TRENDING_EQUIPMENT_FAILURE_KEY,
  trendingEquipmentFailureReducer,
} from './slices/trendingEquipmentFailure.slice';
import {
  TRANSLATION_KEY,
  translationReducer,
} from './slices/translation.slice';
import { THEME_KEY, themeReducer } from './slices/theme.slice';
import { MOCK_SERVER_KEY, mockServerReducer } from './slices/mockServer.slice';
import {
  RATINGS_AND_REVIEWS_KEY,
  ratingsAndReviewsReducer,
} from './slices/ratingsAndReviews.slice';
import {
  WORKFLOW_BOTTLENECK_KEY,
  workflowBottleneckReducer,
} from './slices/workflowBottleneck.slice';
import {
  SYMPTOM_SEARCH_KEY,
  symptomSearchReducer,
} from './slices/symptomSearch.slice';
import {
  allCustomersAndInterventionsReducer,
  COUSTOMERS_AND_INTERVENTIONS_KEY,
} from './slices/allCustomersAndInterventions.slice';
import {
  PREDICTION_QUALITY_KEY,
  predictionQualityReducer,
} from './slices/predictionQuality.slice';
import {
  ONBOARDING_TOUR_KEY,
  onboardingTourReducer,
} from './slices/onboardingTour.slice';
import {
  DOCUMENT_MANAGEMENT_KEY,
  documentManagementReducer,
} from './slices/documentManagement.slice';

const reducers = {
  [FILTERS_KEY]: filtersReducer,
  [SEARCH_KEY]: searchReducer,
  [COUNTRIES_KEY]: countriesReducer,
  [TRENDING_CUSTOMERS_KEY]: trendingCustomersReducer,
  [COUSTOMERS_AND_INTERVENTIONS_KEY]: allCustomersAndInterventionsReducer,
  [FAILURE_RATES_KEY]: failureRatesReducer,
  [FIRST_TIME_FIXED_RATE_KEY]: firstTimeFixedRateReducer,
  [MEAN_TIME_BETWEEN_VISITS_KEY]: meanTimeBetweenVisitsReducer,
  [MEAN_TIME_TO_RESOLUTION_KEY]: meanTimeToResolutionReducer,
  [OPEN_JOBS_KEY]: openJobsReducer,
  [TRENDING_SYMPTOMS_KEY]: trendingSymptomsReducer,
  [TRENDING_PARTS_KEY]: trendingPartsReducer,
  [RATINGS_AND_REVIEWS_KEY]: ratingsAndReviewsReducer,
  [TRENDING_EQUIPMENT_FAILURE_KEY]: trendingEquipmentFailureReducer,
  [TRANSLATION_KEY]: translationReducer,
  [THEME_KEY]: themeReducer,
  [MOCK_SERVER_KEY]: mockServerReducer,
  [WORKFLOW_BOTTLENECK_KEY]: workflowBottleneckReducer,
  [SYMPTOM_SEARCH_KEY]: symptomSearchReducer,
  [PREDICTION_QUALITY_KEY]: predictionQualityReducer,
  [ONBOARDING_TOUR_KEY]: onboardingTourReducer,
  [DOCUMENT_MANAGEMENT_KEY]: documentManagementReducer,
};

export const store = configureStore({
  reducer: reducers,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export interface AppThunkConfig {
  dispatch: AppDispatch;
  state: RootState;
}

// This is going to be used to provide a store for integration tests.
export const createAppStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: reducers,
  });
};
