import React from 'react';
import Fab from '@mui/material/Fab';
import { ShellSdk } from 'fsm-shell';

import { ReactComponent as OpenAssistant } from './assets/chatbot-astronaut-fab.svg';
import styles from './AssistantLink.module.scss';

const AssistantLink: React.FC = () => {
  const isInsideFSMShell = ShellSdk.isInsideShell();
  if (isInsideFSMShell) {
    return null;
  }

  const assistantUrl =
    process.env.REACT_APP_ENVIRONMENT_NAME === 'production'
      ? 'https://assistant.insightloop.com'
      : 'https://development.assistant.insightloop.com';

  return (
    <a href={assistantUrl} target="_blank" rel="noreferrer">
      <Fab
        aria-label="open assistant dialog"
        data-testid="open-assistant"
        className={styles.fab}
        color="info"
      >
        <OpenAssistant />
      </Fab>
    </a>
  );
};

export default AssistantLink;
