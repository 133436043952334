import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types/common';

export const SYMPTOM_SEARCH_KEY = 'symptomSearch';

export interface Symptom {
  name: string;
  count: number;
}

export interface SymptomPrediction {
  partName: string;
  probability: number;
  rootCause: string;
}

export interface SymptomSearchState {
  symptoms: {
    status: FetchingStatus;
    error: string | null;
    data: Symptom[] | null;
  };
  selectedSymptom: string | null;
  predictions: {
    status: FetchingStatus;
    error: string | null;
    data: SymptomPrediction[] | null;
  };
}

const initialState: SymptomSearchState = {
  symptoms: {
    status: FetchingStatus.IDLE,
    error: null,
    data: null,
  },
  selectedSymptom: null,
  predictions: {
    status: FetchingStatus.IDLE,
    error: null,
    data: null,
  },
};

const symptomSearchSlice = createSlice({
  name: SYMPTOM_SEARCH_KEY,
  initialState,
  reducers: {
    updateSelectedSymptom(state, action: PayloadAction<string | null>) {
      const symptom = action.payload;
      if (symptom === null) {
        return {
          ...state,
          selectedSymptom: action.payload,
          predictions: {
            ...initialState.predictions,
          },
        };
      } else {
        return {
          ...state,
          selectedSymptom: action.payload,
        };
      }
    },
  },
});

const { actions, reducer } = symptomSearchSlice;

export const { updateSelectedSymptom } = actions;

export const symptomSearchReducer = reducer;
