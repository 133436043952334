import React, { useCallback, useState } from 'react';
import {
  PieChart as RePieChart,
  Cell,
  Pie,
  Label,
  Tooltip,
  Sector,
} from 'recharts';

import { useMediaQuery, useTheme } from '@mui/material';
import { FetchingStatus } from '../../../../types/common';
import LoadingOverlay from '../../../loadingOverlay/LoadingOverlay';
import AppChartContainer from '../../../charts/chartContainer/AppChartContainer';
import FailureRatesChartLabel from './FailureRatesChartLabel';
import AppChartTooltip from '../../../charts/tooltip/AppChartTooltip';
import { BarChartData } from '../../../../types/charts';

interface Props {
  data: BarChartData;
  status: FetchingStatus;
  label: string;
  testId: string;
}

// Sector types for recharts are not working properly.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any) => {
  const { outerRadius = 0 } = props;

  return (
    <g>
      <Sector {...props} outerRadius={outerRadius + 4} />
    </g>
  );
};

const FailureRatesChart: React.FC<Props> = ({
  data,
  status,
  label,
  testId,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width:1200px) and (max-width:1479px)');

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const onMouseOver = useCallback((_: unknown, index: number) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback(() => {
    setActiveIndex(undefined);
  }, []);

  if (status === FetchingStatus.PENDING) {
    return <LoadingOverlay />;
  }

  if (data === null) {
    return null;
  }
  return (
    <AppChartContainer testId={testId}>
      {({ width, height }) => (
        <RePieChart width={width} height={height}>
          <Pie
            dataKey="count"
            data={data}
            cx={isDesktop ? 80 : 120}
            stroke="none"
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            innerRadius={isDesktop ? 70 : 78}
            outerRadius={isDesktop ? 78 : 86}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            <Cell fill={theme.palette.error.main} />
            <Cell fill={theme.palette.success.main} />
            <Label
              position="center"
              content={
                <FailureRatesChartLabel
                  timeRangeText={label}
                  value={data[0]?.count}
                />
              }
            />
          </Pie>
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
            content={<AppChartTooltip unitOfMeasurement="%" />}
          />
        </RePieChart>
      )}
    </AppChartContainer>
  );
};

export default FailureRatesChart;
